import { useEffect, useMemo, useState } from 'react';
import { MenuProps as AntMenuProps } from 'antd';
import { useLocation } from 'react-router-dom';
import { useGlobal } from 'src/contexts/GlobalContext';
import { useTheme } from 'src/contexts/ThemeContext';
import { useAppNavigation } from 'src/routes';

import LogoCollapsed from '@/assets/logo-collapsed.svg?url';
import Logo from '@/assets/logo.svg?url';
import { DoubleRightOutlined } from '@ant-design/icons';

import { useSidebarItems } from '../const';
import { CollapseButton, LogoWrapper, MenuStyled, SiderStyled, Wrapper } from './Menu.styled';

export interface MenuProps {
  collapsed?: boolean;
  onClick?: AntMenuProps['onClick'];
  onClose?: () => void;
  onClickLogo?: () => void;
  toggleCollapsed?: () => void;
}

const transformKey = (key: string): string => {
  if (key?.length) {
    const camelCaseKey = key.replace(/[-/](.)/g, (_, char) => char.toUpperCase()).replace(/[-/]/g, '');
    return camelCaseKey.charAt(0).toLowerCase() + camelCaseKey.slice(1);
  }
  return '';
};

export const Menu = (props: MenuProps) => {
  const { onClick, collapsed, toggleCollapsed, onClose, onClickLogo } = props;
  const { isDarkMode } = useTheme();
  const { isDesktop } = useGlobal();
  const location = useLocation();
  const { navigateTo } = useAppNavigation();
  const items = useSidebarItems();
  const [backgroundImage, setBackgroundImage] = useState('');

  const [selectedKeys, setSelectedKeys] = useState<string[]>();

  const handleOnClick: MenuProps['onClick'] = (e) => {
    if (onClick) {
      onClick(e);
    } else {
      const { key } = e;
      if (key in navigateTo) {
        navigateTo[key as keyof typeof navigateTo]();
        setSelectedKeys([key]);
      } else {
        console.error(`navigateTo[${key}] does not exist.`);
      }
    }
    onClose?.();
  };

  const handleClickLogo = () => {
    onClickLogo ? onClickLogo() : navigateTo.landing();
    onClose?.();
  };

  useEffect(() => {
    // Only load the image for the current theme
    if (isDarkMode) {
      import('@/assets/menu-dark.svg?url').then((module) => {
        setBackgroundImage(module.default);
      });
    } else {
      import('@/assets/menu-light.svg?url').then((module) => {
        setBackgroundImage(module.default);
      });
    }
  }, [isDarkMode]);

  const wicketLogo = useMemo(() => (collapsed ? LogoCollapsed : Logo), [collapsed]);

  useEffect(() => {
    const pathArray = location.pathname.split('/').filter(Boolean);
    if (pathArray?.length > 1) {
      const isParentKeyExistInMenu = items.some((item) => item?.key === pathArray[0]);
      if (isParentKeyExistInMenu) {
        const parentItem = items.find((item) => item?.key === pathArray[0]);
        const isChildrenKeyExistInMenu =
          parentItem && 'children' in parentItem
            ? parentItem?.children?.some((item) => item?.key === pathArray[1])
            : false;
        if (isChildrenKeyExistInMenu) {
          setSelectedKeys([pathArray[1]]);
        } else {
          setSelectedKeys([pathArray[0]]);
        }
      }
      const key = transformKey(pathArray[1]);
      if (key in navigateTo) {
        setSelectedKeys([key]);
      }
    } else {
      const key = transformKey(pathArray[0]);
      if (key in navigateTo) {
        setSelectedKeys([key]);
      }
      setSelectedKeys([key]);
    }
  }, [location.pathname]);

  return (
    <Wrapper hasSider $isCollapsed={collapsed} $backgroundImage={backgroundImage}>
      <SiderStyled collapsed={collapsed}>
        <LogoWrapper $isCollapsed={collapsed} onClick={handleClickLogo}>
          <img src={wicketLogo} alt="logo" width="171" height="23" />
        </LogoWrapper>
        <MenuStyled
          onClick={handleOnClick}
          defaultSelectedKeys={[`${location.pathname.split('/')[1]}`]}
          selectedKeys={selectedKeys}
          mode="inline"
          items={useSidebarItems()}
          $isCollapsed={collapsed}
          $isDarkMode={isDarkMode}
        />
        {isDesktop && (
          <CollapseButton
            type="primary"
            onClick={toggleCollapsed}
            $isCollapsed={collapsed}
            icon={<DoubleRightOutlined />}
          />
        )}
      </SiderStyled>
    </Wrapper>
  );
};
