import { useState } from 'react';

import type { MenuProps } from 'antd';

import { Menu } from '../Menu/Menu';

interface SidebarProps {
  onClick?: MenuProps['onClick'];
  onClickLogo?: () => void;
}

export const SidebarDesktop = (props: SidebarProps) => {
  const { onClick, onClickLogo } = props;
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => setCollapsed(!collapsed);

  return (
    <Menu
      data-testid="sidebar-desktop"
      collapsed={collapsed}
      onClick={onClick}
      onClickLogo={onClickLogo}
      toggleCollapsed={toggleCollapsed}
    />
  );
};
