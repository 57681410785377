import { FanguardApiContractsInteractionInteractionDecisionOperationModel } from 'src/api/generated';
import i18n from 'i18next';

const browserLocale = navigator.language || 'en-US';

export const capitalizeFirstLetters = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getBase64 = (file: File | Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
export const getInteractionType = (
  interactionType?: string | null,
  interactionOperation?: FanguardApiContractsInteractionInteractionDecisionOperationModel,
) => {
  switch (interactionType?.toLowerCase()) {
    case 'interactiondecisionaccesscontrol':
      return i18n.t('interactions.local');
    case 'interactiondecisioncredentialing':
      if (interactionOperation != null && interactionOperation.toLowerCase() === 'credentialingcheckin') {
        return i18n.t('interactions.checkIn');
      } else {
        return i18n.t('interactions.scan');
      }
    case 'interactiondecisionageverification':
      return i18n.t('interactions.identityVerification');
  }
};

export const dateFormatter = (date: string | null | undefined, precice: boolean = false) => {
  if (!date) return '';

  return new Intl.DateTimeFormat(browserLocale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: precice ? 'numeric' : undefined,
  }).format(new Date(date));
};

export const dateFormatterWithAT = (date: string | null | undefined) => {
  if (!date) return '';

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).format(new Date(date));

  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(new Date(date));

  return `${formattedDate} at ${formattedTime}`;
};

export const currencyFormatter = (value: number | undefined | null) => {
  if (!value) return '';

  return new Intl.NumberFormat(browserLocale, {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};
