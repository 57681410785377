import { Avatar, Button, Flex, Popover, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/contexts/AuthContext';
import { useTheme } from 'src/contexts/ThemeContext';
import { useGetUser } from 'src/hooks/api/useUsersEndpoint';
import { PRIVACY_POLICY_URL, SUPPORT_EMAIL } from 'src/utils/constants';

import { CheckOutlined, CloseOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

import {
  ParagraphNoMargin,
  StyledContactSupport,
  StyledMailIcon,
  TextNoMargin,
  TitleNoMargin,
} from './MobileUserProfile/MobileUserProfile.styled';
import { BorderedWrapper, ButtonWrapper, PopoverContent } from './UserProfile.styled';

interface UserProfileProps {
  visible: boolean;
  toggleUserProfile: () => void;
}

const BUILD_VERSION = import.meta.env.BUILD_NUMBER ?? '';

const UserProfile = (props: UserProfileProps) => {
  const { visible, toggleUserProfile } = props;

  const { t } = useTranslation();
  const { isDarkMode, toggleTheme } = useTheme();

  const { logout } = useAuth();

  const { data } = useGetUser();

  const onPrivacyPolicyClick = () => window.open(PRIVACY_POLICY_URL, '_blank');

  const onMailToHandler = () => window.open(`mailto:${SUPPORT_EMAIL}`);

  const nameLastname = `${data?.firstName} ${data?.lastName}`;

  return (
    <Popover
      open={visible}
      placement="bottom"
      styles={{
        body: {
          padding: 0,
        },
      }}
      onOpenChange={toggleUserProfile}
      arrow={false}
      trigger="click"
      content={
        <PopoverContent>
          <Flex vertical style={{ width: '100%' }} data-testid="user-profile-modal">
            <BorderedWrapper vertical>
              <TitleNoMargin level={5}>{nameLastname}</TitleNoMargin>
              <ParagraphNoMargin type="secondary">{data?.username}</ParagraphNoMargin>
              <ParagraphNoMargin>{data?.email}</ParagraphNoMargin>
            </BorderedWrapper>

            <BorderedWrapper justify="space-between">
              <TextNoMargin>{t('general.version')}</TextNoMargin>
              <TextNoMargin type="secondary">{BUILD_VERSION}</TextNoMargin>
            </BorderedWrapper>

            <BorderedWrapper justify="space-between">
              <TextNoMargin>{t('general.darkMode')}</TextNoMargin>
              <Switch
                checked={isDarkMode}
                onChange={toggleTheme}
                title="Switch Theme Mode"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </BorderedWrapper>

            <BorderedWrapper justify="space-between">
              <StyledContactSupport>{t('general.contactSupport')}</StyledContactSupport>
              <Button type="text" icon={<StyledMailIcon />} onClick={onMailToHandler} />
            </BorderedWrapper>

            <ButtonWrapper justify="space-between">
              <Button onClick={onPrivacyPolicyClick}>{t('general.privacyPolicy')}</Button>
              <Button type="primary" onClick={logout} icon={<LogoutOutlined />} iconPosition="end">
                {t('general.logout')}
              </Button>
            </ButtonWrapper>
          </Flex>
        </PopoverContent>
      }
    >
      <Button data-testid="user-btn" shape="circle">
        <Avatar src="default" icon={<UserOutlined />} />
      </Button>
    </Popover>
  );
};

export default UserProfile;
