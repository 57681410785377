import { FanguardApiContractsClientSiteDetailModel } from 'src/api/generated';
import { get } from '@/api/api';
import { useQuery } from '@tanstack/react-query';

const URI = 'site';

const useGetDetails = () => {
  return useQuery({
    queryKey: [URI],
    queryFn: async () => {
      const response = await get<FanguardApiContractsClientSiteDetailModel[]>({ uri: `${URI}/details` });
      return response.data;
    },
  });
};

export { useGetDetails };
