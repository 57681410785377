export const SENSOR_TABS = ['status', 'settings', 'mode', 'lists', 'manage'] as const;
export const CONTROLLER_TABS = ['status', 'settings', 'ports', 'manage'] as const;

export const SENSOR_ITEMS = [
  { label: 'Status', key: 'status' },
  { label: 'Settings', key: 'settings' },
  { label: 'Mode', key: 'mode' },
  { label: 'Lists', key: 'lists' },
  { label: 'Manage', key: 'manage' },
];

export const CONTROLLER_ITEMS = [
  { label: 'Status', key: 'status' },
  { label: 'Settings', key: 'settings' },
  { label: 'Ports', key: 'ports' },
  { label: 'Manage', key: 'manage' },
];
