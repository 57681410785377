import 'antd/dist/reset.css';
import 'antd-mobile/es/global';
import './global.css';

import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import { clientConfig } from 'src/api/axios/config';
import { useGlobal } from 'src/contexts/GlobalContext';
import { queryClient } from 'src/main';

import Layout from '@/components/Layout';

import { GlobalProvider } from '../src/contexts/GlobalContext';
import SidebarDesktop from './components/Sidebar/SidebarDesktop';

const App = () => (
  <GlobalProvider>
    <AppContent />
  </GlobalProvider>
);

const AppContent = () => {
  const { isDesktop } = useGlobal();

  useEffect(() => {
    const unsubscribe = clientConfig.subscribe(() => {
      queryClient.invalidateQueries();
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      {isDesktop ? <SidebarDesktop /> : null}
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default App;
