import { lazy, Suspense } from 'react';

import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import { ProtectedRoutes } from '@/components/ProtectedRoutes';

import App from './App';
import SuspenseLoader from './components/SuspenseLoader';
import { UserRole } from './contexts/AuthContext';
import { DeviceDetailsProvider } from './pages/Devices/DeviceDetails/DeviceDetailsContext';

// Pages
const NoPermission = lazy(() => import('./pages/NoPermission'));
const NotFound = lazy(() => import('./pages/NotFound'));
const LoginPage = lazy(() => import('@/pages/Login'));
const ListsLanding = lazy(() => import('@/pages/Lists/ListsLanding'));
const ListDetailPage = lazy(() => import('@/pages/Lists/ListDetailPage'));
const ListDetailPeopleAdd = lazy(() => import('@/pages/Lists/ListDetailPeopleAdd'));
const PeoplePage = lazy(() => import('src/pages/People/List'));
const PeoplePageDetails = lazy(() => import('src/pages/People/Details'));
const PeopleDetailsEdit = lazy(() => import('src/pages/People/Edit'));
const Devices = lazy(() => import('src/pages/Devices/Devices'));
const Groups = lazy(() => import('src/pages/Devices/Groups'));
const PeopleNew = lazy(() => import('src/pages/People/Edit'));
const DeviceDetails = lazy(() => import('src/pages/Devices/DeviceDetails'));
const AccessLogs = lazy(() => import('src/pages/AccessLogs/AccessLogs'));
const PaymentLogs = lazy(() => import('src/pages/PaymentLogs/PaymentLogs'));
const DeviceDetailsTab = lazy(() => import('src/pages/Devices/DeviceDetails/DeviceDetailsTab'));
const DeviceLogs = lazy(() => import('src/pages/Devices/DeviceLogs'));
const TicketLogs = lazy(() => import('src/pages/TicketLogs/TicketLogs'));

const renderSuspensedComponent = (Component: React.ComponentType) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component />
  </Suspense>
);

const routes: RouteObject[] = [
  {
    path: '/',
    element: <ProtectedRoutes>{renderSuspensedComponent(App)}</ProtectedRoutes>,
    children: [
      {
        index: true,
        element: <div>Home Page</div>,
      },
      {
        element: <ProtectedRoutes requiredRoles={[UserRole.Admin]} />,
        children: [
          { path: 'users', element: <div>Users</div> },
          { path: 'settings', element: <div>Settings</div> },
        ],
      },
      {
        element: <ProtectedRoutes requiredRoles={[UserRole.Admin, UserRole.Manager]} />,
        children: [{ path: 'reports', element: <div>Reports</div> }],
      },
      {
        element: <ProtectedRoutes />,
        children: [
          { path: 'ticket-logs', element: renderSuspensedComponent(TicketLogs) },
          { path: 'payment-logs', element: renderSuspensedComponent(PaymentLogs) },
          { path: 'access-logs', element: renderSuspensedComponent(AccessLogs) },
          {
            path: 'lists',
            element: renderSuspensedComponent(ListsLanding),
            children: [
              {
                path: 'new',
                element: renderSuspensedComponent(ListDetailPage),
              },
              {
                path: ':id',
                element: renderSuspensedComponent(ListDetailPage),
              },
              {
                path: ':id/add-people',
                element: renderSuspensedComponent(ListDetailPeopleAdd),
              },
            ],
          },
          {
            path: 'people',
            element: renderSuspensedComponent(PeoplePage),
          },
          {
            path: 'people/:id',
            element: renderSuspensedComponent(PeoplePageDetails),
          },
          {
            path: 'people/:id/edit',
            element: renderSuspensedComponent(PeopleDetailsEdit),
          },
          {
            path: 'people/new',
            element: renderSuspensedComponent(PeopleNew),
          },
          { path: 'venues', element: <div>Venues</div> },
          {
            path: 'devices',
            children: [
              {
                path: '',
                element: <Navigate to="sensors-controllers" />,
              },
              {
                path: 'sensors-controllers',
                element: renderSuspensedComponent(Devices),
              },
              {
                path: 'sensors-controllers/:type/:id',
                element: renderSuspensedComponent(() => (
                  <DeviceDetailsProvider>
                    <DeviceDetails />
                  </DeviceDetailsProvider>
                )),
                children: [
                  {
                    path: '',
                    index: true,
                    element: renderSuspensedComponent(() => <Navigate to="status" />),
                  },
                  {
                    path: 'logs',
                    element: renderSuspensedComponent(DeviceLogs),
                  },
                  {
                    path: ':tab',
                    element: renderSuspensedComponent(DeviceDetailsTab),
                  },
                ],
              },
              {
                path: 'groups',
                element: renderSuspensedComponent(Groups),
              },
            ],
          },
          { path: 'events', element: <div>Events</div> },
          { path: 'account', element: <div>Account</div> },
          { path: 'settings', element: <div>Settings</div> },
          { path: 'kiosk', element: <div>Kiosk</div> },
          { path: 'connections', element: <div>Connections</div> },
          { path: 'sites', element: <div>Sites</div> },
          { path: 'ticket-groups', element: <div>Ticket Groups</div> },
          { path: 'email-templates', element: <div>Email Templates</div> },
          { path: 'assets', element: <div>Assets</div> },
          {
            path: 'no-permission',
            element: renderSuspensedComponent(NoPermission),
          },
          {
            path: '*',
            element: renderSuspensedComponent(NotFound),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: renderSuspensedComponent(LoginPage),
  },
];

const router = createBrowserRouter(routes, {
  future: {
    // @ts-expect-error - future prop is not yet in the types i guess but is needed to not display warnings
    v7_startTransition: true,
    v7_relativeSplatPath: true,
  },
});

export { router, routes };
