import { useGlobal } from 'src/contexts/GlobalContext';

import { AppHeader } from '../AppHeader/AppHeader';
import QueryErrorHandler from '../QueryErrorHandler/QueryErrorHandler';
import { Content, Wrapper } from './Layout.styled';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const { isDesktop } = useGlobal();
  return (
    <Wrapper>
      <AppHeader />
      <Content $isDesktop={isDesktop}>
        <QueryErrorHandler>{children}</QueryErrorHandler>
      </Content>
    </Wrapper>
  );
};
