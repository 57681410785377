import { cloneElement, forwardRef, ReactElement, useEffect, useState } from 'react';

import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  ButtonsWrapper,
  CheckIcon,
  DividerStyled,
  DrawerItemWrapper,
  ItemLabel,
  NameWrapper,
  SelectStyled,
  StyledButton,
  StyledDrawer,
} from './DrawerSelect.styled';

interface DrawerSelectOptions {
  value: string;
  label: string;
  disabled?: boolean;
}

interface DrawerSelectProps {
  title: string;
  onOk: (value: string | string[]) => void;
  onOkText: string;
  options: DrawerSelectOptions[];
  placeholder: string;
  value?: string | string[];
  size?: 'default' | 'large';
  mode?: 'tags' | 'multiple';
  isMultiple?: boolean;
  field?: object;
  onClear?: () => void;
  disabled?: boolean;
  /** Pozwala na podmianę triggera */
  triggerComponent?: ReactElement;
}

const DrawerSelect = forwardRef<HTMLDivElement, DrawerSelectProps>(
  (
    {
      title,
      onOk,
      onOkText,
      options,
      placeholder,
      value,
      size = 'default',
      mode,
      isMultiple = false,
      field,
      onClear,
      disabled,
      triggerComponent,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [activeItem, setActiveItem] = useState<string | string[] | undefined>(value);
    const [open, setOpen] = useState(false);

    useEffect(() => {
      setActiveItem(isMultiple ? (Array.isArray(value) ? value : (value?.split(',') ?? [])) : value);
    }, [value, isMultiple]);

    const handleOpenDrawer = () => !disabled && setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSelectItem = (id: string) => {
      if (isMultiple) {
        setActiveItem((prev) => {
          const selected = Array.isArray(prev) ? prev : [];
          return selected.includes(id) ? selected.filter((v) => v !== id) : [...selected, id];
        });
      } else {
        setActiveItem(id);
      }
    };

    const handleConfirm = () => {
      if (isMultiple) {
        const selectedValues = options.filter((o) => (activeItem as string[])?.includes(o.value)).map((o) => o.value);
        onOk(selectedValues);
      } else {
        onOk(activeItem as string);
      }
      handleClose();
    };

    return (
      <div ref={ref}>
        {triggerComponent ? (
          cloneElement(triggerComponent, { onClick: handleOpenDrawer, disabled })
        ) : (
          <SelectStyled
            {...field}
            options={[]}
            value={options.find((o) => o.value === value)?.label}
            placeholder={placeholder}
            open={false}
            mode={mode}
            tokenSeparators={[',']}
            onClick={handleOpenDrawer}
            onClear={onClear}
            allowClear
            disabled={disabled}
          />
        )}

        <StyledDrawer
          $size={size}
          size={size}
          title={<Typography.Title level={5}>{title}</Typography.Title>}
          onClose={handleClose}
          open={open}
          footer={
            <ButtonsWrapper>
              <StyledButton type="default" onClick={handleClose}>
                {t('general.cancel')}
              </StyledButton>
              <StyledButton type="primary" onClick={handleConfirm}>
                {onOkText}
              </StyledButton>
            </ButtonsWrapper>
          }
        >
          <Flex vertical>
            {options.map((option) => {
              const isSelected = Array.isArray(activeItem)
                ? activeItem.includes(option.value)
                : activeItem === option.value;
              return (
                <DrawerItemWrapper
                  key={option.value}
                  $isDisabled={option.disabled}
                  $isSelected={isSelected}
                  onClick={() => !option.disabled && handleSelectItem(option.value)}
                >
                  <NameWrapper>
                    <ItemLabel $isDisabled={option.disabled}>{option.label}</ItemLabel>
                    {isSelected && <CheckIcon />}
                  </NameWrapper>
                  {!option.disabled && <DividerStyled />}
                </DrawerItemWrapper>
              );
            })}
          </Flex>
        </StyledDrawer>
      </div>
    );
  },
);

DrawerSelect.displayName = 'DrawerSelect';
export { DrawerSelect };
