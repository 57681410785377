import { Button, Divider, Drawer, Flex, Select, Typography } from 'antd';
import styled from 'styled-components';

import { CheckOutlined } from '@ant-design/icons';

interface StyledDrawerProps {
  $size?: string;
}

export const StyledDrawer = styled(Drawer).attrs<StyledDrawerProps>({
  closable: false,
  placement: 'bottom',
  height: 'auto',
  style: {
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    padding: 0,
    overflow: 'scroll',
  },
  styles: {
    body: {
      height: 'auto',
      maxHeight: '70dvh',
      overflow: 'auto',
    },
  },
})`
  .ant-drawer-body {
    padding: 0px;
  }
  .ant-upload-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    .ant-upload {
      width: 100%;
    }
  }
  .ant-drawer-footer {
    padding: 0;
  }
`;

export const ButtonsWrapper = styled(Flex).attrs({
  justify: 'center',
  align: 'center',
  gap: 14,
})`
  width: 100%;
  padding: 16px 20px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

interface DrawerItemWrapperProps {
  $isSelected?: boolean;
  $isDisabled?: boolean;
}

export const DrawerItemWrapper = styled(Flex).attrs({
  vertical: true,
  align: 'center',
  justify: 'flex-start',
})<DrawerItemWrapperProps>`
  height: ${({ $isDisabled }) => ($isDisabled ? 32 : 48)}px;
  ${({ $isSelected, theme }) => $isSelected && `background-color: ${theme.controlItemBgActive}`};
`;

interface ItemLabelProps {
  $isDisabled?: boolean;
}

export const ItemLabel = styled(Typography.Text)<ItemLabelProps>`
  padding: 0 24px;
  flex: 1;
  width: 100%;
  align-items: center;
  display: flex;
  ${({ $isDisabled, theme }) =>
    $isDisabled &&
    `
  color: ${theme.colorTextDisabled};
  font-size: 13px;
  padding: 0 12px;
  line-height: 20px;
  `};
`;

export const DividerStyled = styled(Divider)`
  margin: 0;
`;

export const SelectStyled = styled(Select)`
  width: 100%;
`;

export const NameWrapper = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
  flex: 1,
})`
  width: 100%;
  padding-right: 24px;
`;

export const CheckIcon = styled(CheckOutlined)`
  color: ${({ theme }) => theme.colorPrimary};
`;
