import { useState } from 'react';

import type { MenuProps } from 'antd';
import { Button } from 'antd';

import { MenuOutlined } from '@ant-design/icons';

import { Menu } from '../Menu/Menu';
import { MenuWrapper, StyledDrawer, Wrapper } from './SidebarMobile.styled';

interface SidebarProps {
  onClick?: MenuProps['onClick'];
  onClickLogo?: () => void;
}

export const SidebarMobile = (props: SidebarProps) => {
  const { onClick, onClickLogo } = props;
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Wrapper data-testid="sidebar-mobile">
      <Button type="text" onClick={showDrawer} icon={<MenuOutlined />} />
      <StyledDrawer
        maskClosable
        placement="left"
        closable={false}
        onClose={onClose}
        open={visible}
        getContainer={false}
      >
        <MenuWrapper>
          <Menu onClick={onClick} onClose={onClose} onClickLogo={onClickLogo} />
        </MenuWrapper>
      </StyledDrawer>
    </Wrapper>
  );
};
