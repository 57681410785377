import { AliasToken } from 'antd/es/theme/interface';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{ theme: AliasToken }>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    background-color: ${({ theme }) => theme.colorBgLayout};
    color: ${({ theme }) => theme.colorTextBase};
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'Albert Sans', sans-serif;
    font-weight: 400;
  }
  .ant-app {
    display: flex;
  }
  .ant-table-filter-dropdown {
    border: ${({ theme }) => `solid ${theme.colorBorderSecondary} 1px`};
  }
  .anticon {
    vertical-align: -0.15em // updated from -0.125em
  }
  .ant-typography, .ant-btn, .ant-input {
    font-family: 'Albert Sans', sans-serif;
  }
  h1, h2, h3, h4, h5, h6, p, span, button, input {
    font-family: 'Albert Sans', sans-serif;
  }
`;
