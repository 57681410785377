import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppNavigation } from 'src/routes';
import { useAuth, UserRole } from '../contexts/AuthContext';
import { useAuth0 } from '@auth0/auth0-react';
import SuspenseLoader from './SuspenseLoader';

interface ProtectedRoutesProps {
  requiredRoles?: UserRole[];
  children?: React.ReactNode;
}

export const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ requiredRoles, children }) => {
  const location = useLocation();
  const { roles, isAuthenticated: internalIsAuthenticated } = useAuth();
  const { isLoading, isAuthenticated: auth0IsAuthenticated } = useAuth0();
  const { routesMap } = useAppNavigation();

  const hasRequiredRoles = requiredRoles ? requiredRoles.some((role) => roles.includes(role)) : true;

  const searchParams = new URLSearchParams(window.location.search);
  const isAuth0Callback = searchParams.has('code') && searchParams.has('state');
  const auth0HasError = searchParams.has('error');

  if (isLoading || isAuth0Callback || auth0HasError) {
    return <SuspenseLoader />;
  }

  // if the user is authenticated and has the required roles, render the children
  if (internalIsAuthenticated || auth0IsAuthenticated) {
    if (!hasRequiredRoles) {
      return <Navigate to={routesMap.noPermission} replace state={{ from: location }} />;
    }

    return children ? <>{children}</> : <Outlet />;
  }

  if (!internalIsAuthenticated) {
    return <Navigate to={routesMap.login} replace state={{ from: location }} />;
  }
};
