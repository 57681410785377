import { Button, Drawer, Typography } from 'antd';
import { FlexGap8 } from 'src/components/CommonStyledComponents/CommonStyledComponents.styled';
import styled from 'styled-components';

import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons';

export const StyledDrawer = styled(Drawer).attrs({
  closable: false,
  placement: 'bottom',
  style: { borderTopLeftRadius: '6px', borderTopRightRadius: '6px', padding: 0, zIndex: 0 },
  styles: {
    wrapper: {
      height: 'calc(100dvh - 58px)',
      boxShadow: 'none',
    },
    mask: {
      background: 'transparent',
    },
  },
})`
  .ant-drawer-header {
    background: ${({ theme }) => theme.colorBgLayout};
  }
  .ant-drawer-body {
    background: ${({ theme }) => theme.colorBgLayout};
  }
  .ant-drawer-footer {
    border-top: none;
    background: ${({ theme }) => theme.colorBgLayout};
  }
`;

export const FooterWrapper = styled(FlexGap8)`
  padding: 8px 0;
`;

export const TitleNoMargin = styled(Typography.Title).attrs({
  style: {
    marginBottom: 0,
  },
})``;

export const StyledArrowLeft = styled(ArrowLeftOutlined)`
  color: ${({ theme }) => theme.colorPrimary};
`;

export const NavigationBackButton = styled(Button)`
  padding: 0;
`;

export const ParagraphNoMargin = styled(Typography.Paragraph).attrs({
  style: {
    marginBottom: 0,
  },
})``;

export const TextNoMargin = styled(Typography.Text).attrs({
  style: {
    marginBottom: 0,
  },
})`
  font-weight: 500;
`;

export const StyledMailIcon = styled(MailOutlined)`
  color: ${({ theme }) => theme.colorPrimary};
`;

export const StyledContactSupport = styled(TextNoMargin)`
  display: flex;
  align-items: center;
`;
