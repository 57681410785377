import axios from 'axios';

const VITE_API_PATH = import.meta.env.VITE_API_PATH ?? 'access.wicketsoft.com';
const baseURL = `${location.protocol}//${VITE_API_PATH}/api`;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
