import {
  FanguardApiContractsControllerControllerModel,
  FanguardApiContractsDevicesDeviceModel,
  FanguardApiContractsSensorSensorModel,
} from 'src/api/generated';
import { DeviceTypes } from 'src/pages/Devices/DeviceDetails/DeviceDetailsContext';

import { get } from '@/api/api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getControllerLogsOptions, getControllerOptions } from './useControllersEndpoint';
import { getSensorLogsOptions, getSensorOptions } from './useSensorsEndpoint';

const URI = 'devices';

export const getDevicesOptions = () => ({
  queryKey: [URI],
  queryFn: async () => {
    const response = await get<FanguardApiContractsDevicesDeviceModel>({ uri: URI });
    return response.data;
  },
});

const useGetDevices = (): UseQueryResult<FanguardApiContractsDevicesDeviceModel[], Error> => {
  return useQuery(getDevicesOptions());
};

const getDeviceOptions = (id: string, type: string) => {
  if (type.toLowerCase() === DeviceTypes.CONTROLLER) {
    return getControllerOptions(id);
  } else if (type.toLowerCase() === DeviceTypes.SENSOR) {
    return getSensorOptions(id);
  }
  throw new Error('Invalid device type');
};

const useGetDeviceByType = (
  id: string,
  type: string,
): UseQueryResult<FanguardApiContractsControllerControllerModel | FanguardApiContractsSensorSensorModel, Error> => {
  return useQuery(getDeviceOptions(id, type));
};

export const getDeviceLogsOptions = (id: string, type: string, lineCount?: number) => {
  if (type.toLowerCase() === DeviceTypes.CONTROLLER) {
    return getControllerLogsOptions(id, lineCount);
  } else if (type.toLowerCase() === DeviceTypes.SENSOR) {
    return getSensorLogsOptions(id, lineCount);
  }
  throw new Error('Invalid device type');
};

interface DeviceLogsResponse {
  logs: string;
}

const useGetDeviceLogs = (id: string, type: string, lineCount?: number): UseQueryResult<DeviceLogsResponse, Error> => {
  return useQuery(getDeviceLogsOptions(id, type, lineCount));
};

export { useGetDeviceByType, useGetDeviceLogs, useGetDevices };
