import { createContext, useContext } from 'react';

import { theme as antdTheme } from 'antd';

import { darkTheme, lightTheme } from 'src/theme';

import type { ThemeContextType } from './ThemeContext.types';

import useLocalStorage from 'use-local-storage';

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDarkMode, setIsDarkMode] = useLocalStorage<boolean>('isDarkMode', false);
  const { token } = antdTheme.useToken();

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const themeAlgorithm = isDarkMode ? antdTheme.darkAlgorithm : antdTheme.defaultAlgorithm;
  const customTheme = isDarkMode ? darkTheme : lightTheme;
  const combinedTheme = { ...token, ...customTheme.token };
  const setIsDarkTheme = (isDarkTheme: boolean) => {
    setIsDarkMode(isDarkTheme);
  };

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        toggleTheme,
        themeAlgorithm,
        customTheme,
        combinedTheme,
        setIsDarkTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
