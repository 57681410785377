import i18n from 'src/locales/i18n';
import * as yup from 'yup';
import {
  FanguardApiContractsSensorSensorAccessControlModeModel,
  FanguardApiContractsSensorSensorPairingMode,
} from 'src/api/generated';

export const CARD_FORMATS = ['Std26bit', 'H10304', 'Custom'] as const;
export const PROTOCOLS = ['Wiegand'] as const;
export const TIMEOUT_OPTIONS = [
  { value: '3', label: '3.0 s' },
  { value: '5', label: '5.0 s' },
  { value: '10', label: '10.0 s' },
];
export const TIMEOUT_VALUES = TIMEOUT_OPTIONS.map((opt) => Number(opt.value));

export const RESOLUTION_OPTIONS = [
  { label: 'Low (640x360)', value: 'low', width: 640, height: 360 },
  { label: 'Medium (1280x720)', value: 'medium', width: 1280, height: 720 },
  { label: 'High (1920x1080)', value: 'high', width: 1920, height: 1080 },
  { label: 'Ultra (3840x2160)', value: 'ultra', width: 3840, height: 2160 },
];

export const RESOLUTION_VALUES = RESOLUTION_OPTIONS.map((opt) => opt.value);

export const schema = yup.object().shape({
  name: yup
    .string()
    // .required(i18n.t('devices.details.settings.general.sensorNameRequired'))
    .max(40, i18n.t('devices.details.settings.general.sensorNameMax')),
  placementName: yup.string().nullable(),
  venueId: yup.string().nullable(),
  connectionId: yup.string().nullable(),
  watchlistIds: yup.array().of(yup.string()),
  minionName: yup.string().nullable(),
  ports: yup.array().of(
    yup.object().shape({
      sensors: yup.array().of(yup.string()),
      portNumber: yup.number().required(i18n.t('devices.details.settings.ports.portNumberRequired')),
      protocol: yup.string().oneOf(PROTOCOLS).required(i18n.t('devices.details.settings.ports.protocolRequired')),
      cardFormat: yup
        .string()
        .oneOf(CARD_FORMATS)
        .required(i18n.t('devices.details.settings.ports.cardFormatRequired')),
      customFormat: yup.string().when('cardFormat', (cardFormat, schema) => {
        return cardFormat[0] === 'Custom'
          ? schema.required(i18n.t('devices.details.settings.ports.customFormatRequired'))
          : schema.notRequired();
      }),
    }),
  ),
  configuration: yup.object({
    system: yup.object({
      beatInterval: yup.number().min(0).max(100),
      credentialAreaCode: yup.string().nullable(),
      scanFacilityCode: yup.string().nullable(),
      scanAreaCode: yup.string().nullable(),
      scanDeviceCode: yup.string().nullable(),
      allowedPriceScales: yup.array().of(yup.string()).nullable(),
      orderFulfillmentLocationCode: yup.string().nullable(),
      orderFulfillmentDeviceCode: yup.string().nullable(),
      controllerId: yup.string().nullable(),
    }),
    sensor: yup.object({
      cameraUrl: yup.string().nullable(),
      enableZmqInterface: yup.boolean(),
    }),
    recognition: yup.object({
      diagnosticsEnabled: yup.boolean(),
      credentialingPrinterId: yup.string().nullable(),
      credentialingPreCheckEnabled: yup.boolean(),
      dayPassTicketingCampaignId: yup.string().nullable(),
      allowTicketlessEnrollment: yup.boolean(),
      allowDayPassScans: yup.boolean(),
      campaignIds: yup.array().of(yup.string()).nullable(),
      enrollmentQuickCheckEnabled: yup.boolean(),
      accessControlMode: yup
        .mixed<FanguardApiContractsSensorSensorAccessControlModeModel>()
        .oneOf(
          Object.values(
            FanguardApiContractsSensorSensorAccessControlModeModel,
          ) as FanguardApiContractsSensorSensorAccessControlModeModel[],
        )
        .required(),
      detectionDistance: yup.number().nullable(),
      requireConfirmationAfterGranted: yup.boolean(),
      spoofDetectionEnabled: yup.boolean(),
      intentDetectionEnabled: yup.boolean(),
      screenSaverEnabled: yup.boolean(),
      accessResultTimeout: yup.number().oneOf(TIMEOUT_VALUES).nullable(),
      snapshot: yup.object({
        value: yup.string().oneOf(RESOLUTION_VALUES),
        quality: yup.number().min(0).max(100),
        interval: yup.number().min(0).max(1000),
        enabled: yup.boolean(),
      }),
      blurFaces: yup.boolean(),
      contextImage: yup
        .object({
          value: yup
            .string()
            .oneOf(RESOLUTION_OPTIONS.map((opt) => opt.value))
            .notRequired(),
          quality: yup.number().min(0).max(100),
          enabled: yup.boolean(),
        })
        .nullable(),
    }),
  }),
  sensorPair: yup.object({
    operatorSensorId: yup.string().nullable(),
    attendantSensorId: yup.string().nullable(),
    sensorIds: yup.array().of(yup.string()).nullable(),
    guestSensorId: yup.string().nullable(),
    sensorPairingMode: yup
      .mixed<FanguardApiContractsSensorSensorPairingMode | string>()
      .oneOf([...Object.values(FanguardApiContractsSensorSensorPairingMode), 'Auto', 'Wifi', 'Bluetooth'])
      .nullable(),
  }),
});

export type FormValues = yup.InferType<typeof schema>;
