import { Flex } from 'antd';
import styled from 'styled-components';

export const PopoverContent = styled(Flex)`
  min-width: 320px;
  height: auto;
`;

export const BorderedWrapper = styled(Flex)`
  padding: 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderSecondary};
`;

export const ButtonWrapper = styled(Flex)`
  padding: 16px 20px;
`;
