import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export class SignalRService {
  private connection: HubConnection | null = null;
  private isConnecting = false;

  public async startConnection(hubUrl: string) {
    if (this.connection?.state === 'Connected') {
      return;
    }

    if (this.isConnecting) {
      while (this.isConnecting) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      return;
    }

    try {
      this.isConnecting = true;
      this.connection = new HubConnectionBuilder()
        .withUrl(hubUrl, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => localStorage.getItem('token') ?? '',
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      await this.connection.start();
      console.log('SignalR Connected!');
    } catch (err) {
      console.error('SignalR Connection Error: ', err);
      throw err;
    } finally {
      this.isConnecting = false;
    }
  }

  public addListener(methodName: string, callback: (...args: unknown[]) => void) {
    this.connection?.on(methodName, callback);
  }

  public removeListener(methodName: string, callback: (...args: unknown[]) => void) {
    this.connection?.off(methodName, callback);
  }

  public async invoke(methodName: string, ...args: unknown[]) {
    try {
      await this.connection?.invoke(methodName, ...args);
    } catch (err) {
      console.error('SignalR Invoke Error: ', err);
      throw err;
    }
  }

  public stop() {
    this.connection?.stop();
  }

  public get isConnected() {
    return this.connection?.state === 'Connected';
  }
}

export const signalR = new SignalRService();
