import type { ThemeConfig } from 'antd';

import { MENU_TOKENS } from './components/Sidebar/Menu/Menu.styled';

const FONTS = {
  fontFamily: "'Albert Sans', sans-serif",
  fontSize: 15,
  fontSizeLG: 17,
  fontSizeSM: 13,
  fontSizeHeading3: 25,
  fontSizeHeading4: 21,
  fontSizeHeading5: 17,
  fontWeightStrong: 500,
};

const MARGINS = {
  marginXS: 8,
};

const PADDINGS = {
  paddingXS: 8,
  paddingSM: 12,
};

const BORDER_RADIUS = {
  borderRadiusSM: 4,
};

const BOX_SHADOWS = {
  boxShadowSecondary:
    '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);',
};

const DEFAULT_SETTINGS = {
  ...MARGINS,
  ...PADDINGS,
  ...BORDER_RADIUS,
  ...FONTS,
  ...BOX_SHADOWS,
};

export const lightTheme: ThemeConfig = {
  token: {
    colorPrimary: '#1bb5aa',
    colorBgLayout: '#f4f4f4',
    colorBgContainer: '#ffffff',
    colorSuccessBgHover: '#D5F5E1',
    colorErrorBgHover: '#FFEAEA',
    colorInfo: '#383d3d',
    colorTextDescription: '#00000073',
    colorSuccessBg: '#F0FFF5',
    colorSuccessHover: '#79DBA3',
    colorSuccess: '#2cc277',
    colorWarning: '#ffba00',
    colorError: '#f54761',
    colorBgBase: '#ffffff',
    colorBgElevated: '#ffffff',
    colorSplit: 'rgba(0, 0, 0, 0.06)',
    colorPrimaryBorder: '#8adbce',
    colorBorder: '#E2E2E2',
    colorBorderSecondary: '#F0F0F0',
    controlItemBgActive: '#e9f9f5',
    colorFillSecondary: '#F0F0F0',
    colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',
    colorTextBase: '#000000',
    colorTextHeading: 'rgba(0, 0, 0, 0.88)',
    colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
    colorText: 'rgba(0, 0, 0, 0.88)',
    colorTextLabel: '#595959',
    colorTextTertiary: '#8C8C8C',
    colorErrorBg: '#FFF3F3',
    colorSuccessBorderHover: '#79DBA3',
    colorErrorBorder: '#FFC4C7',
    colorErrorTextActive: '#DD2F54',
    colorSuccessTextActive: '#1C9C60',
    colorSuccessText: '#2CC277',
    colorIcon: '#8C8C8C',
    controlItemBgHover: '#F5F5F5',
    colorBgMask: '#00000073',
    colorWarningBg: '#FFFCE6',
    colorWarningBorder: '#FFE97A',
    ...DEFAULT_SETTINGS,
  },
  components: {
    Menu: MENU_TOKENS.light,
    Button: {
      ghostBg: 'rgba(255, 234, 234, 1)',
    },
    Card: {
      colorBorder: '##FFC4C7',
    },
    Pagination: {
      colorTextDisabled: '#BFBFBF',
    },
  },
};

export const CARD_TOKENS = {
  light: {
    contentColor: '1F1F1F',
  },
  dark: {
    contentColor: 'DCDCDC',
  },
};
export const darkTheme: ThemeConfig = {
  token: {
    colorPrimary: '#39b2a6',
    colorBgLayout: '#0B0D0E',
    colorBgContainer: '#131617',
    colorSuccessBgHover: '#153729',
    colorErrorBgHover: '#491F2A',
    colorInfo: '#39b2a6',
    colorTextDescription: '#ffffff73',
    colorText: '#DCDCDC',
    colorTextLabel: '#ACADAE',
    colorSuccessBg: '#12231F',
    colorSuccessHover: '#1E6342',
    colorSuccessBorder: '#1B4934',
    colorSuccess: '#28a869',
    colorWarning: '#dca103',
    colorError: '#da455c',
    colorBgBase: '#1a1e24',
    colorBgElevated: '#1C2021',
    colorSplit: 'rgba(255, 255, 255, 0.10)',
    colorBorder: '#3C3F44',
    colorPrimaryBorder: '#1a6260',
    colorBorderSecondary: '#313439',
    controlItemBgActive: '#133134',
    colorFillSecondary: '#2F3233',
    colorFillQuaternary: 'rgba(255, 255, 255, 0.04)',
    colorTextBase: '#ffffff',
    colorTextHeading: 'rgba(255, 255, 255, 0.85)',
    colorTextDisabled: 'rgba(f, f, f, 0.25)',
    colorTextTertiary: '#7D7F7F',
    geekblue: '#ccd9eb',
    colorErrorBg: '#2B151C',
    colorSuccessBorderHover: '#1E6342',
    colorErrorBorder: '#612935',
    colorErrorTextActive: '#DA455C',
    colorSuccessTextActive: '#4BBC7F',
    colorSuccessText: '#4BBC7F',
    colorIcon: '#7D7F7F',
    controlItemBgHover: '#26292A',
    colorBgMask: '#00000073',
    colorWarningBg: '#2B2414',
    colorWarningBorder: '#5A4710',
    ...DEFAULT_SETTINGS,
  },
  components: {
    Menu: MENU_TOKENS.dark,
    Button: {
      ghostBg: 'rgba(73, 31, 42, 1)',
    },
    Pagination: {
      colorTextDisabled: '#4E5051',
    },
  },
};
