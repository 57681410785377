import { FanguardApiContractsUserUserDetailModel } from 'src/api/generated';
import { get } from '@/api/api';
import { useAuth } from '@/contexts/AuthContext';
import { useQuery } from '@tanstack/react-query';

const URI = 'users';

const useGetUser = () => {
  const { username } = useAuth();

  return useQuery({
    queryKey: [URI, username],
    queryFn: async () => {
      const response = await get<FanguardApiContractsUserUserDetailModel>({ uri: `${URI}/${username}` });

      return response.data;
    },
    enabled: !!username,
  });
};

export { useGetUser };
