import { get } from '@/api/api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import {
  FanguardApiContractsClientClientResponseDetailModel,
  FanguardCoreManagersCredentialingCredentialArea,
  FanguardCoreManagersCredentialingCredentialingPrinters,
} from '../../api/generated';

const URI = 'clients';

export const getCredentialingAreasOptions = () => ({
  queryKey: [URI],
  queryFn: async () => {
    const response = await get<FanguardCoreManagersCredentialingCredentialArea>({ uri: `${URI}/credentialing-areas` });
    return response.data;
  },
});

const useGetCredentialingAreas = (): UseQueryResult<FanguardCoreManagersCredentialingCredentialArea[], Error> => {
  return useQuery(getCredentialingAreasOptions());
};

export const getClientOptions = (id: string) => ({
  queryKey: [URI, id],
  queryFn: async () => {
    const response = await get<FanguardApiContractsClientClientResponseDetailModel>({ uri: `${URI}/${id}` });
    return response.data;
  },
});

const useGetClient = (id: string): UseQueryResult<FanguardApiContractsClientClientResponseDetailModel, Error> => {
  return useQuery(getClientOptions(id));
};

export const getClientPrintersOptions = () => ({
  queryKey: [URI, 'printers'],
  queryFn: async () => {
    const response = await get<FanguardCoreManagersCredentialingCredentialingPrinters>({
      uri: `${URI}/printers`,
    });
    return response.data;
  },
});

const useGetClientPrinters = (): UseQueryResult<FanguardCoreManagersCredentialingCredentialingPrinters, Error> => {
  return useQuery(getClientPrintersOptions());
};

export { useGetClient, useGetClientPrinters, useGetCredentialingAreas };
