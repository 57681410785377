import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'src/contexts/AuthContext';
import { post, onError } from 'src/api/api';
import { clientConfig } from 'src/api/axios/config';
const URI = 'token';

export interface LoginData {
  username: string;
  password: string;
}
export interface Auth0TokenData {
  auth0Token: string;
}

export interface RefreshTokenData {
  client: string;
  refreshToken: string;
}

export interface LoginResponse {
  token: string;
  refreshToken: string;
  status: number;
}

const postToken = async (data: LoginData | Auth0TokenData | RefreshTokenData) => {
  const response = await post({ uri: URI, data });
  return response.data;
};

const setInitialClient = async (token: string) => {
  try {
    const decodedToken = await JSON.parse(atob(token.split('.')[1]));
    const scopes = await JSON.parse(decodedToken.scopes);
    const client = scopes[0].client;
    clientConfig.setClient(client);
  } catch (error) {
    console.error('Error setting initial client', error);
  }
};

const usePostToken = () => {
  const { login } = useAuth();

  return useMutation({
    mutationFn: (data: LoginData | Auth0TokenData | RefreshTokenData) => postToken(data),
    onSuccess: (data: LoginResponse) => {
      const { token, refreshToken } = data;
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      setInitialClient(token);
      login(token);
    },
    onError,
  });
};

export { usePostToken };
