import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';

import { Form, Modal } from 'antd';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  FanguardApiContractsClientSiteDetailModel,
  FanguardApiContractsControllerControllerModel,
  FanguardApiContractsDevicesDeviceModel,
  FanguardApiContractsSensorSensorModel,
  FanguardCoreManagersCredentialingCredentialingPrinters,
  FanguardApiContractsSensorSensorPairingMode,
  FanguardApiContractsSensorSensorAccessControlModeModel,
} from 'src/api/generated';
import MobileDialog from 'src/components/MobileDialog';
import { useGlobal } from 'src/contexts/GlobalContext';
import { useTheme } from 'src/contexts/ThemeContext';
import { useGetClientPrinters } from 'src/hooks/api/useClientsEndpoint';
import {
  useAssignSensorToController,
  useGetControllers,
  useUpdateController,
} from 'src/hooks/api/useControllersEndpoint';
import { useGetDeviceByType, useGetDevices } from 'src/hooks/api/useDevicesEndpoint';
import { useUpdateSensor } from 'src/hooks/api/useSensorsEndpoint';
import { useGetDetails } from 'src/hooks/api/useSiteEndpoint';
import { useAppNavigation } from 'src/routes';

import { yupResolver } from '@hookform/resolvers/yup';

import { CONTROLLER_ITEMS, CONTROLLER_TABS, SENSOR_ITEMS, SENSOR_TABS } from './const';
import { schema, FormValues } from './TabPages/SensorSettings/components/GeneralSettings/config';
import { QueryObserverResult } from '@tanstack/react-query';

export enum DeviceTypes {
  CONTROLLER = 'controller',
  SENSOR = 'sensor',
}

type ControllerDevice = FanguardApiContractsControllerControllerModel & { deviceType: DeviceTypes.CONTROLLER };
type SensorDevice = FanguardApiContractsSensorSensorModel & { deviceType: DeviceTypes.SENSOR };
type Device = ControllerDevice | SensorDevice;

interface DeviceDetailsContextType {
  handleTabChange: (type: string, key: string) => void;
  handleCancel: () => void;
  handleSave: (key?: string) => Promise<void>;
  items: { label: string; key: string }[];
  tabs: unknown;
  handleNavigateBack: () => void;
  device?: SensorDevice | ControllerDevice;
  isDeviceLoading: boolean;
  formMethods: ReturnType<typeof useForm<FormValues>>;
  isPending: boolean;
  isDirty: boolean;
  devicesData: FanguardApiContractsDevicesDeviceModel[] | undefined;
  devicesDataLoading: boolean;
  detailsData: FanguardApiContractsClientSiteDetailModel[] | undefined;
  detailsDataLoading: boolean;
  clientPrintersData: FanguardCoreManagersCredentialingCredentialingPrinters | undefined;
  clientPrintersDataLoading: boolean;
  controllersData: FanguardApiContractsControllerControllerModel[] | undefined;
  controllersDataLoading: boolean;
  isPendingAssignSensorToController: boolean;
  refetchDevice: () => Promise<
    QueryObserverResult<FanguardApiContractsSensorSensorModel | FanguardApiContractsControllerControllerModel, Error>
  >;
  isDeviceFetching: boolean;
}

const DeviceDetailsContext = createContext<DeviceDetailsContextType | undefined>(undefined);

export const useDeviceDetailsContext = (): DeviceDetailsContextType => {
  const context = useContext(DeviceDetailsContext);
  if (!context) {
    throw new Error('useDeviceDetailsContext must be used within DeviceDetailsProvider');
  }
  return context;
};

export const useSensorDetailsContext = (): Omit<ReturnType<typeof useDeviceDetailsContext>, 'device'> & {
  device?: SensorDevice;
} => {
  const context = useDeviceDetailsContext();
  const { type } = useParams<{ type: 'sensor' | 'controller' }>();

  if (type !== DeviceTypes.SENSOR) {
    throw new Error('This context is intended for sensor devices only');
  }
  return { ...context, device: context.device as SensorDevice };
};

export const useControllerDetailsContext = (): Omit<ReturnType<typeof useDeviceDetailsContext>, 'device'> & {
  device?: ControllerDevice;
} => {
  const context = useDeviceDetailsContext();
  const { type } = useParams<{ type: 'sensor' | 'controller' }>();

  if (type !== DeviceTypes.CONTROLLER) {
    throw new Error('This context is intended for controller devices only');
  }
  return { ...context, device: context.device as ControllerDevice };
};

interface DeviceDetailsProviderProps {
  children: ReactNode;
}

export const getDefaultValues = (device: SensorDevice, id: string, accessControlMode?: string) => ({
  ...device,
  name: device?.name ?? '',
  ...(device?.deviceType === DeviceTypes.SENSOR && {
    configuration: {
      ...device?.configuration,
      recognition: {
        ...device?.configuration?.recognition,
        accessControlMode: accessControlMode
          ? (accessControlMode as unknown as FanguardApiContractsSensorSensorAccessControlModeModel)
          : (device?.configuration?.recognition?.accessControlMode ?? undefined),
        credentialingPrinterId: device?.configuration?.recognition?.credentialingPrinterId
          ? device?.configuration?.recognition?.credentialingPrinterId
          : device?.configuration?.recognition?.credentialingPrinterGroupId
            ? device?.configuration?.recognition?.credentialingPrinterGroupId
            : '',
      },
    },
    ...(device.sensorPair?.sensorIds?.[1]?.length
      ? {
          sensorPair: {
            ...device?.sensorPair,
            sensorIds: [id, device.sensorPair.sensorIds?.[1]],
            attendantSensorId: device?.sensorPair?.attendantSensorId ?? id,
            operatorSensorId: device?.sensorPair?.operatorSensorId ?? id,
            sensorPairingMode:
              device?.sensorPair?.sensorPairingMode ?? FanguardApiContractsSensorSensorPairingMode.Auto,
            guestSensorId: device?.sensorPair?.sensorIds?.[1] ?? null,
          },
        }
      : {
          sensorPair: {
            operatorSensorId: device?.sensorPair?.operatorSensorId ?? '',
            attendantSensorId: device?.sensorPair?.attendantSensorId ?? '',
            sensorIds: [
              device?.sensorPair?.operatorSensorId ?? undefined,
              device?.sensorPair?.attendantSensorId ?? undefined,
            ],
            guestSensorId: device?.sensorPair?.guestSensorId ?? '',
            sensorPairingMode: device?.sensorPair?.sensorPairingMode
              ? (device.sensorPair.sensorPairingMode as unknown as FanguardApiContractsSensorSensorPairingMode)
              : FanguardApiContractsSensorSensorPairingMode.Auto,
          },
        }),
  }),
});

export const DeviceDetailsProvider = ({ children }: DeviceDetailsProviderProps) => {
  const { id, type } = useParams<{ id: string; type: string }>();
  const { t } = useTranslation();
  const { navigateTo } = useAppNavigation();
  const { isDesktop } = useGlobal();
  const [modal, contextHolder] = Modal.useModal();
  const { combinedTheme } = useTheme();
  const { mutateAsync: mutateAsyncAssignSensorToController, isPending: isPendingAssignSensorToController } =
    useAssignSensorToController();
  const { mutate: updateController, isPending: isUpdateControllerPending } = useUpdateController();
  const { data: devicesData, isLoading: devicesDataLoading } = useGetDevices();
  const { mutateAsync, isPending: isUpdateSensorPending } = useUpdateSensor();
  const {
    data: deviceData,
    isLoading: isDeviceLoading,
    isFetching: isDeviceFetching,
    refetch: refetchDevice,
  } = useGetDeviceByType(`${id}`, `${type}`);
  const { data: detailsData, isLoading: detailsDataLoading } = useGetDetails();
  const { data: clientPrintersData, isLoading: clientPrintersDataLoading } = useGetClientPrinters();
  const { data: controllersData, isLoading: controllersDataLoading } = useGetControllers();

  const isPending = isUpdateSensorPending || isUpdateControllerPending;

  const device = useMemo<Device | undefined>(() => {
    if (deviceData) {
      return 'ports' in deviceData
        ? ({ ...deviceData, deviceType: DeviceTypes.CONTROLLER } as ControllerDevice)
        : ({ ...deviceData, deviceType: DeviceTypes.SENSOR } as SensorDevice);
    }
    return undefined;
  }, [deviceData]);

  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      ports:
        device?.deviceType === DeviceTypes.CONTROLLER && device?.ports
          ? device.ports.map((port) => ({
              ...port,
              sensors: port.sensors ?? undefined,
            }))
          : [],
    },
  });

  useEffect(() => {
    if (device?.deviceType === DeviceTypes.SENSOR && id) {
      methods.reset({
        ...getDefaultValues(device, id),
      });
    }
    if (device?.deviceType === DeviceTypes.CONTROLLER && device.ports) {
      methods.reset({
        ...methods.getValues(),
        ports: device.ports.map((port) => ({
          ...port,
          sensors: port.sensors ?? undefined,
        })),
      });
    }
  }, [device]);

  const { handleSubmit, reset, formState } = methods;
  const { isDirty } = formState;

  const handleResetForm = () => {
    if (device?.deviceType === DeviceTypes.SENSOR && id) {
      reset(getDefaultValues(device, id));
    }
  };

  const { items, tabs } = useMemo(() => {
    if (device?.deviceType === DeviceTypes.CONTROLLER) {
      return { items: CONTROLLER_ITEMS, tabs: CONTROLLER_TABS };
    }
    if (device?.deviceType === DeviceTypes.SENSOR) {
      return { items: SENSOR_ITEMS, tabs: SENSOR_TABS };
    }
    return { items: [], tabs: [] };
  }, [device]);

  const handleMutateSensor = async (data: FanguardApiContractsSensorSensorModel) => await mutateAsync(data);

  const onSubmit = async (formData: FieldValues) => {
    if (device?.deviceType === DeviceTypes.SENSOR) {
      const isGroupPrinter = !!clientPrintersData?.groups?.find(
        (group) => group.id === formData?.configuration?.recognition?.credentialingPrinterId,
      );
      const isSinglePrinter = !!clientPrintersData?.printers?.find(
        (printer) => printer.id === formData?.configuration?.recognition?.credentialingPrinterId,
      );

      const isControllerAdded = !!formData?.configuration?.system?.controllerId;
      const isNewController =
        formData?.configuration?.system?.controllerId !== device?.configuration?.system?.controllerId;
      if (isControllerAdded && isNewController && id) {
        await mutateAsyncAssignSensorToController({
          controllerId: formData?.configuration?.system?.controllerId,
          sensorId: id,
          portNumber:
            controllersData?.find((controller) => controller.id === formData.configuration.system.controllerId)
              ?.ports?.[0]?.portNumber ?? 0,
        });
      }
      const formattedData = {
        ...formData,
        configuration: {
          ...formData.configuration,
          recognition: {
            ...formData.configuration.recognition,
            ...(isSinglePrinter && {
              credentialingPrinterId: formData.configuration.recognition.credentialingPrinterId,
              credentialingPrinterGroupId: null,
            }),
            ...(isGroupPrinter && {
              credentialingPrinterGroupId: formData.configuration.recognition.credentialingPrinterId,
              credentialingPrinterId: null,
            }),
          },
        },
        ...(!formData?.sensorPair?.sensorIds?.[1] && {
          sensorPair: {},
        }),
      };
      return handleMutateSensor(formattedData);
    }

    if (device?.deviceType === DeviceTypes.CONTROLLER) {
      return updateController({
        id: device.id ?? '',
        ...formData,
      });
    }
    return null;
  };

  const handleSave = async () => {
    await handleSubmit((formData) => onSubmit(formData))();
  };

  const handleReset = (key: string, instance?: ReturnType<typeof modal.confirm>) => {
    handleResetForm();
    navigateTo.devicesDetails(`${id}`, `${type}`, key);
    if (instance) {
      instance.destroy();
    }
  };

  const handleCancel = () => handleResetForm();

  const handleTabChange = (type: string, key: string) => {
    if (isDirty) {
      if (isDesktop) {
        const instance = modal.confirm({
          title: t('devices.details.settings.unsavedChanges'),
          content: t('devices.details.settings.unsavedChangesDescription'),
          okText: t('general.ok').toUpperCase(),
          cancelText: t('general.cancel'),
          onOk: () => handleReset(key, instance),
          okButtonProps: { type: 'primary', danger: true },
        });
      } else {
        return MobileDialog({
          title: t('devices.details.settings.unsavedChanges'),
          description: t('devices.details.settings.unsavedChangesDescription'),

          confirmText: t('general.ok').toUpperCase(),
          cancelText: t('general.cancel'),
          onConfirm: () => handleReset(key),
          iconColor: combinedTheme.colorWarning,
          cancelActionColor: combinedTheme.colorPrimary,
          confirmActionColor: combinedTheme.colorError,
        });
      }
    } else {
      navigateTo.devicesDetails(`${id}`, `${type}`, `${key}`);
    }
  };

  const handleNavigateBack = () => navigateTo.devices();

  const typedDevice = useMemo(() => {
    if (device?.deviceType === DeviceTypes.CONTROLLER) {
      return device as ControllerDevice;
    }
    if (device?.deviceType.toLowerCase() === DeviceTypes.SENSOR) {
      return device as SensorDevice;
    }
    return undefined;
  }, [device]);

  const value = useMemo(
    () => ({
      handleTabChange,
      handleCancel,
      handleSave,
      items,
      tabs,
      handleNavigateBack,
      device: typedDevice,
      isDeviceLoading,
      formMethods: methods,
      isDirty,
      devicesData,
      isPending,
      devicesDataLoading,
      detailsData,
      detailsDataLoading,
      clientPrintersData,
      clientPrintersDataLoading,
      controllersData,
      controllersDataLoading,
      isPendingAssignSensorToController,
      refetchDevice,
      isDeviceFetching,
    }),
    [
      handleTabChange,
      handleCancel,
      handleSave,
      items,
      tabs,
      handleNavigateBack,
      deviceData,
      isDeviceLoading,
      methods,
      device,
      isPending,
      isDirty,
      devicesData,
      devicesDataLoading,
      detailsData,
      detailsDataLoading,
      clientPrintersData,
      clientPrintersDataLoading,
      controllersData,
      controllersDataLoading,
      isPendingAssignSensorToController,
      refetchDevice,
      isDeviceFetching,
    ],
  );

  return (
    <DeviceDetailsContext.Provider value={value}>
      {contextHolder}
      <FormProvider {...methods}>
        {!isDeviceLoading && !devicesDataLoading && !clientPrintersDataLoading && !controllersDataLoading && (
          <Form layout="vertical">{children}</Form>
        )}
      </FormProvider>
    </DeviceDetailsContext.Provider>
  );
};
