import { ReactNode } from 'react';
import { ConfigAuth } from 'src/hooks/api/useConfigEndpoint';

export enum UserRole {
  Admin = 'admin',
  Manager = 'manager',
  User = 'user',
}

export interface AuthContextType {
  isAuthenticated: boolean;
  roles: UserRole[];
  login: (token: string) => void;
  logout: () => void;
  username: string | null;
}

export interface AuthProviderProps {
  children: ReactNode;
  configAuthData?: ConfigAuth;
}
