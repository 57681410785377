import { Auth0Provider as Auth0ProviderSDK } from '@auth0/auth0-react';
import { useGetConfigAuth } from 'src/hooks/api/useConfigEndpoint';
import SuspenseLoader from '../SuspenseLoader';
import { AuthProvider } from 'src/contexts/AuthContext/AuthContext';

interface Auth0ProviderProps {
  children: React.ReactNode;
}
const VITE_API_PATH = import.meta.env.VITE_API_PATH ?? 'access.wicketsoft.com';

const Auth0Provider = ({ children }: Auth0ProviderProps) => {
  const { data: configAuthData, isLoading, isError } = useGetConfigAuth();

  const domain = configAuthData?.domain || '';
  const clientId = configAuthData?.clientId || '';
  const clientName = domain.split('.')[0] || 'demo';

  // Docs for working with auth0 locally:
  // https://wicketsoft.atlassian.net/wiki/spaces/wa/pages/2902458370/Authentication+with+Auth0
  const authorizationParams = {
    redirect_uri: window.location.origin,
    audience: `https://${clientName}.${VITE_API_PATH}/api`, // TODO: update to use slug returned by services
  };

  if (isLoading && !isError) {
    return <SuspenseLoader />;
  }

  return (
    <Auth0ProviderSDK
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
      cacheLocation="localstorage"
    >
      <AuthProvider configAuthData={configAuthData}>{children}</AuthProvider>
    </Auth0ProviderSDK>
  );
};

export default Auth0Provider;
