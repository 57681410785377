import { Flex, Typography } from 'antd';
import styled from 'styled-components';

import { ExclamationCircleFilled } from '@ant-design/icons';

export const Wrapper = styled(Flex).attrs({
  vertical: true,
  align: 'center',
  justify: 'center',
})`
  padding: 20px 12px 0;
`;

interface ModalIconProps {
  $iconColor: string;
}

export const ModalIcon = styled(ExclamationCircleFilled)<ModalIconProps>`
  font-size: 64px;
  margin-bottom: 10px;
  color: ${({ $iconColor }) => $iconColor};
`;

export const Title = styled(Typography.Text)`
  margin-bottom: 10px;
  font-weight: 500;
`;
