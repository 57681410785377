import React, { createContext, useContext, useEffect, useState } from 'react';

import { AuthContextType, AuthProviderProps, UserRole } from './AuthContext.types';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from 'src/api/axios/axiosInstance';
import { AxiosError } from 'axios';
import { signalR } from 'src/signalR';

const VITE_API_PATH = import.meta.env.VITE_API_PATH ?? 'access.wicketsoft.com';

const AuthContext = createContext<AuthContextType | null>(null);

// in my opinion it should be temporary solution just to allow login and look around the app
const isTokenExpired = (token: string) => {
  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  const now = Date.now() / 1000;
  return decodedToken.exp < now;
};

const getAuthInfo = () => {
  const token = localStorage.getItem('token');
  if (!token || isTokenExpired(token)) {
    return { isAuthenticated: false, username: null };
  }

  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  const username = decodedToken.sub as string;

  return {
    isAuthenticated: true,
    username,
  };
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, configAuthData }) => {
  const { isAuthenticated, username } = getAuthInfo();

  const {
    user,
    isAuthenticated: auth0IsAuthenticated,
    logout: auth0Logout,
    getAccessTokenSilently,
    isLoading: auth0IsLoading,
    loginWithRedirect,
  } = useAuth0();

  const [authData, setAuthData] = useState<Omit<AuthContextType, 'login' | 'logout'>>({
    isAuthenticated,
    username,
    roles: [],
  });

  const addBearerAuth0Token = (token: string) => {
    const slug = window.location.hostname.split('.')[0]; // cannot rely on client name, needs domain slug for auth0 clients
    (axiosInstance.defaults.baseURL = `${location.protocol}//${slug}.${VITE_API_PATH}/api`),
      axiosInstance.interceptors.request.use(
        (config) => {
          config.headers.set('Authorization', `Bearer-Auth0 ${token}`);
          config.headers.set('Client', configAuthData?.client ?? 'panthers');
          return config;
        },
        (error: AxiosError): Promise<AxiosError> => {
          return Promise.reject(error);
        },
      );
  };

  const getAuth0Token = async () => {
    try {
      const auth0Token = await getAccessTokenSilently();
      addBearerAuth0Token(auth0Token);
      localStorage.setItem('auth0-token', auth0Token);
      return auth0Token;
    } catch (error) {
      console.error(error);
    }
  };

  const login = (token: string) => {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const username = decodedToken.sub as string;
    const roles = [UserRole.Admin]; // TODO: get roles from ?
    setAuthData({
      username,
      isAuthenticated: true,
      roles,
    });
  };

  const logoutFromAuth0 = () => {
    localStorage.removeItem('auth0-token');
    if (auth0IsAuthenticated) {
      auth0Logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  };

  const logout = () => {
    logoutFromAuth0();
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    setAuthData({
      isAuthenticated: false,
      username: null,
      roles: [],
    });
  };

  // handle already logged in auth0
  useEffect(() => {
    const auth0token = localStorage.getItem('auth0-token');

    if (auth0token) {
      addBearerAuth0Token(auth0token);
    }

    if (user?.sub && auth0IsAuthenticated) {
      setAuthData({
        isAuthenticated: true,
        username: user.sub,
        roles: [],
      });
    }
  }, [user?.sub, auth0IsAuthenticated]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get('error');
    const code = searchParams.get('code');
    const auth0Token = localStorage.getItem('auth0-token');
    const isLoginPage = window.location.pathname === '/login';

    if (
      configAuthData?.platform === 'Auth0' &&
      !auth0IsLoading &&
      isLoginPage &&
      !isAuthenticated &&
      !error &&
      !code &&
      !auth0Token
    ) {
      loginWithRedirect();
    }
  }, [isAuthenticated, configAuthData?.platform, auth0IsLoading, auth0IsAuthenticated]);

  useEffect(() => {
    const auth0Token = localStorage.getItem('auth0-token');

    if (configAuthData?.platform === 'Auth0' && !auth0Token) {
      getAuth0Token();
    }
  }, [configAuthData?.platform]);

  useEffect(() => {
    if (authData.isAuthenticated && !signalR.isConnected) {
      const initSignalR = async () => {
        try {
          const baseUrl = `${axiosInstance.defaults.baseURL}/notification`;
          await signalR.startConnection(baseUrl);
        } catch (error) {
          console.error('Failed to connect to SignalR:', error);
        }
      };

      initSignalR();

      return () => {
        signalR.stop();
      };
    }
  }, [authData.isAuthenticated]);

  return <AuthContext.Provider value={{ ...authData, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
