import { Button, Flex, Card, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  StyledDrawer,
  TitleNoMargin,
  FooterWrapper,
  StyledArrowLeft,
  NavigationBackButton,
  ParagraphNoMargin,
  TextNoMargin,
  StyledMailIcon,
  StyledContactSupport,
} from './MobileUserProfile.styled';
import { useGetUser } from 'src/hooks/api/useUsersEndpoint';
import { FlexGap16 } from 'src/components/CommonStyledComponents/CommonStyledComponents.styled';
import { useTheme } from 'src/contexts/ThemeContext';
import { CheckOutlined, CloseOutlined, LogoutOutlined } from '@ant-design/icons';
import { useAuth } from 'src/contexts/AuthContext';
import { SUPPORT_EMAIL, PRIVACY_POLICY_URL } from 'src/utils/constants';

interface MobileUserProfileProps {
  drawerVisible: boolean;
  toggleDrawer: () => void;
}

const BUILD_VERSION = import.meta.env.BUILD_NUMBER ?? '';

const MobileUserProfile = (props: MobileUserProfileProps) => {
  const { drawerVisible, toggleDrawer } = props;
  const { t } = useTranslation();
  const { isDarkMode, toggleTheme } = useTheme();
  const { logout } = useAuth();

  const { data } = useGetUser();

  const onPrivacyPolicyClick = () => window.open(PRIVACY_POLICY_URL, '_blank');

  const onMailToHandler = () => window.open(`mailto:${SUPPORT_EMAIL}`);

  const nameLastname = `${data?.firstName} ${data?.lastName}`;

  return (
    <StyledDrawer
      onClose={toggleDrawer}
      open={drawerVisible}
      key="bottomDrawer"
      title={
        <Flex>
          <NavigationBackButton type="text" icon={<StyledArrowLeft />} onClick={toggleDrawer} />
          <TitleNoMargin level={3}>{t('general.yourProfile')}</TitleNoMargin>
        </Flex>
      }
      footer={
        <FooterWrapper>
          <Button block onClick={onPrivacyPolicyClick}>
            {t('general.privacyPolicy')}
          </Button>
          <Button block type="primary" onClick={logout} icon={<LogoutOutlined />} iconPosition="end">
            {t('general.logout')}
          </Button>
        </FooterWrapper>
      }
    >
      <FlexGap16 vertical>
        <Card title={null}>
          <TitleNoMargin level={5}>{nameLastname}</TitleNoMargin>
          <ParagraphNoMargin type="secondary">{data?.username}</ParagraphNoMargin>
          <ParagraphNoMargin>{data?.email}</ParagraphNoMargin>
        </Card>
        <Card title={null}>
          <Flex justify="space-between">
            <TextNoMargin strong>{t('general.version')}</TextNoMargin>
            <TextNoMargin type="secondary">{BUILD_VERSION}</TextNoMargin>
          </Flex>
        </Card>
        <Card title={null}>
          <Flex justify="space-between">
            <TextNoMargin strong>{t('general.darkMode')}</TextNoMargin>
            <Switch
              checked={isDarkMode}
              onChange={toggleTheme}
              title="Switch Theme Mode"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Flex>
        </Card>
        <Card title={null}>
          <Flex justify="space-between">
            <StyledContactSupport strong>{t('general.contactSupport')}</StyledContactSupport>
            <Button type="text" icon={<StyledMailIcon />} onClick={onMailToHandler} />
          </Flex>
        </Card>
      </FlexGap16>
    </StyledDrawer>
  );
};

export default MobileUserProfile;
