import { Button, Result } from 'antd';
import { useAppNavigation } from 'src/routes';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
  const { state } = useLocation();
  const { navigateTo } = useAppNavigation();
  const { t } = useTranslation();

  const subTitle = state?.message ?? t('general.somethingWentWrong');

  return (
    <Result
      status={state?.status ?? '404'}
      title={state?.status ?? '404'}
      subTitle={subTitle}
      extra={
        <Button type="primary" onClick={() => navigateTo.landing()}>
          {t('general.home')}
        </Button>
      }
    />
  );
};
