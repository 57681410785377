import { useEffect } from 'react';
import { queryClient } from 'src/main';
import { useAppNavigation } from 'src/routes';
import { routesMap } from 'src/routes';
import { useLocation, matchPath } from 'react-router-dom';

const REDIRECT_FROM_PATHS = Object.values(routesMap).filter((route) => route.includes(':id'));
const MAX_REFETCH_COUNT = 3;

const QueryErrorHandler = ({ children }: { children: React.ReactNode }) => {
  const { navigateTo } = useAppNavigation();
  const { pathname } = useLocation();

  useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      if (event?.query.state.fetchFailureCount === MAX_REFETCH_COUNT) {
        const matchedPath = REDIRECT_FROM_PATHS.some((path) => {
          return matchPath(path, location.pathname) !== null;
        });

        if (matchedPath) {
          navigateTo.errorPage(
            event?.query.state.fetchFailureReason?.status,
            event?.query.state.fetchFailureReason?.response?.data?.title,
          );
        }
      }
    });

    return () => unsubscribe();
  }, [pathname]);

  return children;
};

export default QueryErrorHandler;
