import { App } from 'antd';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  FanguardApiContractsDevicesDeviceModel,
  FanguardApiContractsSensorSensorGroupModel,
  FanguardApiContractsSensorSensorModel,
} from 'src/api/generated';
import i18n from 'src/locales/i18n';
import { useAppNavigation } from 'src/routes';

import { get, patch, post, put, remove } from '@/api/api';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';

import { buildQueryParams } from './helpers';

const URI = 'sensors';

const useGetSensors = () => {
  const query = useQuery({
    queryKey: [URI, 'sensors'],
    queryFn: async () => {
      const response = await get<FanguardApiContractsSensorSensorModel[]>({ uri: `${URI}` });
      return response.data;
    },
  });

  return query;
};

const useGetSensorGroups = () => {
  const query = useQuery({
    queryKey: [URI, 'sensorgroups'],
    queryFn: async () => {
      const response = await get<FanguardApiContractsSensorSensorGroupModel[]>({ uri: `${URI}/groups` });
      return response.data;
    },
  });

  return query;
};

export const getSensorOptions = (id: string) => ({
  queryKey: [URI, id],
  queryFn: async () => {
    const response = await get<FanguardApiContractsDevicesDeviceModel>({ uri: `${URI}/${id}` });
    return response.data;
  },
  enabled: !!id,
});

const useGetSensor = (id: string): UseQueryResult<FanguardApiContractsDevicesDeviceModel, Error> => {
  return useQuery(getSensorOptions(id));
};

const useDeleteSensor = () => {
  const { message } = App.useApp();
  const { t } = useTranslation();
  const { navigateTo } = useAppNavigation();
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>({
    mutationFn: (deviceId: string) => remove({ uri: `${URI}/${deviceId}` }).then(() => undefined),
    onSuccess: () => {
      navigateTo.devices();
      queryClient.invalidateQueries({ queryKey: [URI] });
      message.success(`${t('general.deleted')} ${t('devices.device').toLowerCase()}`);
    },
    onError: () => {
      message.error(`${t('general.unableToDelete')} ${t('devices.device').toLowerCase()}`);
    },
  });
};

const useUpdateSensor = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  return useMutation<void, Error, FanguardApiContractsSensorSensorModel>({
    mutationFn: (data: FanguardApiContractsSensorSensorModel) => {
      return patch({ uri: `${URI}/${data.id}`, data }).then(() => undefined);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [URI] });
      message.success(`${t('general.updated')} ${t('devices.device').toLowerCase()}`);
    },
    onError: () => {
      message.error(`${t('general.unableToUpdate')} ${t('devices.device').toLowerCase()}`);
    },
  });
};
interface SensorMetrics {
  series: {
    0: {
      data: { x: string; y: number }[];
      key: string;
      name: string;
    };
  };
}

const useGetSensorMetrics = (sensorId: string, type: 'cpu' | 'memory' | 'fps') => {
  const params = buildQueryParams({ metric: type });
  return useQuery<SensorMetrics, Error>({
    queryKey: [URI, sensorId, 'metrics', type],
    queryFn: async () => {
      const response = await get<SensorMetrics>({ uri: `${URI}/${sensorId}/graph?${params}` });
      return response.data;
    },
    enabled: !!sensorId,
  });
};

const useSnapshotRefresh = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => post({ uri: `${URI}/${id}/snapshot-refresh` }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [URI] });
    },
  });
};

interface SensorZone {
  id: string;
  zone: number[][];
}

const useUpdateSensorZone = () => {
  const { message } = App.useApp();

  return useMutation({
    mutationFn: (data: SensorZone) => {
      const { id, zone } = data;
      return put({ uri: `${URI}/${id}/zone`, data: zone });
    },

    onSuccess: () => {
      message.success(`${t('general.updated')} ${t('devices.details.status.zone').toLowerCase()}`);
    },
    onError: () => {
      message.error(`${t('general.unableToUpdate')} ${t('devices.details.status.zone').toLowerCase()}`);
    },
  });
};

export enum SensorControl {
  Start = 'start',
  Stop = 'stop',
  Restart = 'restart',
}

interface SensorControlPayload {
  id: string;
  control: SensorControl;
}

const useControlSensor = () => {
  const { message } = App.useApp();
  return useMutation({
    mutationFn: (data: SensorControlPayload) => {
      const { id, control } = data;
      return get({ uri: `${URI}/${id}/control/${control}` });
    },
    onSuccess: () => {
      message.success(i18n.t('general.success'));
    },
    onError: () => {
      message.error(i18n.t('general.error'));
    },
  });
};

interface CalibrationSensorPayload {
  id: string;
}

const useCalibrationSensor = () => {
  const { message } = App.useApp();
  return useMutation({
    mutationFn: (data: CalibrationSensorPayload) => {
      const { id } = data;
      return post({ uri: `${URI}/${id}/calibration` });
    },
    onSuccess: () => {
      message.success(i18n.t('general.success'));
    },
    onError: () => {
      message.error(i18n.t('general.error'));
    },
  });
};

interface DiagnoseSensorPayload {
  id: string;
}

const useDiagnoseSensor = () => {
  const { message } = App.useApp();
  return useMutation({
    mutationFn: (data: DiagnoseSensorPayload) => {
      const { id } = data;
      return get({ uri: `${URI}/${id}/diagnose` });
    },
    onSuccess: () => {
      message.success(i18n.t('general.success'));
    },
    onError: () => {
      message.error(i18n.t('general.error'));
    },
  });
};

interface UploadDiagnoseData {
  id: string;
  startDate: string;
  endDate: string;
}

const useUploadDiagnoseData = () => {
  const { message } = App.useApp();
  return useMutation({
    mutationFn: (data: UploadDiagnoseData) => {
      const payload = {
        startDate: data.startDate,
        endDate: data.endDate,
      };
      return post({ uri: `${URI}/${data.id}/diagnostic-upload`, data: payload });
    },
    onSuccess: () => {
      message.success(i18n.t('general.success'));
    },
    onError: () => {
      message.error(i18n.t('general.error'));
    },
  });
};

export const getSensorLogsOptions = (id: string, lineCount?: number) => ({
  queryKey: [URI, id, lineCount],
  queryFn: async () => {
    const response = await get<SensorLogsResponse>({
      uri: `${URI}/${id}/log${lineCount ? `?lineCount=${lineCount}` : ''}`,
    });
    return response.data;
  },
  enabled: !!id,
  retry: 0,
});

interface SensorLogsResponse {
  logs: string;
}

const useGetSensorLogs = (id: string, lineCount?: number): UseQueryResult<SensorLogsResponse, Error> => {
  return useQuery(getSensorLogsOptions(id, lineCount));
};

export {
  useCalibrationSensor,
  useControlSensor,
  useDeleteSensor,
  useDiagnoseSensor,
  useGetSensor,
  useGetSensorGroups,
  useGetSensorLogs,
  useGetSensorMetrics,
  useGetSensors,
  useSnapshotRefresh,
  useUpdateSensor,
  useUpdateSensorZone,
  useUploadDiagnoseData,
};
