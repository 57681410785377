import { App } from 'antd';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  FanguardApiContractsControllerControllerModel,
  FanguardApiContractsControllerControllerPortModel,
  FanguardApiContractsControllerControllerPortSensorAssignmentRequestModel,
  FanguardApiContractsControllerControllerPortUpdateRequestModel,
  FanguardApiContractsDevicesDeviceModel,
} from 'src/api/generated';

import { get, patch, post } from '@/api/api';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';

const URI = 'controllers';

export const getControllerOptions = (id: string) => ({
  queryKey: [URI, id],
  queryFn: async () => {
    const response = await get<FanguardApiContractsDevicesDeviceModel>({ uri: `${URI}/${id}` });
    return response.data;
  },
});

const useGetController = (id: string): UseQueryResult<FanguardApiContractsDevicesDeviceModel, Error> => {
  return useQuery(getControllerOptions(id));
};

const getControllersOptions = () => ({
  queryKey: [URI],
  queryFn: async () => {
    const response = await get<FanguardApiContractsControllerControllerModel[]>({ uri: URI });
    return response.data;
  },
});

const useGetControllers = (): UseQueryResult<FanguardApiContractsControllerControllerModel[], Error> => {
  return useQuery(getControllersOptions());
};

interface AssignSensorToControllerProps
  extends FanguardApiContractsControllerControllerPortSensorAssignmentRequestModel {
  sensorId: string;
  controllerId: string;
  portNumber: number;
}

const useAssignSensorToController = () => {
  return useMutation<AxiosResponse<AssignSensorToControllerProps>, Error, AssignSensorToControllerProps>({
    mutationFn: async (data) =>
      post({
        uri: `${URI}/${data?.controllerId}/port/${data?.portNumber}/sensor`,
        data: { sensorId: data?.sensorId ?? '' },
      }),
  });
};

interface UpdateControllerProps extends FanguardApiContractsControllerControllerPortUpdateRequestModel {
  id: string;
}

const useUpdateController = () => {
  const { message } = App.useApp();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<void, Error, UpdateControllerProps>({
    mutationFn: async (data) => {
      await patch({ uri: `${URI}/${data.id}`, data });
      return;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [URI] });
      message.success(`${t('general.updated')} ${t('devices.device').toLowerCase()}`);
    },
    onError: () => {
      message.error(`${t('general.unableToUpdate')} ${t('devices.device').toLowerCase()}`);
    },
  });
};

interface TestControllerPortProps extends FanguardApiContractsControllerControllerPortModel {
  controllerId: string;
  credential: string;
  port: number;
}

const useTestControllerPort = () => {
  const { message } = App.useApp();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<TestControllerPortProps>, Error, TestControllerPortProps>({
    mutationFn: async (data) => post({ uri: `${URI}/${data?.controllerId}/test`, data }),
    onSuccess: () => {
      message.success(t('devices.details.ports.testSuccess'));
    },
    onError: () => {
      message.error(t('devices.details.ports.testError'));
    },
  });
};

interface ControllerControlProps {
  controllerId: string;
  control: string;
}

const useControllerControl = () => {
  return useMutation<AxiosResponse<ControllerControlProps>, Error, ControllerControlProps>({
    mutationFn: async (data) => {
      if (data?.controllerId && data?.control) {
        return get({ uri: `${URI}/${data.controllerId}/control/${data.control}` });
      }
      throw new Error('Missing required fields');
    },
  });
};

interface IssueCertificateProps {
  controllerId: string;
}

const useIssueCertificate = () => {
  return useMutation<AxiosResponse<IssueCertificateProps>, Error, IssueCertificateProps>({
    mutationFn: async (data) => post({ uri: `${URI}/${data?.controllerId}/certificate`, data }),
  });
};

interface ControllersContainerProps {
  controllerId: string;
}

const useControllerContainerUpdate = () => {
  return useMutation<AxiosResponse<ControllersContainerProps>, Error, ControllersContainerProps>({
    mutationFn: async (data) => post({ uri: `${URI}/${data?.controllerId}/container`, data }),
  });
};

interface ControllerLogsResponse {
  logs: string;
}

export const getControllerLogsOptions = (id: string, lineCount?: number) => ({
  queryKey: [URI, id, lineCount],
  queryFn: async () => {
    const response = await get<ControllerLogsResponse>({
      uri: `${URI}/${id}/log${lineCount ? `?lineCount=${lineCount}` : ''}`,
    });
    return response.data;
  },
  enabled: !!id,
  retry: 0,
});

const useGetControllerLogs = (id: string, lineCount?: number): UseQueryResult<ControllerLogsResponse, Error> => {
  return useQuery(getControllerLogsOptions(id, lineCount));
};

export {
  useAssignSensorToController,
  useControllerContainerUpdate,
  useControllerControl,
  useGetController,
  useGetControllerLogs,
  useGetControllers,
  useIssueCertificate,
  useTestControllerPort,
  useUpdateController,
};
