import { useSyncExternalStore } from 'react';

export const APP_PLATFORM_WEB = import.meta.env.VITE_APP_PLATFORM_WEB ?? 'web';
export const API_VERSION = import.meta.env.VITE_API_VERSION ?? '1.8';

interface Scope {
  client: string;
}

interface DecodedToken {
  scopes: string;
}

class ClientConfig {
  private client = '';
  private listeners: Set<() => void> = new Set();
  constructor() {
    this.checkInitialClient();
  }

  private checkInitialClient() {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      const decodedToken = JSON.parse(atob(token.split('.')[1])) as DecodedToken;
      const scopes = JSON.parse(decodedToken.scopes) as Scope[];

      if (scopes?.[0]?.client) {
        this.client = scopes[0].client;
      }
    } catch (error) {
      console.error('Error checking initial client', error);
    }
  }

  setClient(newClient: string) {
    this.client = newClient;
  }

  getClient() {
    return this.client;
  }

  subscribe(listener: () => void) {
    this.listeners.add(listener);
    return () => {
      this.listeners.delete(listener);
    };
  }

  notifyListeners() {
    this.listeners.forEach((listener) => listener());
  }
}

export const clientConfig = new ClientConfig();

export const useClient = () => {
  return useSyncExternalStore(
    (listener) => clientConfig.subscribe(listener),
    () => clientConfig.getClient(),
  );
};
