import { useMemo, useState } from 'react';

import { AutoComplete, Button, List, Popover, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { clientConfig, useClient } from 'src/api/axios/config';
import { FanguardApiContractsUserUserClientModel } from 'src/api/generated';
import { useGlobal } from 'src/contexts/GlobalContext';
import { useGetConfigAuth } from 'src/hooks/api/useConfigEndpoint';
import { usePostToken } from 'src/hooks/api/useTokenEndpoint';
import { useGetUser } from 'src/hooks/api/useUsersEndpoint';
import { capitalizeFirstLetters } from 'src/utils/formatters';

import { CloseOutlined, DownOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';

import { StyledAnchor } from '../AppHeader/AppHeader.styled';
import { FlexGap16AlignCenter } from '../CommonStyledComponents/CommonStyledComponents.styled';
import DrawerSelect from '../DrawerSelect';
import {
  AutocompleteWrapper,
  PopoverContent,
  StyledHeader,
  StyledListItem,
  TitleNoMargin,
} from './ClientSwitcher.styled';

const PATH = import.meta.env.VITE_HOST_PATH ?? 'access.wicketsoft.com';

const ClientSwitcher = () => {
  const { data, isPending: getUserIsPending, isError, refetch } = useGetUser();
  const { mutateAsync, isPending: postTokenIsPending } = usePostToken();
  const { data: configAuthData } = useGetConfigAuth();

  const isPending = getUserIsPending || postTokenIsPending;

  const { isDesktop } = useGlobal();
  const { t } = useTranslation();
  const client = useClient();

  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [switcherOpen, setSwitcherOpen] = useState(false);

  const onClientRefetch = () => refetch();

  const toggleSwitcher = () => setSwitcherOpen((prev) => !prev);

  const hostname = window.location.hostname;
  const protocol = location.protocol;
  const subdomain = hostname.split('.')[0];
  const label =
    configAuthData?.platform === 'Local'
      ? data?.clients?.find((c) => c.id === client)?.name
      : capitalizeFirstLetters(subdomain);

  const handleDomainChange = (client: FanguardApiContractsUserUserClientModel) => {
    const host = location.host.split('.').slice(1).join('.');
    const url = `${protocol}//${client.domainSlug}.${host}`;
    location.replace(url);
  };

  const handleLocalAuthDomainChange = (client: FanguardApiContractsUserUserClientModel) => {
    const url = `${protocol}//${client.domainSlug}.${PATH}`;
    location.replace(url);
  };

  const switchClientWithoutDomain = async (selectedClient: FanguardApiContractsUserUserClientModel) => {
    clientConfig.setClient(selectedClient?.id ?? 'demo');
    await mutateAsync(
      {
        client: selectedClient.id ?? 'demo',
        refreshToken: localStorage.getItem('refreshToken') ?? '',
      },
      {
        onSuccess: () => {
          clientConfig.notifyListeners();
        },
      },
    );
  };

  const handleChangeClient = async (selectedClient: FanguardApiContractsUserUserClientModel) => {
    if (location.hostname === 'localhost') {
      await switchClientWithoutDomain(selectedClient);
      toggleSwitcher();
      return;
    }

    if (configAuthData?.platform === 'Local') {
      if (selectedClient.domainSlug) {
        handleLocalAuthDomainChange(selectedClient);
        return;
      }
      switchClientWithoutDomain(selectedClient);
      toggleSwitcher();
    }

    if (configAuthData?.platform === 'Auth0') {
      if (selectedClient.domainSlug) {
        handleDomainChange(selectedClient);
        return;
      }
      location.replace(`${protocol}//${PATH}/login`);
    }
  };

  const onPopoverOpenChange = () => {
    if (isDesktop) {
      return toggleSwitcher();
    }
  };

  const sortedClients = useMemo(() => {
    return data?.clients?.sort((a, b) => {
      return (a.name ?? '').localeCompare(b.name ?? '');
    });
  }, [data?.clients]);

  const clientsFiltered = useMemo(() => {
    return sortedClients?.filter((client) => client?.name?.toLowerCase().includes(clientSearchTerm.toLowerCase()));
  }, [data, clientSearchTerm]);

  if (isError) {
    return (
      <FlexGap16AlignCenter>
        <Typography.Text type="danger">{t('client.errorFetchingClients')}</Typography.Text>
        <Button onClick={onClientRefetch} disabled={isPending}>
          {isPending ? t('general.refetching') : t('general.refetch')}
        </Button>
      </FlexGap16AlignCenter>
    );
  }

  return (
    <>
      {!isDesktop ? (
        <DrawerSelect
          title={t('client.client')}
          onOk={(selectedClientId) => {
            const selectedClient = data?.clients?.find((c) => c.id === selectedClientId);
            if (selectedClient) {
              handleChangeClient(selectedClient);
            }
          }}
          onOkText={t('client.selectClient')}
          options={
            sortedClients?.map((client) => ({
              value: client.id ?? '',
              label: client.name ?? '',
            })) ?? []
          }
          placeholder={t('client.selectClient')}
          value={client}
          triggerComponent={
            <StyledAnchor>
              <Space>
                {isPending ? t('general.loading') : label || t('client.selectClient')}
                <DownOutlined />
              </Space>
            </StyledAnchor>
          }
        />
      ) : (
        <Popover
          open={switcherOpen}
          placement="bottom"
          onOpenChange={onPopoverOpenChange}
          styles={{
            body: {
              padding: '0',
            },
          }}
          arrow={false}
          trigger="click"
          content={
            <PopoverContent vertical $isDesktop={isDesktop}>
              <StyledHeader justify="space-between" align="center">
                <TitleNoMargin level={4}>{t('client.client')}</TitleNoMargin>
                <Button type="text" icon={<CloseOutlined />} onClick={toggleSwitcher} />
              </StyledHeader>
              <AutocompleteWrapper>
                <AutoComplete
                  value={clientSearchTerm}
                  options={clientsFiltered}
                  onSearch={(value) => setClientSearchTerm(value)}
                  placeholder={t('interactions.search')}
                  suffixIcon={<SearchOutlined />}
                  open={false}
                />
              </AutocompleteWrapper>

              <List
                dataSource={clientsFiltered}
                renderItem={(item) => (
                  <StyledListItem key={item.id} onClick={() => handleChangeClient(item)}>
                    {item.name}
                    <Spin
                      indicator={<LoadingOutlined spin />}
                      spinning={isPending && data?.clients?.find((c) => c.id === client)?.id === item.id}
                    />
                  </StyledListItem>
                )}
              />
            </PopoverContent>
          }
        >
          <StyledAnchor>
            <Space data-testid="client-switcher">
              {isPending ? t('general.loading') : label || t('client.selectClient')}
              <DownOutlined />
            </Space>
          </StyledAnchor>
        </Popover>
      )}
    </>
  );
};

export default ClientSwitcher;
