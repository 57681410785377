import { AutoComplete, Flex, List, Menu, Tag, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FlexGap8 } from '../CommonStyledComponents/CommonStyledComponents.styled';

export const StyledArrowLeft = styled(ArrowLeftOutlined)`
  color: ${({ theme }) => theme.colorPrimary};
`;

interface TitleProps {
  $isDesktop?: boolean;
}

export const Title = styled(Typography.Title)<TitleProps>`
  margin: ${({ $isDesktop }) => ($isDesktop ? '0' : '12px 0')};
`;

interface PopoverContentStyledProps {
  $isDesktop: boolean;
}

export const PopoverContent = styled(Flex)<PopoverContentStyledProps>`
  width: ${({ $isDesktop }) => ($isDesktop ? '376px' : '100dvw')};
  height: ${({ $isDesktop }) => ($isDesktop ? 'auto' : 'calc(100dvh - 52px)')};
  .ant-list-items {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: ${({ $isDesktop }) => ($isDesktop ? '212px' : 'unset')};
    overflow: auto;
    padding: 8px 16px 16px 16px;
  }
`;

export const StyledHeader = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderSecondary};
  height: 60px;
  padding: 0 16px;
`;

export const TitleNoMargin = styled(Typography.Title).attrs({
  style: {
    marginBottom: 0,
  },
})``;

export const AutocompleteWrapper = styled(StyledHeader)`
  justify-content: center;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderSecondary};
  input {
    border-right: ${({ theme }) => `1px solid ${theme.colorBorder}`};
  }
  .ant-select-auto-complete {
    width: 100%;
  }
`;

export const StyledListItem = styled(List.Item).attrs({
  style: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 0,
    margin: 0,
    width: '100%',
    height: '32px',
    padding: '5px 0 5px 12px',
    border: 'none',
    cursor: 'pointer',
  },
})`
  &:hover {
    background-color: ${({ theme }) => theme.controlItemBgHover};
  }
`;

export const PrimaryTag = styled(Tag)`
  background-color: ${({ theme }) => theme.colorSuccessBg};
  border-color: ${({ theme }) => theme.colorSuccessBg};
  color: ${({ theme }) => theme.colorPrimary};
`;

export const StyledMenu = styled(Menu).attrs({
  multiple: true,
})`
  .ant-menu-title-content {
    display: block;
    width: 100%;
  }
  .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.colorSuccessBg};
  }
  .anticon-check {
    color: ${({ theme }) => theme.colorPrimary};
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const StyledMenuTitle = styled(Typography.Title).attrs({
  style: {
    marginBottom: 0,
  },
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledMenuItem = styled(Flex).attrs({
  style: {
    borderRadius: 0,
    margin: 0,
    width: '100%',
    height: '48px',
  },
})`
  justify-content: space-between;
  align-items: center;
`;

export const FooterWrapper = styled(FlexGap8)`
  padding: 8px 0;
`;

export const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`;
