import { useState } from 'react';
import { Avatar, Button, Typography } from 'antd';
import { useGlobal } from 'src/contexts/GlobalContext';

import { useGetUser } from '@/hooks/api/useUsersEndpoint';
import { UserOutlined } from '@ant-design/icons';

import SidebarMobile from '../Sidebar/SidebarMobile';
import { Header, StyledBellOutlined } from './AppHeader.styled';
import ClientSwitcher from '../ClientSwitcher/ClientSwitcher';
import { FlexGap16 } from '../CommonStyledComponents/CommonStyledComponents.styled';
import UserProfile from '../UserProfile/UserProfile';
import MobileUserProfile from '../UserProfile/MobileUserProfile';

const buildNumber = import.meta.env.VITE_BUILD_NUMBER;

export const AppHeader = () => {
  const { isDesktop } = useGlobal();
  const { data: userData, isSuccess } = useGetUser();

  const [isUserProfileModalVisible, setIsUserProfileModalVisible] = useState(false);

  const fullName = isSuccess ? `${userData?.firstName} ${userData?.lastName}` : '';

  const onBellHandler = () => {
    return;
  };

  const toggleUserProfileModal = () => setIsUserProfileModalVisible((prev) => !prev);

  return (
    <Header>
      {!isDesktop ? <SidebarMobile /> : null}
      <ClientSwitcher />
      <FlexGap16 align="center">
        {isDesktop ? (
          <Typography.Text>
            {buildNumber ? 'Version ' + process.env.npm_package_version + '.' + buildNumber : 'BUILD VERSION'}
          </Typography.Text>
        ) : null}
        <Button data-testid="bell-btn" type="text" onClick={onBellHandler} icon={<StyledBellOutlined />} />
        {isDesktop ? <Typography.Text>{fullName}</Typography.Text> : null}
        {isDesktop ? (
          <UserProfile visible={isUserProfileModalVisible} toggleUserProfile={toggleUserProfileModal} />
        ) : (
          <>
            <Button data-testid="profile-btn" shape="circle" onClick={toggleUserProfileModal}>
              <Avatar icon={<UserOutlined />} />
            </Button>
            <MobileUserProfile drawerVisible={isUserProfileModalVisible} toggleDrawer={toggleUserProfileModal} />
          </>
        )}
      </FlexGap16>
    </Header>
  );
};
