import '@/locales/i18n';
import '@ant-design/v5-patch-for-react-19';
import './fonts.css';

import React from 'react';

import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// import * as Sentry from '@sentry/react';
import Auth0Provider from './components/AuthWrapper/Auth0Provider';
import { router } from './router';
import { ApplicationThemeWrapper } from './ApplicationThemeWrapper';
import { ThemeProvider } from './contexts/ThemeContext';

export const queryClient = new QueryClient();

const initSentry = async () => {
  try {
    const Sentry = await import('@sentry/react');

    Sentry.init({
      dsn: 'https://520371275e2c7bb21bf67b160a607bc5@o188333.ingest.us.sentry.io/4507929729302528',
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // Tracing
      tracePropagationTargets: ['localhost', /^https:\/\/access.wicketsoft\.com\/api/],
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      //   // Set profilesSampleRate to 1.0 to profile every transaction.
      //   // Since profilesSampleRate is relative to tracesSampleRate,
      //   // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
      //   // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
      //   // results in 25% of transactions being profiled (0.5*0.5=0.25)
      profilesSampleRate: 1.0,
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  } catch (error) {
    console.warn('Failed to initialize Sentry:', error);
  }
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider>
      <ApplicationThemeWrapper>
        <QueryClientProvider client={queryClient}>
          <Auth0Provider>
            <RouterProvider
              router={router}
              // @ts-expect-error - future prop is not yet in the types i guess but is needed to not display warnings
              future={{
                v7_startTransition: true,
                v7_relativeSplatPath: true,
              }}
            />
          </Auth0Provider>
        </QueryClientProvider>
      </ApplicationThemeWrapper>
    </ThemeProvider>
  </React.StrictMode>,
);

initSentry();
