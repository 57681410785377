import ErrorBoundary from './components/ErrorBoundary';
import { useTheme } from './contexts/ThemeContext';
import { App as Antd, ConfigProvider } from 'antd';
import { ConfigProvider as MobileAntDesignConfigProvider } from 'antd-mobile';
import enUS from 'antd-mobile/es/locales/en-US';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { GlobalStyles } from './GlobalStyles';

export const ApplicationThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const { themeAlgorithm, customTheme, combinedTheme } = useTheme();

  return (
    <Antd>
      <ErrorBoundary>
        <ConfigProvider theme={{ algorithm: themeAlgorithm, ...customTheme }}>
          <StyledThemeProvider theme={combinedTheme}>
            <MobileAntDesignConfigProvider locale={enUS}>
              <GlobalStyles theme={combinedTheme} />
              {children}
            </MobileAntDesignConfigProvider>
          </StyledThemeProvider>
        </ConfigProvider>
      </ErrorBoundary>
    </Antd>
  );
};
