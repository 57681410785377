import { useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

export const routesMap = {
  landing: '/',
  users: '/users',
  settings: '/settings',
  reports: '/reports',
  ticketLogs: '/ticket-logs',
  paymentLogs: '/payment-logs',
  accessLogs: '/access-logs',
  lists: '/lists',
  listNew: '/lists/new',
  listDetails: '/lists/:id',
  listDetailsAddPeople: '/lists/:id/add-people',
  people: '/people',
  peopleDetails: '/people/:id',
  peopleDetailsEdit: '/people/:id/edit',
  peopleNew: '/people/new',
  venues: '/venues',
  devices: '/devices/sensors-controllers',
  devicesDetails: '/devices/sensors-controllers/:type/:id/:tab',
  devicesDetailsLogs: '/devices/sensors-controllers/:type/:id/logs',
  groups: '/devices/groups',
  events: '/events',
  account: '/account',
  preferences: '/preferences',
  kiosk: '/kiosk',
  connections: '/connections',
  sites: '/sites',
  emailTemplates: '/email-templates',
  ticketGroups: '/ticket-groups',
  noPermission: '/no-permission',
  errorPage: '/error/:status',
  login: '/login',
  assets: '/assets',
};

type RoutesMapKeys = keyof typeof routesMap;

export const useAppNavigation = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const navigateTo = useMemo(
    () => ({
      landing: () => navigate(routesMap.landing),
      users: () => navigate(routesMap.users),
      settings: () => navigate(routesMap.settings),
      reports: () => navigate(routesMap.reports),
      ticketLogs: () => navigate(routesMap.ticketLogs),
      paymentLogs: () => navigate(routesMap.paymentLogs),
      accessLogs: () => navigate(routesMap.accessLogs),
      lists: () => navigate(routesMap.lists),
      listDetails: (id?: string) => {
        if (id) {
          return navigate(routesMap.listDetails.replace(':id', id));
        }
        return navigate(routesMap.lists);
      },
      listDetailsAddPeople: (id?: string) => {
        if (id) {
          return navigate(routesMap.listDetailsAddPeople.replace(':id', id));
        }
        return navigate(routesMap.lists);
      },
      listNew: () => navigate(routesMap.listNew),
      people: () => navigate(routesMap.people),
      peopleDetails: (id?: string) => {
        if (id) {
          return navigate(routesMap.peopleDetails.replace(':id', id));
        }
        return navigate(routesMap.people);
      },
      peopleDetailsEdit: (id?: string) => {
        if (id) {
          return navigate(routesMap.peopleDetailsEdit.replace(':id', id));
        }
        return navigate(routesMap.people);
      },
      peopleNew: () => navigate(routesMap.peopleNew),
      venues: () => navigate(routesMap.venues),
      devices: () => navigate(routesMap.devices),
      sensorsControllers: () => navigate(routesMap.devices),
      groups: () => navigate(routesMap.groups),
      devicesDetails: (id?: string, type?: string, tab?: string) =>
        navigate(
          routesMap.devicesDetails
            .replace(':type', type || '')
            .replace(':id', id || '')
            .replace(':tab', tab || ''),
        ),
      devicesDetailsLogs: (id?: string, type?: string) =>
        navigate(routesMap.devicesDetailsLogs.replace(':type', type || '').replace(':id', id || '')),
      events: () => navigate(routesMap.events),
      account: () => navigate(routesMap.account),
      preferences: () => navigate(routesMap.preferences),
      kiosk: () => navigate(routesMap.kiosk),
      connections: () => navigate(routesMap.connections),
      sites: () => navigate(routesMap.sites),
      emailTemplates: () => navigate(routesMap.emailTemplates),
      ticketGroups: () => navigate(routesMap.ticketGroups),
      noPermission: () => navigate(routesMap.noPermission),
      errorPage: (status?: number, message?: string) =>
        navigate(routesMap.errorPage.replace(':status', status?.toString() || ''), {
          state: { status, message },
        }),
      assets: () => navigate(routesMap.assets),
      login: () => navigate(routesMap.login),
    }),
    [id],
  );

  return { navigateTo, routesMap };
};

export type { RoutesMapKeys };
