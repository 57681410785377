import { Typography } from 'antd';
import { Dialog } from 'antd-mobile';

import { ModalIcon, Title, Wrapper } from './MobileDialog.styled';

interface MobileDialogProps {
  onConfirm: () => void;
  confirmText: string;
  cancelText: string;
  title: string;
  description: string;
  iconColor: string;
  cancelActionColor: string;
  confirmActionColor: string;
}

export const MobileDialog = (props: MobileDialogProps) => {
  const { onConfirm, confirmText, cancelText, title, description, iconColor, cancelActionColor, confirmActionColor } =
    props;

  return Dialog.show({
    content: (
      <Wrapper>
        <ModalIcon $iconColor={iconColor} />
        <Title>{title}</Title>
        <Typography.Text>{description}</Typography.Text>
      </Wrapper>
    ),
    closeOnAction: true,
    actions: [
      [
        {
          key: 'cancel',
          text: cancelText,
          style: { color: cancelActionColor },
        },
        {
          key: 'confirm',
          text: confirmText,
          onClick: onConfirm,
          style: { color: confirmActionColor },
        },
      ],
    ],
  });
};
