import { Spin } from 'antd';
import styled from 'styled-components';

export const FullScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
`;

export const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.colorPrimary};
  }
`;
