/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** @format int32 */
export enum FanguardApiContractsAlertAlertStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

/** @format int32 */
export enum FanguardApiContractsAlertFilterType {
  List,
  Range,
  DateTime,
  Checkbox,
}

export type FanguardApiContractsAlertFilterTypeEnumKeys = keyof typeof FanguardApiContractsAlertFilterType;

/** @format int32 */
export enum FanguardApiContractsBoxBatteryStateModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/** @format int32 */
export enum FanguardApiContractsBoxDeviceDetailStatusEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
}

/** @format int32 */
export enum FanguardApiContractsBoxPairingStatus {
  Unavailable,
  TimedOut,
  Connecting,
  Connected,
  NotConnected,
}

export type FanguardApiContractsBoxPairingStatusKeys = keyof typeof FanguardApiContractsBoxPairingStatus;

export interface FanguardApiContractsBoxSensorBeatRequestModel {
  command?: string | null;
  sensorId?: string | null;
  status?: FanguardApiContractsBoxDeviceDetailStatusEnum;
  softwareVersion?: string | null;
  /** @format double */
  fps?: number;
  /** @format int32 */
  frameWidth?: number;
  /** @format int32 */
  frameHeight?: number;
  /** @format double */
  cpuUtilization?: number;
  /** @format double */
  memoryUtilization?: number;
  /** @format double */
  memoryTotal?: number | null;
  /** @format double */
  memoryUsed?: number | null;
  /** @format double */
  diskSpaceTotal?: number | null;
  /** @format double */
  diskSpaceUsed?: number | null;
  /** @format double */
  videoPreprocessingTime?: number | null;
  /** @format double */
  intercommunicationTime?: number | null;
  /** @format double */
  identificationTime?: number | null;
  /** @format double */
  totalTime?: number | null;
  /** @format double */
  classificationTime?: number | null;
  metrics?: FanguardApiContractsBoxSensorMetricRequestModel[] | null;
  watchlists?: FanguardApiContractsBoxSensorWatchlistRequestModel[] | null;
  configurationVersion?: string | null;
  ipAddress?: string | null;
  /** @format date-time */
  timestamp?: string;
  /** @format double */
  batteryLevel?: number | null;
  batteryState?: FanguardApiContractsBoxBatteryStateModelEnum;
  frNetworks?: string[] | null;
  pairingStatus?: FanguardApiContractsBoxPairingStatusKeys;
}

export interface FanguardApiContractsBoxSensorMetricRequestModel {
  name?: string | null;
  /** @format double */
  value?: number;
}

export interface FanguardApiContractsBoxSensorWatchlistRequestModel {
  watchlistId?: string | null;
  classifierId?: string | null;
  /** @format date-time */
  lastModifiedOn?: string | null;
}

export interface FanguardApiContractsBoxServiceKeyModel {
  id?: string | null;
  secretKey?: string | null;
}

export interface FanguardApiContractsClientAgreementModel {
  id?: string | null;
  key?: string | null;
  /** @format int32 */
  version?: number;
  body?: string | null;
}

export interface FanguardApiContractsClientAssetModel {
  id?: string | null;
  key?: string | null;
  name?: string | null;
  url?: string | null;
  /** @format int64 */
  size?: number;
  /** @format date-time */
  lastModified?: string;
  eTag?: string | null;
}

export interface FanguardApiContractsClientCampaignCreateRequestModel {
  /** @minLength 1 */
  name: string;
}

export interface FanguardApiContractsClientCampaignDetailModel {
  id?: string | null;
  name?: string | null;
  tagline?: string | null;
  pathSegment?: string | null;
  promoText?: string | null;
  program?: FanguardApiContractsClientCampaignProgram;
  programDetail?: FanguardApiContractsClientCampaignProgramDetail;
  linkAccountUrl?: string | null;
  /** @format int32 */
  requiredMinimumAge?: number | null;
  venueId?: string | null;
  listId?: string | null;
  enabled?: boolean | null;
  requireId?: boolean | null;
  enrollmentByRequestOnly?: boolean | null;
  requestEnrollmentTemplateId?: string | null;
  emailVerificationTemplateId?: string | null;
  registrationCompleteTemplateId?: string | null;
  registrationMissingPhotosTemplateId?: string | null;
  /** @format int32 */
  noPhotoEmailReminderDelay?: number;
  registrationIncompleteTemplateId?: string | null;
  /** @format int32 */
  incompleteCampaignReminderDelay?: number;
  content?: FanguardApiContractsClientContentModel;
  connectionId?: string | null;
  walletConnectionId?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsClientCampaignProgram {
  Value0 = 0,
  Value1 = 1,
  Value3 = 3,
  Value4 = 4,
}

/** @format int32 */
export enum FanguardApiContractsClientCampaignProgramDetail {
  Value0 = 0,
  Value1 = 1,
}

export interface FanguardApiContractsClientCampaignUpdateRequestModel {
  name?: string | null;
  tagline?: string | null;
  pathSegment?: string | null;
  promoText?: string | null;
  program?: FanguardApiContractsClientCampaignProgram;
  /** @format int32 */
  requiredMinimumAge?: number | null;
  listId?: string | null;
  venueId?: string | null;
  enabled?: boolean | null;
  requireId?: boolean | null;
  enrollmentByRequestOnly?: boolean | null;
  requestEnrollmentTemplateId?: string | null;
  emailVerificationTemplateId?: string | null;
  registrationCompleteTemplateId?: string | null;
  registrationMissingPhotosTemplateId?: string | null;
  /** @format int32 */
  noPhotoEmailReminderDelay?: number | null;
  registrationIncompleteTemplateId?: string | null;
  /** @format int32 */
  incompleteCampaignReminderDelay?: number | null;
  content?: FanguardApiContractsClientContentModel;
  connectionId?: string | null;
  walletConnectionId?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsClientClientAuthenticationPlatformEnum {
  Value0 = 0,
  Value1 = 1,
}

export interface FanguardApiContractsClientClientAuthenticationSettingsModel {
  platform?: FanguardApiContractsClientClientAuthenticationPlatformEnum;
  domain?: string | null;
  clientId?: string | null;
  managementClientId?: string | null;
  managementApiAudience?: string | null;
  clientSecret?: string | null;
  managementClientSecret?: string | null;
}

export interface FanguardApiContractsClientClientAuthenticationSettingsResponseModel {
  platform?: FanguardApiContractsClientClientAuthenticationPlatformEnum;
  domain?: string | null;
  clientId?: string | null;
  managementClientId?: string | null;
  managementApiAudience?: string | null;
}

export interface FanguardApiContractsClientClientCreateRequestModel {
  /**
   * @minLength 1
   * @pattern ^[a-zA-Z0-9-_!]*$
   */
  id: string;
  /** @minLength 1 */
  name: string;
  domainSlug?: string | null;
  licensedFor?: FanguardApiContractsClientClientModelLicenseTypeEnum[] | null;
}

export interface FanguardApiContractsClientClientExpirationSettingsModel {
  /** @format int32 */
  purgeInactivePersonsAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireMatchedTicketingAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireMatchedAccessControlAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireMatchedAgeVerificationAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireMatchedOrderFulfillmentAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireMatchedCredentialingAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireUnmatchedTicketingAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireUnmatchedAccessControlAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireUnmatchedAgeVerificationAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireUnmatchedOrderFulfillmentAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireUnmatchedCredentialingAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireAlertsAfterDays?: number | null;
  /**
   * @format int32
   * @min 120
   * @max 365
   */
  expireEventsAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireMatchedEnrollmentCheckAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireUnmatchedEnrollmentCheckAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireMatchedDayPassAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireUnmatchedDayPassAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireMatchedRemoteTicketingAfterDays?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 365
   */
  expireUnmatchedRemoteTicketingAfterDays?: number | null;
}

export interface FanguardApiContractsClientClientIntegrationSettingsModel {
  ticketing?: FanguardApiContractsClientTicketingIntegrationSettingsModel;
}

export interface FanguardApiContractsClientClientIntegrationSettingsResponseModel {
  ticketing?: FanguardApiContractsClientTicketingIntegrationSettingsModel;
}

/** @format int32 */
export enum FanguardApiContractsClientClientModelLicenseTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface FanguardApiContractsClientClientResponseDetailModel {
  id?: string | null;
  name?: string | null;
  domainSlug?: string | null;
  embedderName?: string | null;
  nextEmbedderName?: string | null;
  /** @format int32 */
  facilityCode?: number | null;
  /** @format double */
  alertThreshold?: number;
  /** @format int32 */
  expireAlertsAfterDays?: number | null;
  /** @format int32 */
  expireDecisionsAfterDays?: number | null;
  /** @format int32 */
  expireTicketingDataAfterDays?: number | null;
  saltBaseUri?: string | null;
  saltUsername?: string | null;
  onDemandQueueOverride?: string | null;
  /** @format int32 */
  purgeInactivePersonsAfterDays?: number | null;
  /** @format date-time */
  wipeOn?: string | null;
  suspendedBy?: string | null;
  status?: string | null;
  licensedFor?: FanguardApiContractsClientClientModelLicenseTypeEnum[] | null;
  /** @format int32 */
  wipeInDays?: number | null;
  kioskInstructions?: string | null;
  kioskClientImageUrl?: string | null;
  integrationSettings?: FanguardApiContractsClientClientIntegrationSettingsResponseModel;
  connections?: FanguardApiContractsClientIConnectionModel[] | null;
  sensorDefaults?: FanguardApiContractsClientClientSensorDefaultSettingsModel;
  expirationSettings?: FanguardApiContractsClientClientExpirationSettingsModel;
  authenticationSettings?: FanguardApiContractsClientClientAuthenticationSettingsResponseModel;
}

export interface FanguardApiContractsClientClientResponseSummaryModel {
  id?: string | null;
  name?: string | null;
  licensedFor?: FanguardApiContractsClientClientModelLicenseTypeEnum[] | null;
  /** @format date-time */
  wipeOn?: string | null;
  status?: string | null;
  /** @format int32 */
  wipeInDays?: number | null;
}

export interface FanguardApiContractsClientClientSensorDefaultSettingsModel {
  crowdCountThresholds?: number[] | null;
  kiosk?: FanguardApiContractsClientKioskConfigurationModel;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  negativeAlertThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  positiveAlertThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  negativeDiagnosticAlertThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  positiveDiagnosticAlertThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  initialBatchAcceptanceThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  initialBatchRejectionThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  additionalBatchAcceptanceThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  additionalBatchRejectionThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  detectionThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  blackhawkInitialBatchAcceptanceThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  blackhawkInitialBatchRejectionThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  blackhawkAdditionalBatchAcceptanceThreshold?: number | null;
  /**
   * @format double
   * @min 0
   * @max 1
   */
  blackhawkAdditionalBatchRejectionThreshold?: number | null;
  /**
   * @format int32
   * @min 5
   * @max 100
   */
  blackhawkLowBatteryThreshold?: number | null;
  enableZmqInterface?: boolean | null;
  enableTapToStart?: boolean | null;
}

export interface FanguardApiContractsClientClientUpdateRequestModel {
  name?: string | null;
  domainSlug?: string | null;
  /** @format double */
  alertThreshold?: number | null;
  /**
   * @format int32
   * @min 30
   * @max 365
   */
  expireAlertsAfterDays?: number | null;
  /**
   * @format int32
   * @min 30
   * @max 365
   */
  expireDecisionsAfterDays?: number | null;
  /**
   * @format int32
   * @min 30
   * @max 365
   */
  expireTicketingDataAfterDays?: number | null;
  /** @format int32 */
  purgeInactivePersonsAfterDays?: number | null;
  expirationSettings?: FanguardApiContractsClientClientExpirationSettingsModel;
  saltBaseUri?: string | null;
  saltUsername?: string | null;
  saltPassword?: string | null;
  onDemandQueueOverride?: string | null;
  unsuspend?: boolean | null;
  licensedFor?: FanguardApiContractsClientClientModelLicenseTypeEnum[] | null;
  sensorDefaults?: FanguardApiContractsClientClientSensorDefaultSettingsModel;
  integrationSettings?: FanguardApiContractsClientClientIntegrationSettingsModel;
  authenticationSettings?: FanguardApiContractsClientClientAuthenticationSettingsModel;
}

export interface FanguardApiContractsClientConnectionRequestModel {
  id?: string | null;
  name?: string | null;
  connectionType?: FanguardApiContractsClientConnectionTypeModelEnum;
  connectionTypeDetail?: FanguardApiContractsClientConnectionTypeDetailModelEnum;
  isDefaultForConnectionType?: boolean;
}

/** @format int32 */
export enum FanguardApiContractsClientConnectionTypeDetailModelEnum {
  TicketingTicketmasterIats,
  CredentialingAccredit,
  OrderFulfillmentLavaTapin2,
  OrderFulfillmentVenuetize,
  OrderFulfillmentLavaMashgin,
  OrderFulfillmentAiFi,
  AuthenticationSeatGeekOAuth,
  TicketingMock,
  TicketingProVenue,
  TicketingFortress,
  TicketingAxs,
  AuthenticationEmailVerification,
  AuthenticationTicketmasterOAuth,
  AuthenticationAxs,
  AgeVerificationIdMissionV2,
  CredentialingRainFocus,
  CredentialingMock,
  AccessControlCustomFormat,
  WalletGlobalPayments,
  AuthenticationProVenue,
  CredentialingCvent,
  AuthenticationCvent,
  CredentialingCustom,
  TicketingCustom,
}

export type FanguardApiContractsClientConnectionTypeDetailModelEnumKeys =
  keyof typeof FanguardApiContractsClientConnectionTypeDetailModelEnum;

/** @format int32 */
export enum FanguardApiContractsClientConnectionTypeModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface FanguardApiContractsClientContentModel {
  introduction?: FanguardApiContractsClientIntroductionModel;
  registration?: FanguardApiContractsClientRegistrationModel;
  photoUpload?: FanguardApiContractsClientPhotoUploadModel;
}

export interface FanguardApiContractsClientCreateEventRequestModel {
  id?: string | null;
  /** @minLength 1 */
  name: string;
  description?: string | null;
  /** @format date-time */
  startsOn: string;
  /** @format date-time */
  endsOn: string;
  imageId?: string | null;
  image?: string | null;
  source?: string | null;
  externalData?: Record<string, string>;
  tmEventId?: string | null;
  tmEventName?: string | null;
  /** @minLength 1 */
  venueId: string;
}

export interface FanguardApiContractsClientDeleteAssetRequestModel {
  id?: string | null;
}

export interface FanguardApiContractsClientDesignModel {
  introduction?: FanguardApiContractsClientSiteIntroductionModel;
  smallLogoImageId?: string | null;
  smallLogoImageUrl?: string | null;
  largeLogoImageId?: string | null;
  largeLogoImageUrl?: string | null;
  backgroundImageId?: string | null;
  backgroundImageUrl?: string | null;
  campaignPageDescriptionHtml?: string | null;
  campaignPageTitle?: string | null;
  styleSheet?: string | null;
  styleSheetId?: string | null;
  styleSheetUrl?: string | null;
  privacyPolicyHtml?: string | null;
  footerHtml?: string | null;
  useWicketFooter?: boolean | null;
}

export interface FanguardApiContractsClientEditEventRequestModel {
  id?: string | null;
  /** @minLength 1 */
  name: string;
  description?: string | null;
  /** @format date-time */
  startsOn: string;
  /** @format date-time */
  endsOn: string;
  imageId?: string | null;
  image?: string | null;
  source?: string | null;
  externalData?: Record<string, string>;
  tmEventId?: string | null;
  tmEventName?: string | null;
}

export interface FanguardApiContractsClientEmailTemplateCreateRequestModel {
  key?: FanguardApiContractsClientEmailTemplateModelKeyEnum;
  /** @minLength 1 */
  subject: string;
  /** @minLength 1 */
  fromEmailAddress: string;
  textTemplate?: string | null;
  htmlTemplate?: string | null;
  /** @format int32 */
  version?: number;
  active?: boolean;
}

export interface FanguardApiContractsClientEmailTemplateModel {
  id?: string | null;
  client?: string | null;
  key?: FanguardApiContractsClientEmailTemplateModelKeyEnum;
  subject?: string | null;
  fromEmailAddress?: string | null;
  textTemplate?: string | null;
  htmlTemplate?: string | null;
  /** @format int32 */
  version?: number;
  active?: boolean;
  /** @format int32 */
  reminderDelay?: number;
}

/** @format int32 */
export enum FanguardApiContractsClientEmailTemplateModelKeyEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
}

export interface FanguardApiContractsClientEmailTemplateUpdateRequestModel {
  /** @minLength 1 */
  subject: string;
  /** @minLength 1 */
  fromEmailAddress: string;
  textTemplate?: string | null;
  htmlTemplate?: string | null;
  /** @format int32 */
  version?: number;
  active?: boolean | null;
  /** @format int32 */
  reminderDelay?: number | null;
}

export interface FanguardApiContractsClientEventDetailModel {
  id?: string | null;
  /** @minLength 1 */
  name: string;
  code?: string | null;
  description?: string | null;
  source?: string | null;
  imageId?: string | null;
  imageUrl?: string | null;
  venue?: FanguardApiContractsClientVenueBaseModel;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  startsOn?: string | null;
  /** @format date-time */
  endsOn?: string | null;
  externalData?: Record<string, string>;
  active?: boolean;
  readOnly?: boolean;
  /** @format date-time */
  scanStartsOn?: string | null;
  /** @format date-time */
  scanEndsOn?: string | null;
  hasOverlap?: boolean;
  /** @format date-time */
  sortableStartsOn?: string | null;
  exterior?: FanguardApiContractsClientTicketingScanFacilityModel;
  interior?: FanguardApiContractsClientTicketingScanFacilityModel;
  tmEventName?: string | null;
}

export interface FanguardApiContractsClientIConnectionModel {
  id?: string | null;
  name?: string | null;
  connectionType?: FanguardApiContractsClientConnectionTypeModelEnum;
  connectionTypeDetail?: FanguardApiContractsClientConnectionTypeDetailModelEnumKeys;
  isDefaultForConnectionType?: boolean;
}

export interface FanguardApiContractsClientIntroductionModel {
  enabled?: boolean | null;
  marketingInfo?: string | null;
  /** @deprecated */
  tagline?: string | null;
}

export interface FanguardApiContractsClientKioskColorPaletteModel {
  /** @format int32 */
  backgroundImageOpacity?: number | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  numberColor?: string | null;
  standard?: FanguardApiContractsClientKioskScreenColorsModel;
  knownPersonAccepted?: FanguardApiContractsClientKioskScreenColorsModel;
  knownPersonDenied?: FanguardApiContractsClientKioskScreenColorsModel;
  unknownPerson?: FanguardApiContractsClientKioskScreenColorsModel;
}

export interface FanguardApiContractsClientKioskConfigurationModel {
  exitPassword?: string | null;
  viewfinderEnabled?: boolean | null;
  scanningMethod?: FanguardApiContractsClientScanningMethodModelEnum;
  enableZmqInterface?: boolean | null;
  displayPoweredByLogo?: boolean | null;
  design?: Record<string, FanguardApiContractsClientKioskDesignModel>;
  messages?: FanguardApiContractsClientKioskMessagesModel;
}

export interface FanguardApiContractsClientKioskDesignModel {
  images?: Record<string, FanguardApiContractsClientKioskImageModel>;
  colors?: FanguardApiContractsClientKioskColorPaletteModel;
}

export interface FanguardApiContractsClientKioskGeneralMessagesModel {
  personNameDisplayTemplate?: string | null;
  entryConfirmation?: string | null;
  entryDenied?: string | null;
}

export interface FanguardApiContractsClientKioskImageModel {
  id?: string | null;
  image?: string | null;
  name?: string | null;
  /** @format int64 */
  size?: number | null;
  /** @format int32 */
  displaySizePercentage?: number | null;
  url?: string | null;
  delete?: boolean;
}

export interface FanguardApiContractsClientKioskIntroductionMessagesModel {
  message?: string | null;
  title?: string | null;
}

export interface FanguardApiContractsClientKioskMessagesModel {
  general?: FanguardApiContractsClientKioskGeneralMessagesModel;
  introduction?: FanguardApiContractsClientKioskIntroductionMessagesModel;
}

export interface FanguardApiContractsClientKioskScreenColorsModel {
  backgroundColor?: string | null;
  backgroundColorOverlay?: string | null;
  /** @format double */
  backgroundColorOpacity?: number | null;
  textColor?: string | null;
  showBackgroundImage?: boolean | null;
}

export interface FanguardApiContractsClientPhotoUploadModel {
  instructions?: string | null;
  tip?: string | null;
  success?: string | null;
}

export interface FanguardApiContractsClientPointModel {
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface FanguardApiContractsClientRegistrationModel {
  confirmation?: string | null;
  callToActionButtonTitle?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsClientScanningMethodModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface FanguardApiContractsClientSendTestEmailRequestModel {
  emailAddresses?: string | null;
}

export interface FanguardApiContractsClientSensorPlacementRequestModel {
  sensorId?: string | null;
  /** @minLength 1 */
  placementName: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface FanguardApiContractsClientSiteCreateRequestModel {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  pathSegment: string;
}

export interface FanguardApiContractsClientSiteDetailModel {
  id?: string | null;
  name?: string | null;
  pathSegment?: string | null;
  logoImageUrl?: string | null;
  client?: string | null;
  design?: FanguardApiContractsClientDesignModel;
  campaigns?: FanguardApiContractsClientCampaignDetailModel[] | null;
  ageConfirmationEnabled?: boolean | null;
  authenticationConnection?: FanguardApiContractsClientIConnectionModel;
  connections?: FanguardApiContractsClientIConnectionModel[] | null;
  requirePersonNames?: boolean;
}

export interface FanguardApiContractsClientSiteIntroductionModel {
  enabled?: boolean | null;
  html?: string | null;
}

export interface FanguardApiContractsClientSiteSummaryModel {
  id?: string | null;
  name?: string | null;
  pathSegment?: string | null;
  logoImageUrl?: string | null;
  client?: string | null;
}

export interface FanguardApiContractsClientSiteUpdateRequestModel {
  name?: string | null;
  pathSegment?: string | null;
  design?: FanguardApiContractsClientDesignModel;
  authenticationConnectionId?: string | null;
  requirePersonNames?: boolean;
}

export interface FanguardApiContractsClientStartNetworkSwitchRequestModel {
  nextNetworkName?: string | null;
}

export interface FanguardApiContractsClientTestWebhookConnection {
  connectionId?: string | null;
  webhookTestType?: FanguardApiContractsClientWebhookTestType;
  webhookUrl?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsClientTicketingIntegrationPlatformModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface FanguardApiContractsClientTicketingIntegrationSettingsModel {
  ageConfirmationEnabled?: boolean | null;
}

export interface FanguardApiContractsClientTicketingScanFacilityModel {
  code?: string | null;
  exitScanEnabled?: boolean;
  /** @format int32 */
  validScansPerDay?: number;
  /** @format int32 */
  validScansPerEvent?: number;
}

export interface FanguardApiContractsClientUploadAssetRequestModel {
  name?: string | null;
  file?: string | null;
  contentType?: string | null;
}

export interface FanguardApiContractsClientVenueBaseModel {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  city?: string | null;
  state?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  stageCoordinates?: FanguardApiContractsClientPointModel[] | null;
  source?: string | null;
  timeZone?: string | null;
  credentialAreaCodePrefix?: string | null;
}

export interface FanguardApiContractsClientVenueDetailModel {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  city?: string | null;
  state?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  stageCoordinates?: FanguardApiContractsClientPointModel[] | null;
  source?: string | null;
  timeZone?: string | null;
  credentialAreaCodePrefix?: string | null;
  imageId?: string | null;
  imageUrl?: string | null;
  events?: FanguardApiContractsClientEventDetailModel[] | null;
  nextEvent?: FanguardApiContractsClientEventDetailModel;
  sensorPlacements?: FanguardApiContractsSensorSensorModel[] | null;
  externalData?: Record<string, string>;
  tmVenueId?: string | null;
  facilityCodes?: number[] | null;
}

export interface FanguardApiContractsClientVenueRequestModel {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  city?: string | null;
  state?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  stageCoordinates?: FanguardApiContractsClientPointModel[] | null;
  source?: string | null;
  timeZone?: string | null;
  credentialAreaCodePrefix?: string | null;
  imageId?: string | null;
  image?: string | null;
  externalData?: Record<string, string>;
  tmVenueId?: string | null;
  facilityCodes?: number[] | null;
}

export interface FanguardApiContractsClientVenueSummaryModel {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  city?: string | null;
  state?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  stageCoordinates?: FanguardApiContractsClientPointModel[] | null;
  source?: string | null;
  timeZone?: string | null;
  credentialAreaCodePrefix?: string | null;
  imageId?: string | null;
  imageUrl?: string | null;
  events?: FanguardApiContractsClientEventDetailModel[] | null;
  nextEvent?: FanguardApiContractsClientEventDetailModel;
}

/** @format int32 */
export enum FanguardApiContractsClientWebhookTestType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface FanguardApiContractsConfigModel {
  currentVenue?: FanguardApiContractsClientVenueSummaryModel;
  defaultVenue?: FanguardApiContractsClientVenueSummaryModel;
  unsignedAgreements?: FanguardApiContractsClientAgreementModel[] | null;
  supportedMobileVersion?: string | null;
  clientLicenses?: FanguardApiContractsClientClientModelLicenseTypeEnum[] | null;
  ticketingPlatform?: FanguardApiContractsClientTicketingIntegrationPlatformModelEnum;
}

export interface FanguardApiContractsControllerControllerBeatModel {
  status?: FanguardApiContractsBoxDeviceDetailStatusEnum;
  softwareVersion?: string | null;
  metrics?: Record<string, number | null>;
  /** @format date-time */
  timestamp?: string;
  /** @format date-time */
  boxTimestamp?: string;
}

/** @format int32 */
export enum FanguardApiContractsControllerControllerCardFormatModelEnum {
  Std26bit = 0,
  H10304,
  Custom = 100,
}

export type FanguardApiContractsControllerControllerCardFormatModelEnumKeys =
  keyof typeof FanguardApiContractsControllerControllerCardFormatModelEnum;

export interface FanguardApiContractsControllerControllerModel {
  id?: string | null;
  name?: string | null;
  venueId?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  status?: string | null;
  latestBeat?: FanguardApiContractsControllerControllerBeatModel;
  minionName?: string | null;
  ipAddress?: string | null;
  ports?: FanguardApiContractsControllerControllerPortModel[] | null;
  sensorGroupId?: string | null;
  watchlists?: FanguardApiContractsSensorSensorWatchlistModel[] | null;
  snapshotUpdatedOn?: string | null;
  areSnapshotsEnabled?: boolean | null;
  snapshotUrl?: string | null;
}

export interface FanguardApiContractsControllerControllerPortModel {
  /** @format int32 */
  portNumber?: number;
  cardFormat?: FanguardApiContractsControllerControllerCardFormatModelEnumKeys;
  customFormatConnectionId?: string | null;
  protocol?: FanguardApiContractsControllerPanelInterfaceProtocolModelEnumKeys;
  sensors?: string[] | null;
}

export interface FanguardApiContractsControllerControllerPortSensorAssignmentRequestModel {
  sensorId?: string | null;
}

export interface FanguardApiContractsControllerControllerPortTestRequestModel {
  /** @format int32 */
  port?: number;
  cardFormat?: FanguardApiContractsControllerControllerCardFormatModelEnumKeys;
  customFormatConnectionId?: string | null;
  protocol?: FanguardApiContractsControllerPanelInterfaceProtocolModelEnumKeys;
  credential?: string | null;
}

export interface FanguardApiContractsControllerControllerPortUpdateRequestModel {
  /** @format int32 */
  portNumber?: number;
  cardFormat?: FanguardApiContractsControllerControllerCardFormatModelEnumKeys;
  customFormatConnectionId?: string | null;
  protocol?: FanguardApiContractsControllerPanelInterfaceProtocolModelEnumKeys;
}

export interface FanguardApiContractsControllerControllerUpdateRequestModel {
  name?: string | null;
  minionName?: string | null;
  venueId?: string | null;
  ipAddress?: string | null;
  ports?: FanguardApiContractsControllerControllerPortUpdateRequestModel[] | null;
}

/** @format int32 */
export enum FanguardApiContractsControllerPanelInterfaceProtocolModelEnum {
  Wiegand,
}

export type FanguardApiContractsControllerPanelInterfaceProtocolModelEnumKeys =
  keyof typeof FanguardApiContractsControllerPanelInterfaceProtocolModelEnum;

/** @format int32 */
export enum FanguardApiContractsDecisionDecisionStatusEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface FanguardApiContractsDecisionDecisionSummaryModel {
  id?: string | null;
  granted?: boolean;
  grantStatus?: FanguardApiContractsDecisionInteractionGrantStatus;
  overrideReason?: string | null;
  overrideReasonDetail?: string | null;
  /** @format double */
  spoofProbability?: number | null;
  spoofAlgorithmName?: string | null;
  spoofAlgorithmVersion?: string | null;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string | null;
  client?: string | null;
  status?: FanguardApiContractsDecisionDecisionStatusEnum;
  sensorId?: string | null;
  sensorName?: string | null;
  sensorPlacementName?: string | null;
  sensorMode?: string | null;
  eventId?: string | null;
  eventName?: string | null;
  venueId?: string | null;
  venueName?: string | null;
  source?: string | null;
  affirmed?: boolean;
  detectedImageId?: string | null;
  contextImageId?: string | null;
  /** @format date-time */
  detectedOn?: string;
  /** @format date-time */
  matchedOn?: string | null;
  personId?: string | null;
  personFirstName?: string | null;
  personLastName?: string | null;
  personPhotoId?: string | null;
  watchlistId?: string | null;
  watchlistName?: string | null;
  /** @format float */
  matchConfidenceScore?: number;
  /** @format double */
  similarity?: number;
  similarityText?: string | null;
  trackedFaceId?: string | null;
  detectedImageUrl?: string | null;
  contextImageUrl?: string | null;
  personPhotoImageUrl?: string | null;
  primaryDetectionId?: string | null;
}

export interface FanguardApiContractsDecisionDetectionProcessorResultModel {
  id?: string | null;
  granted?: boolean;
  overrideReason?: string | null;
  overrideReasonDetail?: string | null;
  displayMessage?: string | null;
  status?: FanguardApiContractsDecisionDecisionStatusEnum;
  /** @format date-time */
  createdOn?: string;
}

/** @format int32 */
export type FanguardApiContractsDecisionInteractionGrantStatus = 'Denied' | 'Partial' | 'Granted' | 'Pending';

export interface FanguardApiContractsDecisionTicketDecisionSummaryModel {
  id?: string | null;
  granted?: boolean;
  grantStatus?: FanguardApiContractsDecisionInteractionGrantStatus;
  overrideReason?: string | null;
  overrideReasonDetail?: string | null;
  /** @format double */
  spoofProbability?: number | null;
  spoofAlgorithmName?: string | null;
  spoofAlgorithmVersion?: string | null;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string | null;
  client?: string | null;
  status?: FanguardApiContractsDecisionDecisionStatusEnum;
  sensorId?: string | null;
  sensorName?: string | null;
  sensorPlacementName?: string | null;
  sensorMode?: string | null;
  eventId?: string | null;
  eventName?: string | null;
  venueId?: string | null;
  venueName?: string | null;
  source?: string | null;
  affirmed?: boolean;
  detectedImageId?: string | null;
  contextImageId?: string | null;
  /** @format date-time */
  detectedOn?: string;
  /** @format date-time */
  matchedOn?: string | null;
  personId?: string | null;
  personFirstName?: string | null;
  personLastName?: string | null;
  personPhotoId?: string | null;
  watchlistId?: string | null;
  watchlistName?: string | null;
  /** @format float */
  matchConfidenceScore?: number;
  /** @format double */
  similarity?: number;
  similarityText?: string | null;
  trackedFaceId?: string | null;
  detectedImageUrl?: string | null;
  contextImageUrl?: string | null;
  personPhotoImageUrl?: string | null;
  primaryDetectionId?: string | null;
  ticketScans?: FanguardApiContractsTicketTicketScanSummary[] | null;
}

/** @format int32 */
export enum FanguardApiContractsDetectionDetectionResultEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
}

/** @format int32 */
export enum FanguardApiContractsDetectionDetectionTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
}

export interface FanguardApiContractsDetectionRemoteDetectionCreateRequest {
  /** @minLength 1 */
  location: string;
  /** @format int32 */
  minimumAge?: number | null;
  paymentExpected?: boolean;
}

export interface FanguardApiContractsDetectionRemoteDetectionResponse {
  interactionId?: string | null;
  ageVerified?: boolean | null;
  paymentAccountId?: string | null;
  granted?: boolean;
  resultDetail?: string | null;
}

export interface FanguardApiContractsDetectionUnifiedDetectionCreateRequest {
  detectionType?: FanguardApiContractsDetectionDetectionTypeEnum;
  /** @pattern ^[0-9a-fA-F]{24}$ */
  sensorId?: string | null;
  /** @pattern ^[0-9a-fA-F]{24}$ */
  personId?: string | null;
  /** @pattern ^[0-9a-fA-F]{24}$ */
  personPhotoId?: string | null;
  /** @pattern ^[0-9a-fA-F]{24}$ */
  watchlistId?: string | null;
  /** @format float */
  businessConfidence?: number | null;
  /** @format float */
  confidence?: number | null;
  /** @format float */
  similarity?: number;
  /** @format float */
  spoofProbability?: number | null;
  spoofAlgorithmName?: string | null;
  spoofAlgorithmVersion?: string | null;
  result?: FanguardApiContractsDetectionDetectionResultEnum;
  contextImageData?: string | null;
  detectedImageData?: string | null;
  /** @format date-time */
  detectedOn?: string;
  /** @format date-time */
  matchedOn?: string;
  /** @format date-time */
  sentOn?: string;
  /** @format date-time */
  trackingStartedOn?: string;
  /** @format date-time */
  identificationStartedOn?: string;
  /** @format date-time */
  classificationStartedOn?: string;
  isDiagnostic?: boolean;
  /** @format int32 */
  eyeDistance?: number | null;
  faceTrackId?: string | null;
  isContextImageBlurred?: boolean | null;
  ticketProviderAccessToken?: string | null;
  ageVerificationRequested?: boolean;
}

export interface FanguardApiContractsDevicesDeviceModel {
  id?: string | null;
  name?: string | null;
  pairedSensorName?: string | null;
  pairingStatus?: FanguardApiContractsBoxPairingStatusKeys;
  pairingErrorMessage?: string | null;
  venueId?: string | null;
  deviceType?: FanguardApiContractsDevicesDeviceType;
  status?: FanguardApiContractsSensorDeviceStatusEnumKeys;
  isOnline?: boolean;
  snapshotUrl?: string | null;
  placementName?: string | null;
  /** @format int32 */
  batteryLevel?: number | null;
  accessControlMode?: FanguardApiContractsSensorSensorAccessControlModeModelKeys;
  batteryState?: FanguardApiContractsBoxBatteryStateModelEnum;
  deviceDetailStatus?: FanguardApiContractsBoxDeviceDetailStatusEnum;
  softwareVersion?: string | null;
  listStatus?: FanguardApiContractsDevicesListStatus;
  watchlists?: FanguardApiContractsDevicesDeviceWatchlistModel[] | null;
  sensorGroupName?: string | null;
  platform?: FanguardApiContractsSensorSensorModelPlatformEnum;
}

/** @format int32 */
export enum FanguardApiContractsDevicesDeviceType {
  Value0 = 0,
  Value1 = 1,
}

export interface FanguardApiContractsDevicesDeviceWatchlistModel {
  watchlistId?: string | null;
  watchlistName?: string | null;
  status?: FanguardApiContractsDevicesDeviceWatchlistStatusEnum;
}

/** @format int32 */
export enum FanguardApiContractsDevicesDeviceWatchlistStatusEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** @format int32 */
export enum FanguardApiContractsDevicesListStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface FanguardApiContractsErrorErrorModel {
  message?: string | null;
  exception?: FanguardApiContractsErrorErrorModelException;
}

export interface FanguardApiContractsErrorErrorModelException {
  type?: string | null;
  data?: Record<string, any>;
  source?: string | null;
  innerException?: FanguardApiContractsErrorErrorModelException;
  message?: string | null;
  stackTrace?: string | null;
}

export interface FanguardApiContractsFrNetworkFrNetworkModel {
  id?: string | null;
  name?: string | null;
}

export interface FanguardApiContractsHostHostModel {
  id?: string | null;
  status?: FanguardApiContractsHostHostStatusModelEnum;
  product?: FanguardApiContractsHostHostProductModelEnum;
}

/** @format int32 */
export enum FanguardApiContractsHostHostProductModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface FanguardApiContractsHostHostProvisionRequestModel {
  hostId?: string | null;
  client?: string | null;
  sensorName?: string | null;
  product?: FanguardApiContractsHostProductTypeEnum;
  venueId?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsHostHostStatusModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/** @format int32 */
export enum FanguardApiContractsHostProductTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface FanguardApiContractsIdVerificationStartIdVerificationProcessResponseModel {
  submissionToken?: string | null;
  requireBarcodeVerificationForLicences?: boolean;
}

export interface FanguardApiContractsIdVerificationStartIdVerificationRequestModel {
  /** @minLength 1 */
  siteId: string;
  /** @minLength 1 */
  campaignId: string;
}

/** @format int32 */
export enum FanguardApiContractsInteractionClaimStatus {
  Value0 = 0,
  Value1 = 1,
}

/** @format int32 */
export enum FanguardApiContractsInteractionInteractionAlertTypeModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** @format int32 */
export enum FanguardApiContractsInteractionInteractionBlockedReasonModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** @format int32 */
export type FanguardApiContractsInteractionInteractionDecisionOperationModel =
  | 'CredentialingScan'
  | 'CredentialingCheckIn';

/** @format int32 */
export enum FanguardApiContractsInteractionInteractionDecisionOverrideReasonModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
}

export interface FanguardApiContractsInteractionInteractionFilterOptionModel {
  default: boolean;
  id: string;
  name: string;
}
export interface FanguardApiContractsInteractionInteractionFilterModel {
  default: boolean;
  displayName?: string | null;
  parameterName?: string | null;
  filterType?: FanguardApiContractsAlertFilterTypeEnumKeys;
  shouldPauseLiveUpdating?: boolean;
  options: FanguardApiContractsInteractionInteractionFilterOptionModel[];
}

/** @format int32 */
export enum FanguardApiContractsInteractionInteractionGrantStatusModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface FanguardApiContractsInteractionInteractionListModel {
  filters?: FanguardApiContractsInteractionInteractionFilterModel[] | null;
  /** @format int32 */
  count?: number | null;
  interactions?: FanguardApiContractsInteractionInteractionResponseModel[] | null;
}

export interface FanguardApiContractsInteractionInteractionNoteModel {
  id?: string | null;
  text?: string | null;
  createdByName?: string | null;
  createdBy?: string | null;
  /** @format date-time */
  createdOn?: string | null;
}

export interface FanguardApiContractsInteractionInteractionNoteRequestModel {
  id?: string | null;
  type: FanguardApiContractsInteractionInteractionTypeEnum;
  /** @minLength 1 */
  text: string;
}

export interface FanguardApiContractsInteractionInteractionOrderItemResponseModel {
  id?: string | null;
  description?: string | null;
  /** @format int32 */
  quantity?: number;
  /** @format float */
  unitPrice?: number;
  /** @format float */
  totalPrice?: number;
}

export interface FanguardApiContractsInteractionInteractionOrderResponseModel {
  orderNumber?: string | null;
  /** @format date-time */
  orderDate?: string;
  items?: FanguardApiContractsInteractionInteractionOrderItemResponseModel[] | null;
  claimStatus?: FanguardApiContractsInteractionClaimStatus;
  /** @format float */
  totalTax?: number | null;
  /** @format float */
  totalDiscount?: number | null;
  /** @format float */
  subtotalPrice?: number;
  /** @format float */
  totalPrice?: number;
}

export interface FanguardApiContractsInteractionInteractionProviderResponseModel {
  code?: string | null;
  text?: string | null;
  error?: string | null;
}

export enum FanguardApiContractsInteractionInteractionTypeModelEnum {
  Alert,
  InteractionDecisionAccessControl,
  InteractionDecisionTicketing,
  All,
  Credentialing,
  InteractionDecisionOrderFulfillment,
  AgeVerification,
  EnrollmentCheck,
  DayPass,
  RemoteTicketing,
}

export type FanguardApiContractsInteractionInteractionTypeModelEnumKeys =
  keyof typeof FanguardApiContractsInteractionInteractionTypeModelEnum;

export interface FanguardApiContractsInteractionInteractionResponseModel {
  id?: string | null;
  type?: FanguardApiContractsInteractionInteractionTypeModelEnumKeys | null;
  alertType?: FanguardApiContractsInteractionInteractionAlertTypeModelEnum;
  isAffirmed?: boolean;
  personId?: string | null;
  personName?: string | null;
  sensorId?: string | null;
  sensorName?: string | null;
  sensorContextImageEnabled?: boolean;
  venueId?: string | null;
  watchlistId?: string | null;
  watchlistName?: string | null;
  /** @format double */
  businessConfidence?: number | null;
  /** @format date-time */
  detectedOn?: string;
  /** @format date-time */
  createdOn?: string;
  blockedReason?: FanguardApiContractsInteractionInteractionBlockedReasonModelEnum;
  validationStatus?: FanguardApiContractsInteractionInteractionValidationStatusModelEnumKeys;
  overrideReason?: FanguardApiContractsInteractionInteractionDecisionOverrideReasonModelEnum;
  grantStatus?: FanguardApiContractsDecisionInteractionGrantStatus;
  workflowStage?: FanguardApiContractsInteractionInteractionWorkflowStageModelEnum;
  overrideReasonDetail?: string | null;
  notes?: FanguardApiContractsInteractionInteractionNoteModel[] | null;
  detectedImageUrl?: string | null;
  contextImageUrl?: string | null;
  personPhotoImageUrl?: string | null;
  /** @format double */
  confidence?: number | null;
  /** @format double */
  similarity?: number | null;
  /** @format double */
  spoofProbability?: number | null;
  spoofAlgorithmName?: string | null;
  spoofAlgorithmVersion?: string | null;
  faceTrackId?: string | null;
  /** @format int32 */
  eyeDistance?: number | null;
  createdBy?: string | null;
  source?: string | null;
  ageVerificationRequested?: boolean;
  orders?: FanguardApiContractsInteractionInteractionOrderResponseModel[] | null;
  tickets?: FanguardApiContractsInteractionInteractionTicketResponseModel[] | null;
  ticketScans?: FanguardApiContractsInteractionInteractionTicketScanResponseModel[] | null;
  hasContextImage?: boolean;
  displayedMessage?: string | null;
  providerResponse?: FanguardApiContractsInteractionInteractionProviderResponseModel;
  operation?: FanguardApiContractsInteractionInteractionDecisionOperationModel;
}

export interface FanguardApiContractsInteractionInteractionSharedUrlResponseModel {
  url?: string | null;
}

export interface FanguardApiContractsInteractionInteractionTicketResponseModel {
  /** @format date-time */
  createdOn?: string | null;
  /** @format date-time */
  modifiedOn?: string | null;
  personReference?: string | null;
  eventReference?: string | null;
  priceReference?: string | null;
  ticketReference?: string | null;
  deliveryReference?: string | null;
  seat?: string | null;
  row?: string | null;
  section?: string | null;
  statusDetail?: FanguardApiContractsInteractionTicketStatusDetailModelEnum;
  scans?: FanguardApiContractsInteractionInteractionTicketScanResponseModel[] | null;
}

export interface FanguardApiContractsInteractionInteractionTicketScanResponseModel {
  redemptionCorrelation?: string | null;
  /** @format date-time */
  createdOn?: string | null;
  accepted?: boolean;
  ticketReference?: string | null;
  scanType?: FanguardApiContractsInteractionTicketScanTypeModelEnum;
  resultDetail?: FanguardApiContractsInteractionTicketRedemptionResultDetailModelEnum;
  resultDetailRaw?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsInteractionInteractionTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
}

export enum FanguardApiContractsInteractionInteractionValidationStatusModelEnum {
  Unknown,
  Incorrect,
  Correct,
}

export type FanguardApiContractsInteractionInteractionValidationStatusModelEnumKeys =
  keyof typeof FanguardApiContractsInteractionInteractionValidationStatusModelEnum;

export interface FanguardApiContractsInteractionInteractionValidationStatusUpdateModel {
  type?: FanguardApiContractsInteractionInteractionTypeEnum;
  validationStatus?: FanguardApiContractsInteractionInteractionValidationStatusModel;
}

/** @format int32 */
export enum FanguardApiContractsInteractionInteractionWorkflowStageModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface FanguardApiContractsInteractionInteractionWorkflowStageUpdateModel {
  workflowStage?: FanguardApiContractsInteractionInteractionWorkflowStageModelEnum;
}

/** @format int32 */
export enum FanguardApiContractsInteractionTicketRedemptionResultDetailModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
}

/** @format int32 */
export enum FanguardApiContractsInteractionTicketScanTypeModelEnum {
  Value0 = 0,
  Value1 = 1,
}

/** @format int32 */
export enum FanguardApiContractsInteractionTicketStatusDetailModelEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface FanguardApiContractsJobJobDetailMessage {
  message?: string | null;
  level?: string | null;
  /** @format date-time */
  createdOn?: string;
}

export interface FanguardApiContractsJobJobDetailModel {
  /** @format int32 */
  totalItems?: number;
  /** @format int32 */
  successItems?: number;
  /** @format int32 */
  failedItems?: number;
  complete?: boolean;
  messages?: FanguardApiContractsJobJobDetailMessage[] | null;
}

export interface FanguardApiContractsOrderClaimOrderRequestModel {
  interactionId?: string | null;
  /** @format int32 */
  operatorId?: number;
  itemIds?: number[] | null;
}

export interface FanguardApiContractsOrderLineItemRequestModel {
  itemId?: string | null;
  externalId?: string | null;
  barcode?: string | null;
  description?: string | null;
  /** @format int32 */
  quantity?: number;
  /** @format float */
  unitPrice?: number;
  /** @format float */
  unitDiscount?: number | null;
  /** @format float */
  tax?: number | null;
  /** @format float */
  subtotalPrice?: number;
  /** @format float */
  totalPrice?: number;
}

export interface FanguardApiContractsOrderOperatorValidationResultModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface FanguardApiContractsOrderOrderCompleteRequestModel {
  interactionId?: string | null;
  orderNumber?: string | null;
  status?: string | null;
  /** @format float */
  totalTax?: number | null;
  /** @format float */
  totalFee?: number | null;
  /** @format float */
  totalDiscount?: number | null;
  /** @format float */
  subtotalPrice?: number;
  /** @format float */
  totalPrice?: number;
  lineItems?: FanguardApiContractsOrderLineItemRequestModel[] | null;
}

export interface FanguardApiContractsPagedItemsModel1FanguardApiContractsPersonPersonDetailModel {
  items?: FanguardApiContractsPersonPersonDetailModel[] | null;
  /** @format int64 */
  totalItems?: number;
}

export interface FanguardApiContractsPagedItemsModel1FanguardApiContractsPersonPersonSummaryModel {
  items?: FanguardApiContractsPersonPersonSummaryModel[] | null;
  /** @format int64 */
  totalItems?: number;
}

export interface FanguardApiContractsPermissionGroupModel {
  id?: string | null;
  name?: string | null;
  permissions?: string[] | null;
  statuses?: FanguardApiContractsAlertAlertStatus[] | null;
  watchlists?: string[] | null;
}

export interface FanguardApiContractsPermissionPermissionModel {
  id?: string | null;
  description?: string | null;
}

export interface FanguardApiContractsPersonAddPersonNoteRequestModel {
  personId?: string | null;
  note?: string | null;
  createdBy?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsPersonAddPhotoWorkflowEnum {
  Value0 = 0, // addPhoto ?
  Value1 = 1, // ?
}

export interface FanguardApiContractsPersonAiFiCart {
  id?: string | null;
  name?: string | null;
  /** @format double */
  price?: number | null;
  totalTax?: string | null;
  subtotalPrice?: string | null;
  totalPrice?: string | null;
  barcode?: string | null;
  thumbnail?: string | null;
  externalId?: string | null;
  /** @format int32 */
  quantity?: number | null;
}

export interface FanguardApiContractsPersonAiFiCheckoutRequest {
  /** @format int32 */
  orderId?: number | null;
  sessionId?: string | null;
  status?: string | null;
  cart?: FanguardApiContractsPersonAiFiCart[] | null;
  totalPrice?: string | null;
  totalTax?: string | null;
  subtotalPrice?: string | null;
}

export interface FanguardApiContractsPersonCreateGuestRequest {
  interactionId?: string | null;
  photo?: string | null;
  token?: string | null;
}

export interface FanguardApiContractsPersonEmailVerificationRequest {
  siteId?: string | null;
  ageConfirmed?: boolean;
  redirectUri?: string | null;
}

export interface FanguardApiContractsPersonEnrolledCampaignModel {
  siteId?: string | null;
  campaignId?: string | null;
  status?: FanguardApiContractsPersonEnrollmentStatusEnum;
  /** @format date-time */
  requestedOn?: string | null;
  /** @format date-time */
  startedOn?: string | null;
  /** @format date-time */
  completedOn?: string | null;
}

export interface FanguardApiContractsPersonEnrolledCampaignSummaryModel {
  siteId?: string | null;
  campaignId?: string | null;
  status?: FanguardApiContractsPersonEnrollmentStatusEnum;
  /** @format date-time */
  requestedOn?: string | null;
  /** @format date-time */
  startedOn?: string | null;
  /** @format date-time */
  completedOn?: string | null;
  siteName?: string | null;
  campaignName?: string | null;
}

export interface FanguardApiContractsPersonEnrollmentEmailRequestModel {
  siteId?: string | null;
  campaignId?: string | null;
  sendEmailConfirmation?: boolean;
  personIds?: string[] | null;
}

export interface FanguardApiContractsPersonEnrollmentRequestModel {
  siteId?: string | null;
  campaignId?: string | null;
  connectionType?: FanguardApiContractsClientConnectionTypeModelEnum;
  personId?: string | null;
  lastStep?: boolean;
}

/** @format int32 */
export enum FanguardApiContractsPersonEnrollmentStatusEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface FanguardApiContractsPersonImpersonateResponseModel {
  token?: string | null;
}

export interface FanguardApiContractsPersonLinkedAccount {
  accountId?: string | null;
  email?: string | null;
  name?: string | null;
  source?: FanguardApiContractsPersonLinkedAccountSource;
  provenueAccessCardBarcode?: string | null;
  ticketmasterCustNameId?: string | null;
  axsMobileId?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsPersonLinkedAccountSource {
  Value0 = 0,
  Value1 = 1,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value14 = 14,
}

export interface FanguardApiContractsPersonPaymentCardModel {
  id?: string | null;
  maskedCardNumber?: string | null;
  cardType?: string | null;
  /** @format date-time */
  createdOn?: string;
  isDefault?: boolean;
}

export interface FanguardApiContractsPersonPaymentCardRemovalRequestModel {
  personId?: string | null;
}

export interface FanguardApiContractsPersonPaymentCardRequestModel {
  personId?: string | null;
  token?: string | null;
}

export interface FanguardApiContractsPersonPersonBulkAddToWatchlistRequestModel {
  personIds?: string[] | null;
  watchlistId?: string | null;
}

export interface FanguardApiContractsPersonPersonBulkPhotoRequestModel {
  personIds?: string[] | null;
  watchlistId?: string | null;
  searchTerm?: string | null;
}

export interface FanguardApiContractsPersonPersonBulkRemoveFromWatchlistRequestModel {
  personIds?: string[] | null;
  watchlistId?: string | null;
  searchTerm?: string | null;
}

export interface FanguardApiContractsPersonPersonClassifierCredentialModel {
  /** @format int64 */
  facilityCode?: number;
  /** @format int64 */
  cardNumber?: number;
}

export interface FanguardApiContractsPersonPersonClassifierModificationModel {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  /** @format int32 */
  batchNumber?: number | null;
  /** @format int32 */
  cardNumber?: number | null;
  propertyCredentials?: FanguardApiContractsPersonPersonClassifierCredentialModel[] | null;
  watchlistId?: string | null;
  photos?: FanguardApiContractsPersonPersonPhotoClassifierModificationModel[] | null;
  /** @format date-time */
  lastEmbeddingModifiedOn?: string;
  personCredentials?: FanguardApiContractsPersonPersonClassifierCredentialModel[] | null;
  ofAge?: boolean;
}

export interface FanguardApiContractsPersonPersonClassifierRemovalModel {
  watchlistId?: string | null;
  personPhotoId?: string | null;
  /** @format date-time */
  removedOn?: string;
}

export interface FanguardApiContractsPersonPersonClassifierSetModel1FanguardApiContractsPersonPersonClassifierModificationModel {
  items?: FanguardApiContractsPersonPersonClassifierModificationModel[] | null;
  /** @format int32 */
  pageSize?: number;
}

export interface FanguardApiContractsPersonPersonClassifierSetModel1FanguardApiContractsPersonPersonClassifierRemovalModel {
  items?: FanguardApiContractsPersonPersonClassifierRemovalModel[] | null;
  /** @format int32 */
  pageSize?: number;
}

export interface FanguardApiContractsPersonPersonCreateLinkedAccountRequestModel {
  accountId?: string | null;
  email?: string | null;
  source?: FanguardApiContractsPersonLinkedAccountSource;
  provenueAccessCardBarcode?: string | null;
}

export interface FanguardApiContractsPersonPersonCreateRequestModel {
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @format email */
  email?: string | null;
  location?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  company?: string | null;
  department?: string | null;
  comments?: string | null;
  watchlists?: string[] | null;
  photo?: string | null;
  client?: string | null;
  force?: boolean;
  credentials?: FanguardApiContractsPersonPersonCredentialModel[] | null;
  sendDecisionNotification?: boolean | null;
  externalData?: Record<string, string | null>;
  source?: string | null;
  optIn?: boolean | null;
  ageConfirmed?: boolean | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  phoneNumber?: string | null;
  linkedAccounts?: FanguardApiContractsPersonPersonCreateLinkedAccountRequestModel[] | null;
}

export interface FanguardApiContractsPersonPersonPhotoResponseModel {
  person: FanguardApiContractsPersonPersonDetailModel;
  photoStatus: number;
}

export interface FanguardApiContractsPersonPersonCredentialModel {
  /** @format int64 */
  facilityCode?: number;
  /** @format int64 */
  cardNumber?: number;
  readOnly?: boolean;
}

export interface FanguardApiContractsPersonPersonDetailModel {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  location?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  gender?: string | null;
  comments?: string | null;
  watchlists?: string[] | null;
  photoUrls?: string[] | null;
  notes?: FanguardApiContractsPersonPersonNoteModel[] | null;
  photos?: FanguardApiContractsPersonPersonPhotoModel[] | null;
  recentAlerts?: FanguardApiContractsInteractionInteractionResponseModel[] | null;
  credentials?: FanguardApiContractsPersonPersonCredentialModel[] | null;
  readOnly?: boolean;
  sendDecisionNotification?: boolean;
  externalData?: Record<string, string | null>;
  linkedAccounts?: FanguardApiContractsPersonLinkedAccount[] | null;
  accountId?: string | null;
  tmAccountId?: string | null;
  provenuePatronId?: string | null;
  provenueAccessCardBarcode?: string | null;
  /** @format date-time */
  createdOn?: string | null;
  /** @format date-time */
  lastSeenOn?: string | null;
  /** @format date-time */
  optInTimestamp?: string | null;
  /** @format date-time */
  ageConfirmationTimestamp?: string | null;
  /** @format date-time */
  idVerificationTimestamp?: string | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  is21?: boolean | null;
  phoneNumber?: string | null;
  enrolledCampaigns?: FanguardApiContractsPersonEnrolledCampaignModel[] | null;
  paymentCards?: FanguardApiContractsPersonPaymentCardModel[] | null;
}

export interface FanguardApiContractsPersonPersonNoteModel {
  id?: string | null;
  note?: string | null;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string | null;
  venueId?: string | null;
  eventId?: string | null;
}

export interface FanguardApiContractsPersonPersonPhotoClassifierModificationModel {
  id?: string | null;
  embedding?: number[] | null;
  /** @format int32 */
  maskStatus?: number;
  /** @format int32 */
  hatStatus?: number;
  /** @format int32 */
  sunglassesStatus?: number;
}

export interface FanguardApiContractsPersonPersonPhotoModel {
  id?: string | null;
  largeFileId?: string | null;
  thumbnailFileId?: string | null;
  status?: FanguardApiContractsPersonPersonPhotoStatusEnum;
  url?: string | null;
  hasHat?: boolean | null;
  hasMask?: boolean | null;
  hasSunglasses?: boolean | null;
  hasOpenMouth?: boolean | null;
  hasAccessory?: boolean | null;
  existingPersonId?: string | null;
}

export interface FanguardApiContractsPersonPersonPhotoRequestModel {
  /** @minLength 1 */
  photo: string;
  force?: boolean;
  deleteInvitation?: boolean;
  workflow?: '?' | 'addPhoto';
}

export interface FanguardApiContractsPersonPersonPhotoErrorResponsModel {
  defaultMessage: string;
  hasAccessory: boolean;
  hasHat: boolean;
  hasMask: boolean;
  hasOpenMouth: boolean;
  hasSunglasses: boolean;
  status: string;
}

export interface FanguardApiContractsPersonPersonPhotoSuccessResponsModel {
  defaultMessage: string;
  hasAccessory: boolean;
  hasHat: boolean;
  hasMask: boolean;
  hasOpenMouth: boolean;
  url: string;
  status: string;
  id: string;
}

export interface FanguardApiContractsPersonPersonPhotoRequestRequestModel {
  multiple?: boolean;
}

/** @format int32 */
export enum FanguardApiContractsPersonPersonPhotoStatusEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
  Value19 = 19,
  Value20 = 20,
  Value21 = 21,
  Value22 = 22,
  Value23 = 23,
  Value24 = 24,
  Value25 = 25,
}

export interface FanguardApiContractsPersonPersonSearchRequest {
  photo?: string | null;
}

export interface FanguardApiContractsPersonPersonSearchResponse {
  /** @format date-time */
  timestamp?: string | null;
  personId?: string | null;
  personName?: string | null;
  source?: string | null;
  /** @format double */
  similarity?: number | null;
  /** @format double */
  confidence?: number | null;
  /** @format double */
  businessConfidence?: number | null;
  /** @format double */
  score?: number;
  photo?: string | null;
  photoUrl?: string | null;
  watchlists?: Record<string, string | null>;
  /** @format int32 */
  rotationCorrection?: number;
  status?: FanguardApiContractsPersonPersonSearchResponseStatus;
  over21?: boolean | null;
}

/** @format int32 */
export enum FanguardApiContractsPersonPersonSearchResponseStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface FanguardApiContractsPersonPersonSummaryModel {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  gender?: string | null;
  comments?: string | null;
  watchlists?: string[] | null;
  primaryPhoto?: FanguardApiContractsPersonPersonPhotoModel;
  /** @format int32 */
  photoCount?: number;
  photos?: FanguardApiContractsPersonPersonPhotoModel[] | null;
  readOnly?: boolean;
  sendDecisionNotification?: boolean;
  /** @format date-time */
  createdOn?: string | null;
  /** @format date-time */
  lastSeenOn?: string | null;
  dateOfBirth?: string | null;
  is21?: boolean | null;
}

export interface FanguardApiContractsPersonPersonUpdateRequestModel {
  firstName?: string | null;
  lastName?: string | null;
  /** @format email */
  email?: string | null;
  isEmailSet?: boolean;
  location?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  company?: string | null;
  department?: string | null;
  comments?: string | null;
  watchlists?: string[] | null;
  photo?: string | null;
  client?: string | null;
  force?: boolean;
  sendDecisionNotification?: boolean | null;
  credentials?: FanguardApiContractsPersonPersonCredentialModel[] | null;
  containsAllowableChange?: boolean;
  optIn?: boolean | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  formId?: string | null;
  externalRequestId?: string | null;
  phoneNumber?: string | null;
  ageConfirmed?: boolean | null;
  externallyActive?: boolean;
}

export interface FanguardApiContractsSensorAccessControlZoneModel {
  /** @format float */
  x1?: number;
  /** @format float */
  y1?: number;
  /** @format float */
  x2?: number;
  /** @format float */
  y2?: number;
  isValid?: boolean;
}

export interface FanguardApiContractsSensorCameraSettingsModel {
  cameraUrl?: string | null;
  enableTapToStart?: boolean;
  enableZmqInterface?: boolean;
}

/** @format int32 */
export enum FanguardApiContractsSensorDeviceStatusEnum {
  offline,
  online,
  unknown,
}

export type FanguardApiContractsSensorDeviceStatusEnumKeys = keyof typeof FanguardApiContractsSensorDeviceStatusEnum;

export interface FanguardApiContractsSensorGraphPoint {
  x?: string | null;
  /** @format double */
  y?: number | null;
}

export interface FanguardApiContractsSensorGraphSeries {
  name?: string | null;
  key?: string | null;
  data?: FanguardApiContractsSensorGraphPoint[] | null;
}

export interface FanguardApiContractsSensorImageSettingsModel {
  enabled?: boolean;
  /** @format int32 */
  height?: number;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  quality?: number;
}

export interface FanguardApiContractsSensorKioskColorPaletteModel {
  /** @format double */
  backgroundImageOpacity?: number | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  numberColor?: string | null;
  standard?: FanguardApiContractsSensorKioskScreenColorsModel;
  knownPersonAccepted?: FanguardApiContractsSensorKioskScreenColorsModel;
  knownPersonDenied?: FanguardApiContractsSensorKioskScreenColorsModel;
  unknownPerson?: FanguardApiContractsSensorKioskScreenColorsModel;
}

export interface FanguardApiContractsSensorKioskColorPaletteRequestModel {
  /** @format int32 */
  backgroundImageOpacity?: number | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  numberColor?: string | null;
  standard?: FanguardApiContractsSensorKioskScreenColorsRequestModel;
  knownPersonAccepted?: FanguardApiContractsSensorKioskScreenColorsRequestModel;
  knownPersonDenied?: FanguardApiContractsSensorKioskScreenColorsRequestModel;
  unknownPerson?: FanguardApiContractsSensorKioskScreenColorsRequestModel;
}

export interface FanguardApiContractsSensorKioskConfigurationModel {
  viewfinderEnabled?: boolean;
  enableTapToStart?: boolean;
  enableZmqInterface?: boolean;
  scanningMethod?: FanguardApiContractsClientScanningMethodModelEnum;
  design?: Record<string, FanguardApiContractsSensorKioskDesignModel>;
  messages?: FanguardApiContractsSensorKioskMessagesModel;
}

export interface FanguardApiContractsSensorKioskConfigurationRequestModel {
  exitPassword?: string | null;
  viewfinderEnabled?: boolean | null;
  enableTapToStart?: boolean | null;
  enableZmqInterface?: boolean | null;
  scanningMethod?: FanguardApiContractsClientScanningMethodModelEnum;
  design?: Record<string, FanguardApiContractsSensorKioskDesignRequestModel>;
  messages?: FanguardApiContractsSensorKioskMessagesRequestModel;
}

export interface FanguardApiContractsSensorKioskDayPassMessagesModel {
  unenrolledTitle?: string | null;
  unenrolledMessage?: string | null;
  unenrolledConfirmationText?: string | null;
  unenrolledCancelText?: string | null;
  alreadyEnrolledTitle?: string | null;
  alreadyEnrolledMessage?: string | null;
  alreadyEnrolledConfirmationText?: string | null;
  enrollmentSuccessTitle?: string | null;
  enrollmentSuccessMessage?: string | null;
  enrollmentSuccessConfirmationText?: string | null;
  guestDataDisclaimerText?: string | null;
}

export interface FanguardApiContractsSensorKioskDayPassMessagesRequestModel {
  unenrolledTitle?: string | null;
  unenrolledMessage?: string | null;
  unenrolledConfirmationText?: string | null;
  unenrolledCancelText?: string | null;
  alreadyEnrolledTitle?: string | null;
  alreadyEnrolledMessage?: string | null;
  alreadyEnrolledConfirmationText?: string | null;
  enrollmentSuccessTitle?: string | null;
  enrollmentSuccessMessage?: string | null;
  enrollmentSuccessConfirmationText?: string | null;
  guestDataDisclaimerText?: string | null;
}

export interface FanguardApiContractsSensorKioskDesignModel {
  images?: Record<string, FanguardApiContractsSensorKioskImageModel>;
  colors?: FanguardApiContractsSensorKioskColorPaletteModel;
}

export interface FanguardApiContractsSensorKioskDesignRequestModel {
  images?: Record<string, FanguardApiContractsSensorKioskImageRequestModel>;
  colors?: FanguardApiContractsSensorKioskColorPaletteRequestModel;
}

export interface FanguardApiContractsSensorKioskGeneralMessagesModel {
  personNameDisplayTemplate?: string | null;
  entryConfirmation?: string | null;
  entryDenied?: string | null;
  exitConfirmation?: string | null;
  exitDenied?: string | null;
}

export interface FanguardApiContractsSensorKioskGeneralMessagesRequestModel {
  personNameDisplayTemplate?: FanguardApiContractsSensorPersonNameDisplayTemplateEnum;
  entryConfirmation?: string | null;
  entryDenied?: string | null;
  exitConfirmation?: string | null;
  exitDenied?: string | null;
}

export interface FanguardApiContractsSensorKioskImageModel {
  id?: string | null;
  image?: string | null;
  name?: string | null;
  /** @format int64 */
  size?: number | null;
  /** @format int32 */
  displaySizePercentage?: number | null;
  url?: string | null;
  delete?: boolean;
}

export interface FanguardApiContractsSensorKioskImageRequestModel {
  id?: string | null;
  image?: string | null;
  name?: string | null;
  /** @format int64 */
  size?: number | null;
  /** @format int32 */
  displaySizePercentage?: number | null;
  url?: string | null;
  delete?: boolean;
}

export interface FanguardApiContractsSensorKioskIntroductionMessagesModel {
  message?: string | null;
  title?: string | null;
  startOnAttentionButtonText?: string | null;
  startOffAttentionButtonText?: string | null;
}

export interface FanguardApiContractsSensorKioskIntroductionMessagesRequestModel {
  message?: string | null;
  title?: string | null;
  startOnAttentionButtonText?: string | null;
  startOffAttentionButtonText?: string | null;
}

export interface FanguardApiContractsSensorKioskMessagesModel {
  general?: FanguardApiContractsSensorKioskGeneralMessagesModel;
  introduction?: FanguardApiContractsSensorKioskIntroductionMessagesModel;
  dayPass?: FanguardApiContractsSensorKioskDayPassMessagesModel;
}

export interface FanguardApiContractsSensorKioskMessagesRequestModel {
  general?: FanguardApiContractsSensorKioskGeneralMessagesRequestModel;
  introduction?: FanguardApiContractsSensorKioskIntroductionMessagesRequestModel;
  dayPass?: FanguardApiContractsSensorKioskDayPassMessagesRequestModel;
}

export interface FanguardApiContractsSensorKioskScreenColorsModel {
  backgroundColor?: string | null;
  backgroundColorOverlay?: string | null;
  /** @format double */
  backgroundColorOpacity?: number | null;
  textColor?: string | null;
  showBackgroundImage?: boolean | null;
}

export interface FanguardApiContractsSensorKioskScreenColorsRequestModel {
  backgroundColor?: string | null;
  backgroundColorOverlay?: string | null;
  /** @format double */
  backgroundColorOpacity?: number | null;
  textColor?: string | null;
  showBackgroundImage?: boolean | null;
}

/** @format int32 */
export enum FanguardApiContractsSensorPersonNameDisplayTemplateEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface FanguardApiContractsSensorRecognitionSettingsModel {
  snapshot?: FanguardApiContractsSensorSnapshotImageSettingsModel;
  contextImage?: FanguardApiContractsSensorImageSettingsModel;
  enabled?: boolean;
  diagnosticsEnabled?: boolean;
  spoofDetectionEnabled?: boolean;
  intentDetectionEnabled?: boolean;
  /** @format double */
  spoofThreshold?: number | null;
  securityMode?: FanguardApiContractsSensorSensorSecurityModeModel;
  accessControlMode?: FanguardApiContractsSensorSensorAccessControlModeModel;
  accessControlZone?: FanguardApiContractsSensorAccessControlZoneModel;
  zones?: Record<string, number[][] | null>;
  /** @format int32 */
  detectionDistance?: number | null;
  blurFaces?: boolean;
  requireConfirmationAfterGranted?: boolean;
  campaignIds?: string[] | null;
  enrollmentQuickCheckEnabled?: boolean;
  dayPassTicketingCampaignId?: string | null;
  allowDayPassScans?: boolean;
  allowTicketlessEnrollment?: boolean;
  screenSaverEnabled?: boolean;
  /** @format double */
  accessResultTimeout?: number | null;
  credentialingPreCheckEnabled?: boolean;
  credentialingPrinterId?: string | null;
  credentialingPrinterGroupId?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsSensorSensorAccessControlModeModel {
  Normal,
  Ticketing,
  Credentialing,
  CredentialingCheckIn,
  OrderFulfillment,
  AgeVerification,
  EnrollmentCheck,
  DayPass,
  RemoteTicketing,
  Multimode,
  DayPassEnrollment,
}

export type FanguardApiContractsSensorSensorAccessControlModeModelKeys =
  keyof typeof FanguardApiContractsSensorSensorAccessControlModeModel;

export interface FanguardApiContractsSensorSensorBeatModel {
  batteryLevel?: number | null;
  batteryState?: FanguardApiContractsBoxBatteryStateModelEnum;
  status?: FanguardApiContractsBoxDeviceDetailStatusEnum;
  softwareVersion?: string | null;
  metrics?: Record<string, number | null>;
  /** @format date-time */
  timestamp?: string;
  /** @format date-time */
  boxTimestamp?: string;
  ipAddress?: string | null;
  /** @format int32 */
  batteryLevel?: number | null;
  batteryState?: FanguardApiContractsBoxBatteryStateModelEnum;
  pairingStatus?: FanguardApiContractsBoxPairingStatusKeys;
}

export interface FanguardApiContractsSensorSensorConfigurationModel {
  version?: string | null;
  recognition?: FanguardApiContractsSensorRecognitionSettingsModel;
  sensor?: FanguardApiContractsSensorCameraSettingsModel;
  system?: FanguardApiContractsSensorSystemSettingsModel;
}

export interface FanguardApiContractsSensorSensorCreateRequestModel {
  name?: string | null;
  client?: string | null;
  type?: FanguardApiContractsSensorSensorModelTypeEnum;
  platform?: FanguardApiContractsSensorSensorModelPlatformEnum;
  /** @format int32 */
  assetId?: number | null;
  venueId?: string | null;
  minionName?: string | null;
}

export interface FanguardApiContractsSensorSensorCrowdModel {
  /** @format int32 */
  count?: number;
  /** @format int32 */
  level?: number | null;
  /** @format int32 */
  thresholdBreached?: number | null;
  /** @format date-time */
  timestamp?: string;
}

export interface FanguardApiContractsSensorSensorGraphModel {
  series?: FanguardApiContractsSensorGraphSeries[] | null;
}

export interface FanguardApiContractsSensorSensorGroupConfigurationModel {
  kiosk?: FanguardApiContractsSensorKioskConfigurationModel;
  ticketingConnectionId?: string | null;
}

export interface FanguardApiContractsSensorSensorGroupConfigurationRequestModel {
  kiosk?: FanguardApiContractsSensorKioskConfigurationRequestModel;
}

export interface FanguardApiContractsSensorSensorGroupDetailModel {
  id?: string | null;
  client?: string | null;
  name?: string | null;
  useMaskedPeoplePhotos?: boolean;
  sensors?: Record<string, string | null>;
  configuration?: FanguardApiContractsSensorSensorGroupConfigurationModel;
}

export interface FanguardApiContractsSensorSensorGroupModel {
  id?: string | null;
  client?: string | null;
  name?: string | null;
  useMaskedPeoplePhotos?: boolean;
  sensors?: Record<string, string | null>;
}

export interface FanguardApiContractsSensorSensorGroupRequestModel {
  name?: string | null;
  sensors?: string[] | null;
  useMaskedPeoplePhotos?: boolean | null;
  configuration?: FanguardApiContractsSensorSensorGroupConfigurationRequestModel;
}

export interface FanguardApiContractsSensorSensorModel {
  id?: string | null;
  name?: string | null;
  status?: FanguardApiContractsSensorDeviceStatusEnumKeys;
  snapshotUrl?: string | null;
  /** @format date-time */
  snapshotUpdatedOn?: string | null;
  watchlists?: FanguardApiContractsSensorSensorWatchlistModel[] | null;
  latestBeat?: FanguardApiContractsSensorSensorBeatModel;
  configuration?: FanguardApiContractsSensorSensorConfigurationModel;
  type?: FanguardApiContractsSensorSensorModelTypeEnum;
  platform?: FanguardApiContractsSensorSensorModelPlatformEnum;
  venueId?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  placementName?: string | null;
  minionName?: string | null;
  sensorGroupId?: string | null;
  isOnline?: boolean;
  externalData?: Record<string, string>;
  tmDeviceId?: string | null;
  latestCrowd?: FanguardApiContractsSensorSensorCrowdModel;
  client?: string | null;
  areSnapshotsEnabled?: boolean;
  sensorPair?: FanguardApiContractsSensorSensorPairModel;
  connectionId?: string | null;
  /** @format double */
  preAuthAmount?: number | null;
  thumbprint?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsSensorSensorModelPlatformEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** @format int32 */
export enum FanguardApiContractsSensorSensorModelTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface FanguardApiContractsSensorSensorPairModel {
  sensorIds?: string[] | null;
  guestSensorId?: string | null;
  attendantSensorId?: string | null;
  operatorSensorId?: string | null;
  sensorPairingMode?: FanguardApiContractsSensorSensorPairingModeKeys;
}

export interface FanguardApiContractsSensorSensorPairRequestModel {
  sensorIds?: string[] | null;
  guestSensorId?: string | null;
  attendantSensorId?: string | null;
  operatorSensorId?: string | null;
  sensorPairingMode?: FanguardApiContractsSensorSensorPairingModeKeys;
}

/** @format int32 */
export enum FanguardApiContractsSensorSensorPairingMode {
  Auto,
  Wifi,
  Bluetooth,
}

export type FanguardApiContractsSensorSensorPairingModeKeys = keyof typeof FanguardApiContractsSensorSensorPairingMode;

export type FanguardApiContractsSensorSensorRequestDiagnosticUploadModel = object;

/** @format int32 */
export enum FanguardApiContractsSensorSensorSecurityModeModel {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface FanguardApiContractsSensorSensorUpdateRequestModel {
  name?: string | null;
  watchlistIds?: string[] | null;
  configuration?: FanguardApiContractsSensorSensorConfigurationModel;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  placementName?: string | null;
  venueId?: string | null;
  minionName?: string | null;
  externalData?: Record<string, string>;
  noPrinterSelected?: boolean;
  sensorPair?: FanguardApiContractsSensorSensorPairRequestModel;
  connectionId?: string | null;
  /** @format double */
  preAuthAmount?: number | null;
}

export interface FanguardApiContractsSensorSensorWatchlistModel {
  watchlistId?: string | null;
  watchlistName?: string | null;
  status?: FanguardApiContractsSensorSensorWatchlistStatusEnumKeys;
}

/** @format int32 */
export enum FanguardApiContractsSensorSensorWatchlistStatusEnum {
  current,
  outdated,
  missing,
}

export type FanguardApiContractsSensorSensorWatchlistStatusEnumKeys =
  keyof typeof FanguardApiContractsSensorSensorWatchlistStatusEnum;

export interface FanguardApiContractsSensorSnapshotImageSettingsModel {
  enabled?: boolean;
  /** @format int32 */
  height?: number;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  quality?: number;
  /** @format int32 */
  interval?: number;
}

export interface FanguardApiContractsSensorSystemSettingsModel {
  /** @format int32 */
  beatInterval?: number;
  /** @format int32 */
  assetId?: number | null;
  /** @format int32 */
  readerNumber?: number | null;
  /** @format int32 */
  entryNumber?: number | null;
  /** @format int32 */
  laneNumber?: number | null;
  panelInterfaceEndpoint?: string | null;
  crowdCountThresholds?: number[] | null;
  scanFacilityCode?: string | null;
  scanAreaCode?: string | null;
  scanDeviceCode?: string | null;
  credentialAreaCode?: string | null;
  controllerId?: string | null;
  controllerName?: string | null;
  /** @format int32 */
  controllerPort?: number | null;
  allowedPriceScales?: string[] | null;
  orderFulfillmentLocationCode?: string | null;
  orderFulfillmentDeviceCode?: string | null;
}

export interface FanguardApiContractsTicketCreateTicketRequestModel {
  eventId?: string | null;
  personId?: string | null;
  section?: string | null;
  row?: string | null;
  seat?: string | null;
  type?: string | null;
  statusDetail?: FanguardApiContractsTicketTicketStatusDetailEnum;
}

export interface FanguardApiContractsTicketRemoteTicketingRequestModel {
  transactionId?: string | null;
  tickets?: FanguardApiContractsTicketRemoteTicketingTicketRequestModel[] | null;
}

export interface FanguardApiContractsTicketRemoteTicketingTicketRequestModel {
  seat?: string | null;
  row?: string | null;
  section?: string | null;
  redemptionAttempted?: boolean;
  scanStatus?: string | null;
  scanStatusDetail?: string | null;
  priceReference?: string | null;
}

export interface FanguardApiContractsTicketSectionModel {
  id?: string | null;
  /**
   * @minLength 1
   * @pattern ^[a-zA-Z0-9]*$
   */
  name: string;
  rows?: string[] | null;
}

export interface FanguardApiContractsTicketTicketGroupCreateRequestModel {
  /** @minLength 1 */
  name: string;
  /** @pattern ^([0-9a-fA-F]{3}){1,2}$ */
  color?: string | null;
  sections?: FanguardApiContractsTicketSectionModel[] | null;
  priceCodes?: string[] | null;
}

export interface FanguardApiContractsTicketTicketGroupDetailModel {
  id?: string | null;
  name?: string | null;
  color?: string | null;
  sections?: FanguardApiContractsTicketSectionModel[] | null;
  priceCodes?: string[] | null;
}

export interface FanguardApiContractsTicketTicketGroupSummaryModel {
  id?: string | null;
  name?: string | null;
  color?: string | null;
  sectionNames?: string[] | null;
  priceCodes?: string[] | null;
}

export interface FanguardApiContractsTicketTicketGroupUpdateRequestModel {
  name?: string | null;
  /** @pattern ^([0-9a-fA-F]{3}){1,2}$ */
  color?: string | null;
  sections?: FanguardApiContractsTicketSectionModel[] | null;
  priceCodes?: string[] | null;
}

export interface FanguardApiContractsTicketTicketModel {
  id?: string | null;
  client?: string | null;
  source?: string | null;
  code?: string | null;
  /** @format date-time */
  createdOn?: string | null;
  createdBy?: string | null;
  /** @format date-time */
  modifiedOn?: string | null;
  personReference?: string | null;
  eventReference?: string | null;
  priceReference?: string | null;
  ticketReference?: string | null;
  deliveryReference?: string | null;
  seat?: string | null;
  row?: string | null;
  section?: string | null;
  statusDetail?: FanguardApiContractsTicketTicketStatusDetailEnum;
  isValid?: boolean;
  rawStatus?: string | null;
  groupName?: string | null;
  groupColor?: string | null;
}

export interface FanguardApiContractsTicketTicketScanRequest {
  decisionId?: string | null;
  tokens?: string[] | null;
  type?: FanguardApiContractsTicketTicketScanRequestTypeEnum;
}

/** @format int32 */
export enum FanguardApiContractsTicketTicketScanRequestTypeEnum {
  Value0 = 0,
  Value1 = 1,
}

export interface FanguardApiContractsTicketTicketScanResult {
  barcode?: string | null;
  token?: string | null;
  accepted?: boolean;
  error?: string | null;
}

export interface FanguardApiContractsTicketTicketScanSummary {
  accepted?: boolean;
  scanType?: FanguardApiContractsTicketTicketScanRequestTypeEnum;
  resultDetail?: string | null;
  barcode?: string | null;
  seat?: string | null;
  row?: string | null;
  section?: string | null;
  price?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsTicketTicketStatusDetailEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface FanguardApiContractsUserAuthenticationRequest {
  username?: string | null;
  password?: string | null;
  refreshToken?: string | null;
  invitationCode?: string | null;
  authorizationCode?: string | null;
  tokenId?: string | null;
  auth0Token?: string | null;
  pathSegment?: string | null;
  axsAccessToken?: string | null;
  cventConfirmationNumber?: string | null;
  cventContactId?: string | null;
  type?: FanguardApiContractsUserAuthenticationRequestType;
  isValid?: boolean;
  client?: string | null;
  redirectUri?: string | null;
  ageConfirmed?: boolean | null;
  phone?: string | null;
  photo?: string | null;
  personToken?: string | null;
  attemptId?: string | null;
  otp?: string | null;
  verificationCode?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsUserAuthenticationRequestType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
}

export interface FanguardApiContractsUserDeviceModel {
  /** @minLength 1 */
  token: string;
  /** @minLength 1 */
  platform: string;
  client?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsUserInvitationTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value4 = 4,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
}

export interface FanguardApiContractsUserLogoutRequest {
  deviceToken?: string | null;
}

export interface FanguardApiContractsUserSensorNotificationPreferencesResponseModel {
  sensorStatusChange?: boolean;
  densityThresholdExceeded?: boolean;
  ticketRedemptionSuccessful?: boolean;
  ticketRedemptionPartialFailure?: boolean;
  venueSensorStatusChange?: string[] | null;
}

export interface FanguardApiContractsUserSignAgreementRequest {
  agreementId?: string | null;
}

export interface FanguardApiContractsUserUserAlertNotificationPreferenceRequestModel {
  watchlistId?: string | null;
  alertStatuses?: string[] | null;
}

export interface FanguardApiContractsUserUserClientModel {
  id?: string | null;
  name?: string | null;
  domainSlug?: string | null;
}

export interface FanguardApiContractsUserUserClientPreferenceRequestModel {
  defaultVenueId?: string | null;
}

export interface FanguardApiContractsUserUserClientPreferenceResponseModel {
  defaultVenueId?: string | null;
}

export interface FanguardApiContractsUserUserDetailModel {
  username?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  preferences?: FanguardApiContractsUserUserPreferencesResponseModel;
  sysAdmin?: boolean;
  lockedOut?: boolean;
  clients?: FanguardApiContractsUserUserClientModel[] | null;
  source?: FanguardApiContractsUserUserSourceEnum;
  scopes?: FanguardApiContractsUserUserRequestScopeModel[] | null;
}

export interface FanguardApiContractsUserUserPatchRequestModel {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  currentPassword?: string | null;
  password?: string | null;
  scopes?: FanguardApiContractsUserUserRequestScopeModel[] | null;
  scopesToRemove?: string[] | null;
  sendInvitation?: boolean;
  preferences?: FanguardApiContractsUserUserPreferencesRequestModel;
  sysAdmin?: boolean | null;
}

export interface FanguardApiContractsUserUserPreferencesRequestModel {
  defaultClient?: string | null;
  alertNotifications?: FanguardApiContractsUserUserAlertNotificationPreferenceRequestModel[] | null;
  sensorNotifications?: FanguardApiContractsUserUserSensorNotificationPreferenceRequestModel;
  clientPreferences?: FanguardApiContractsUserUserClientPreferenceRequestModel;
}

export interface FanguardApiContractsUserUserPreferencesResponseModel {
  defaultClient?: string | null;
  alertNotificationPreferences?: Record<string, string[]>;
  sensorNotificationPreferences?: FanguardApiContractsUserSensorNotificationPreferencesResponseModel;
  clientPreferences?: FanguardApiContractsUserUserClientPreferenceResponseModel;
}

export interface FanguardApiContractsUserUserRequestModel {
  /**
   * @minLength 1
   * @pattern ^[a-zA-Z0-9_.@-]*$
   */
  username: string;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  email: string;
  scopes?: FanguardApiContractsUserUserRequestScopeModel[] | null;
  sysAdmin?: boolean | null;
  source?: FanguardApiContractsUserUserSourceEnum;
}

export interface FanguardApiContractsUserUserRequestScopeModel {
  client?: string | null;
  venueId?: string | null;
  permissions?: string[] | null;
  statuses?: FanguardApiContractsInteractionInteractionWorkflowStageModelEnum[] | null;
  watchlists?: string[] | null;
  venues?: string[] | null;
}

export interface FanguardApiContractsUserUserSensorNotificationPreferenceRequestModel {
  sensorStatusChange?: boolean;
  densityThresholdExceeded?: boolean | null;
  ticketRedemptionSuccessful?: boolean | null;
  ticketRedemptionPartialFailure?: boolean | null;
  venueSensorStatusChange?: string[] | null;
}

export interface FanguardApiContractsUserUserSetPasswordRequestModel {
  currentPassword?: string | null;
  /** @pattern ^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~]).{10,64}$ */
  password?: string | null;
}

/** @format int32 */
export enum FanguardApiContractsUserUserSourceEnum {
  Value0 = 0,
  Value1 = 1,
}

export interface FanguardApiContractsWatchlistSensorSummaryModel {
  id?: string | null;
  name?: string | null;
}

export interface FanguardApiContractsWatchlistWatchlistDetailModel {
  id?: string | null;
  /** @minLength 1 */
  name: string;
  shortName?: string | null;
  /** @format int32 */
  personCount?: number;
  context?: string | null;
  /** @format double */
  alertThreshold?: number | null;
  sensors?: FanguardApiContractsWatchlistSensorSummaryModel[] | null;
  /** @format date-time */
  createdOn?: string | null;
  createdBy?: string | null;
}

export interface FanguardApiContractsWatchlistWatchlistGeneratorModel {
  id?: string | null;
  name?: string | null;
  members?: FanguardApiContractsWatchlistWatchlistMemberGeneratorModel[] | null;
}

export interface FanguardApiContractsWatchlistWatchlistMemberGeneratorModel {
  id?: string | null;
  photos?: string[] | null;
}

export interface FanguardApiContractsWatchlistWatchlistSummaryModel {
  id?: string | null;
  /** @minLength 1 */
  name: string;
  shortName?: string | null;
  /** @format int32 */
  personCount?: number;
  context?: string | null;
  /** @format double */
  alertThreshold?: number | null;
  sensors?: FanguardApiContractsWatchlistSensorSummaryModel[] | null;
}

export interface FanguardApiControllersLinkAccountRequestModel {
  code?: string | null;
  redirectUri?: string | null;
  source?: string | null;
}

export interface FanguardApiControllersPersonImportRequestModel {
  watchlistId?: string | null;
  watchlistName?: string | null;
  watchlistPurpose?: string | null;
  /** @format binary */
  file: File;
  includesHeaderRow?: boolean;
  columnMapping?: string[] | null;
}

export interface FanguardCoreEntitiesClientPreference {
  defaultVenueId?: string | null;
}

export interface FanguardCoreEntitiesDevice {
  token?: string | null;
  client?: string | null;
  platform?: string | null;
  /** @format date-time */
  createdOn?: string;
  /** @format date-time */
  modifiedOn?: string | null;
}

/** @format int32 */
export enum FanguardCoreEntitiesInteractionWorkflowStageEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface FanguardCoreEntitiesNotificationPreference {
  options?: string[] | null;
}

export interface FanguardCoreEntitiesScope {
  client?: string | null;
  venueId?: string | null;
  permissions?: string[] | null;
  statuses?: FanguardCoreEntitiesInteractionWorkflowStageEnum[] | null;
  watchlistIds?: string[] | null;
  entityId?: string | null;
  ageConfirmed?: boolean;
  venueIds?: string[] | null;
}

export interface FanguardCoreEntitiesSensorNotificationPreferences {
  sensorStatusChange?: boolean;
  densityThresholdExceeded?: boolean;
  ticketRedemptionSuccessful?: boolean;
  ticketRedemptionPartialFailure?: boolean;
  venueSensorStatusChange?: string[] | null;
}

export interface FanguardCoreEntitiesSignedAgreement {
  client?: string | null;
  agreementKey?: string | null;
  /** @format int32 */
  version?: number;
  /** @format date-time */
  signedOn?: string;
}

export interface FanguardCoreEntitiesUser {
  id?: string | null;
  hashedPassword?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  devices?: FanguardCoreEntitiesDevice[] | null;
  scopes?: FanguardCoreEntitiesScope[] | null;
  signedAgreements?: FanguardCoreEntitiesSignedAgreement[] | null;
  chatUserSid?: string | null;
  preferences?: FanguardCoreEntitiesUserPreferences;
  sysAdmin?: boolean;
  /** @format date-time */
  lockedOutOn?: string | null;
  source?: FanguardCoreEntitiesUserSource;
}

export interface FanguardCoreEntitiesUserPreferences {
  defaultClient?: string | null;
  clientPreferences?: Record<string, FanguardCoreEntitiesClientPreference>;
  notificationPreferences?: Record<string, FanguardCoreEntitiesNotificationPreference>;
  sensorNotificationPreferences?: FanguardCoreEntitiesSensorNotificationPreferences;
}

/** @format int32 */
export enum FanguardCoreEntitiesUserSource {
  Value0 = 0,
  Value1 = 1,
}

/** @format int32 */
export enum FanguardCoreInfrastructureDataSortDirection {
  Value0 = 0,
  Value1 = 1,
}

export interface FanguardCoreManagersCredentialingCredentialArea {
  name?: string | null;
  identifier?: string | null;
}

export interface FanguardCoreManagersCredentialingCredentialingPrinter {
  id?: string | null;
  name?: string | null;
  groupIds?: string[] | null;
}

export interface FanguardCoreManagersCredentialingCredentialingPrinterGroup {
  id?: string | null;
  name?: string | null;
}

export interface FanguardCoreManagersCredentialingCredentialingPrinters {
  groups?: FanguardCoreManagersCredentialingCredentialingPrinterGroup[] | null;
  printers?: FanguardCoreManagersCredentialingCredentialingPrinter[] | null;
}

/** @format int32 */
export enum FanguardCoreManagersSensorControlEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface FanguardCoreServicesAuthenticationResult {
  status?: FanguardCoreServicesAuthenticationStatus;
  token?: string | null;
  refreshToken?: string | null;
  user?: FanguardCoreEntitiesUser;
  attemptId?: string | null;
  personToken?: string | null;
  message?: string | null;
}

/** @format int32 */
export enum FanguardCoreServicesAuthenticationStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
}

export interface FanguardCoreServicesTemporaryToken {
  accessKey?: string | null;
  secretAccessKey?: string | null;
  sessionToken?: string | null;
  /** @format date-time */
  expiration?: string;
}

export interface MicrosoftAspNetCoreMvcProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '/api';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Fanguard API 1.8
 * @version 1.8
 * @baseUrl /api
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags Authorization
     * @name GroupsList
     * @request GET:/auth/groups
     * @secure
     */
    groupsList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsPermissionGroupModel[], any>({
        path: `/auth/groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authorization
     * @name PermissionsList
     * @request GET:/auth/permissions
     * @secure
     */
    permissionsList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsPermissionPermissionModel[], any>({
        path: `/auth/permissions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  clients = {
    /**
     * No description
     *
     * @tags Client
     * @name CheckNetworkSwitchCreate
     * @request POST:/clients/checkNetworkSwitch
     * @secure
     */
    checkNetworkSwitchCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/checkNetworkSwitch`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name CompleteNetworkSwitchCreate
     * @request POST:/clients/completeNetworkSwitch
     * @secure
     */
    completeNetworkSwitchCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/completeNetworkSwitch`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name CredentialingAreasList
     * @summary Retrieves a list of Accredit credentialing areas.
     * @request GET:/clients/credentialing-areas
     * @secure
     */
    credentialingAreasList: (params: RequestParams = {}) =>
      this.request<FanguardCoreManagersCredentialingCredentialArea[], any>({
        path: `/clients/credentialing-areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name PrintersList
     * @request GET:/clients/printers
     * @secure
     */
    printersList: (params: RequestParams = {}) =>
      this.request<FanguardCoreManagersCredentialingCredentialingPrinters, any>({
        path: `/clients/printers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name StartNetworkSwitchCreate
     * @request POST:/clients/startNetworkSwitch
     * @secure
     */
    startNetworkSwitchCreate: (
      data: FanguardApiContractsClientStartNetworkSwitchRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/startNetworkSwitch`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name TestWebhookCreate
     * @request POST:/clients/test-webhook
     * @secure
     */
    testWebhookCreate: (data: FanguardApiContractsClientTestWebhookConnection, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/test-webhook`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name AssetDelete
     * @request DELETE:/clients/{id}/asset
     * @secure
     */
    assetDelete: (id: string, data: FanguardApiContractsClientDeleteAssetRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/asset`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name AssetDetail
     * @request GET:/clients/{id}/asset
     * @secure
     */
    assetDetail: (id: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientAssetModel[], any>({
        path: `/clients/${id}/asset`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name AssetCreate
     * @request POST:/clients/{id}/asset
     * @secure
     */
    assetCreate: (id: string, data: FanguardApiContractsClientUploadAssetRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/asset`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ConnectionServiceKeysDelete
     * @request DELETE:/clients/{id}/connection/{connectionId}/service-keys/{serviceKeyId}
     * @secure
     */
    connectionServiceKeysDelete: (id: string, connectionId: string, serviceKeyId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/connection/${connectionId}/service-keys/${serviceKeyId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ConnectionServiceKeysUpdate
     * @request PUT:/clients/{id}/connection/{connectionId}/service-keys/{serviceKeyId}
     * @secure
     */
    connectionServiceKeysUpdate: (
      id: string,
      connectionId: string,
      serviceKeyId: string,
      query?: {
        setActive?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/connection/${connectionId}/service-keys/${serviceKeyId}`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ConnectionServiceKeysCreate
     * @request POST:/clients/{id}/connection/{connectionId}/service-keys
     * @secure
     */
    connectionServiceKeysCreate: (id: string, connectionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/connection/${connectionId}/service-keys`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ConnectionDelete
     * @request DELETE:/clients/{id}/connection/{connectionId}
     * @secure
     */
    connectionDelete: (id: string, connectionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/connection/${connectionId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ConnectionDetail
     * @request GET:/clients/{id}/connection/{connectionId}
     * @secure
     */
    connectionDetail: (id: string, connectionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/connection/${connectionId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ConnectionPartialUpdate
     * @request PATCH:/clients/{id}/connection/{connectionId}
     * @secure
     */
    connectionPartialUpdate: (
      connectionId: string,
      id: string,
      data: FanguardApiContractsClientConnectionRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/connection/${connectionId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ConnectionCreate
     * @request POST:/clients/{id}/connection
     * @secure
     */
    connectionCreate: (
      id: string,
      data: FanguardApiContractsClientConnectionRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/connection`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ClientsDelete
     * @request DELETE:/clients/{id}
     * @secure
     */
    clientsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ClientsDetail
     * @request GET:/clients/{id}
     * @secure
     */
    clientsDetail: (id: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientClientResponseDetailModel, any>({
        path: `/clients/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ClientsPartialUpdate
     * @request PATCH:/clients/{id}
     * @secure
     */
    clientsPartialUpdate: (
      id: string,
      data: FanguardApiContractsClientClientUpdateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsClientClientResponseDetailModel, any>({
        path: `/clients/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ClientsList
     * @request GET:/clients
     * @secure
     */
    clientsList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientClientResponseSummaryModel[], any>({
        path: `/clients`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ClientsCreate
     * @request POST:/clients
     * @secure
     */
    clientsCreate: (data: FanguardApiContractsClientClientCreateRequestModel, params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientClientResponseDetailModel, any>({
        path: `/clients`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  config = {
    /**
     * No description
     *
     * @tags Configuration
     * @name AuthDetail
     * @request GET:/config/auth/{slug}
     * @secure
     */
    authDetail: (slug: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/config/auth/${slug}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Configuration
     * @name ConfigList
     * @summary Retrieve application configuration settings
     * @request GET:/config
     * @secure
     */
    configList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsConfigModel, any>({
        path: `/config`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  controllers = {
    /**
     * No description
     *
     * @tags Controller
     * @name CertificateCreate
     * @summary Upgrade the controller container to most recent image
     * @request POST:/controllers/{controllerId}/certificate
     * @secure
     */
    certificateCreate: (controllerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/controllers/${controllerId}/certificate`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name ContainerCreate
     * @summary Upgrade the controller container to most recent image
     * @request POST:/controllers/{controllerId}/container
     * @secure
     */
    containerCreate: (controllerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/controllers/${controllerId}/container`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name ControlDetail
     * @request GET:/controllers/{controllerId}/control/{control}
     * @secure
     */
    controlDetail: (controllerId: string, control: FanguardCoreManagersSensorControlEnum, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/controllers/${controllerId}/control/${control}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name GetControllers
     * @request GET:/controllers/{controllerId}/log
     * @secure
     */
    getControllers: (
      controllerId: string,
      query?: {
        /**
         * @format int32
         * @default 100
         */
        lineCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/controllers/${controllerId}/log`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name PortSensorDelete
     * @request DELETE:/controllers/{controllerId}/port/{controllerPort}/sensor
     * @secure
     */
    portSensorDelete: (
      controllerId: string,
      controllerPort: number,
      data: FanguardApiContractsControllerControllerPortSensorAssignmentRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/controllers/${controllerId}/port/${controllerPort}/sensor`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name PortSensorCreate
     * @request POST:/controllers/{controllerId}/port/{controllerPort}/sensor
     * @secure
     */
    portSensorCreate: (
      controllerId: string,
      controllerPort: number,
      data: FanguardApiContractsControllerControllerPortSensorAssignmentRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsControllerControllerModel, any>({
        path: `/controllers/${controllerId}/port/${controllerPort}/sensor`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name PortPartialUpdate
     * @summary Update controller port
     * @request PATCH:/controllers/{controllerId}/port/{controllerPort}
     * @secure
     */
    portPartialUpdate: (
      controllerId: string,
      controllerPort: number,
      data: FanguardApiContractsControllerControllerPortUpdateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsControllerControllerModel, any>({
        path: `/controllers/${controllerId}/port/${controllerPort}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name TestCreate
     * @request POST:/controllers/{controllerId}/test
     * @secure
     */
    testCreate: (
      controllerId: string,
      data: FanguardApiContractsControllerControllerPortTestRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/controllers/${controllerId}/test`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name ControllersDetail
     * @summary Retrieves a specific controller
     * @request GET:/controllers/{controllerId}
     * @secure
     */
    controllersDetail: (controllerId: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsControllerControllerModel, any>({
        path: `/controllers/${controllerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name ControllersPartialUpdate
     * @summary Update controller
     * @request PATCH:/controllers/{controllerId}
     * @secure
     */
    controllersPartialUpdate: (
      controllerId: string,
      data: FanguardApiContractsControllerControllerUpdateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsControllerControllerModel, any>({
        path: `/controllers/${controllerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name ControllersDelete
     * @request DELETE:/controllers/{sensorId}
     * @secure
     */
    controllersDelete: (sensorId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/controllers/${sensorId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Controller
     * @name ControllersList
     * @summary List controllers
     * @request GET:/controllers
     * @secure
     */
    controllersList: (
      query?: {
        /** @default "all" */
        venueId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsControllerControllerModel[], any>({
        path: `/controllers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  detections = {
    /**
     * No description
     *
     * @tags Detection
     * @name StartCreate
     * @request POST:/detections/start
     * @secure
     */
    startCreate: (data: FanguardApiContractsDetectionRemoteDetectionCreateRequest, params: RequestParams = {}) =>
      this.request<FanguardApiContractsDetectionRemoteDetectionResponse, any>({
        path: `/detections/start`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Detection
     * @name DetectionsCreate
     * @request POST:/detections
     * @secure
     */
    detectionsCreate: (data: FanguardApiContractsDetectionUnifiedDetectionCreateRequest, params: RequestParams = {}) =>
      this.request<FanguardApiContractsDecisionDetectionProcessorResultModel, any>({
        path: `/detections`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  devices = {
    /**
     * No description
     *
     * @tags Device
     * @name DevicesList
     * @summary List devices: sensors and controllers.
     * @request GET:/devices
     * @secure
     */
    devicesList: (
      query?: {
        venueId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsDevicesDeviceModel[], any>({
        path: `/devices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  emailTemplates = {
    /**
     * No description
     *
     * @tags EmailTemplate
     * @name SendTestCreate
     * @request POST:/email-templates/{id}/send-test
     * @secure
     */
    sendTestCreate: (
      id: string,
      data: FanguardApiContractsClientSendTestEmailRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/email-templates/${id}/send-test`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmailTemplate
     * @name EmailTemplatesDetail
     * @request GET:/email-templates/{id}
     * @secure
     */
    emailTemplatesDetail: (id: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientEmailTemplateModel, any>({
        path: `/email-templates/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmailTemplate
     * @name EmailTemplatesPartialUpdate
     * @request PATCH:/email-templates/{id}
     * @secure
     */
    emailTemplatesPartialUpdate: (
      id: string,
      data: FanguardApiContractsClientEmailTemplateUpdateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsClientEmailTemplateModel, any>({
        path: `/email-templates/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmailTemplate
     * @name EmailTemplatesList
     * @request GET:/email-templates
     * @secure
     */
    emailTemplatesList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientEmailTemplateModel[], any>({
        path: `/email-templates`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EmailTemplate
     * @name EmailTemplatesCreate
     * @request POST:/email-templates
     * @secure
     */
    emailTemplatesCreate: (
      data: FanguardApiContractsClientEmailTemplateCreateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsClientEmailTemplateModel, any>({
        path: `/email-templates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  enrollment = {
    /**
     * No description
     *
     * @tags Enrollment
     * @name CompleteStepUpdate
     * @request PUT:/enrollment/complete-step
     * @secure
     */
    completeStepUpdate: (data: FanguardApiContractsPersonEnrollmentRequestModel, params: RequestParams = {}) =>
      this.request<FanguardApiContractsPersonPersonDetailModel, any>({
        path: `/enrollment/complete-step`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enrollment
     * @name RequestCreate
     * @request POST:/enrollment/request
     * @secure
     */
    requestCreate: (data: FanguardApiContractsPersonEnrollmentEmailRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/enrollment/request`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enrollment
     * @name StartCreate
     * @request POST:/enrollment/start
     * @secure
     */
    startCreate: (data: FanguardApiContractsPersonEnrollmentRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/enrollment/start`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enrollment
     * @name EnrollmentDetail
     * @request GET:/enrollment/{id}
     * @secure
     */
    enrollmentDetail: (id: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsPersonEnrolledCampaignSummaryModel[], any>({
        path: `/enrollment/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  events = {
    /**
     * No description
     *
     * @tags Event
     * @name ImportCreate
     * @request POST:/events/import
     * @secure
     */
    importCreate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/events/import`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventsDetail
     * @request GET:/events/{eventId}
     * @secure
     */
    eventsDetail: (
      eventId: string,
      query?: {
        /** @default "" */
        venueId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsClientEventDetailModel, any>({
        path: `/events/${eventId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventsDelete
     * @request DELETE:/events/{id}
     * @secure
     */
    eventsDelete: (id: string, params: RequestParams = {}) =>
      this.request<any, MicrosoftAspNetCoreMvcProblemDetails>({
        path: `/events/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventsUpdate
     * @request PUT:/events/{id}
     * @secure
     */
    eventsUpdate: (id: string, data: FanguardApiContractsClientEditEventRequestModel, params: RequestParams = {}) =>
      this.request<any, MicrosoftAspNetCoreMvcProblemDetails>({
        path: `/events/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventsList
     * @summary List events
     * @request GET:/events
     * @secure
     */
    eventsList: (
      query?: {
        venueId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsClientEventDetailModel[], any>({
        path: `/events`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventsCreate
     * @request POST:/events
     * @secure
     */
    eventsCreate: (data: FanguardApiContractsClientCreateEventRequestModel, params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientEventDetailModel, any>({
        path: `/events`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  frNetworks = {
    /**
     * No description
     *
     * @tags FrNetworks
     * @name FrNetworksList
     * @summary Retrieve available FR networks
     * @request GET:/frNetworks
     * @secure
     */
    frNetworksList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsFrNetworkFrNetworkModel[], any>({
        path: `/frNetworks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  generator = {
    /**
     * No description
     *
     * @tags Generator
     * @name PersonImageDetail
     * @request GET:/generator/person-image/{fileId}
     * @secure
     */
    personImageDetail: (fileId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/generator/person-image/${fileId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Generator
     * @name WatchlistDetail
     * @request GET:/generator/watchlist/{watchlistId}
     * @secure
     */
    watchlistDetail: (watchlistId: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsWatchlistWatchlistGeneratorModel, any>({
        path: `/generator/watchlist/${watchlistId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  hosts = {
    /**
     * No description
     *
     * @tags Host
     * @name HostsList
     * @request GET:/hosts
     * @secure
     */
    hostsList: (
      query?: {
        statuses?: FanguardApiContractsHostHostStatusModelEnum[];
        products?: FanguardApiContractsHostHostProductModelEnum[];
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsHostHostModel[], any>({
        path: `/hosts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Host
     * @name HostsCreate
     * @request POST:/hosts
     * @secure
     */
    hostsCreate: (data: FanguardApiContractsHostHostProvisionRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/hosts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  idVerification = {
    /**
     * No description
     *
     * @tags IdVerification
     * @name StartCreate
     * @request POST:/id-verification/start
     * @secure
     */
    startCreate: (
      data: FanguardApiContractsIdVerificationStartIdVerificationRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsIdVerificationStartIdVerificationProcessResponseModel, any>({
        path: `/id-verification/start`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  interactions = {
    /**
     * No description
     *
     * @tags Interaction
     * @name NotesDelete
     * @summary Remove a note from an interaction
     * @request DELETE:/interactions/{interactionId}/notes/{noteId}
     * @secure
     */
    notesDelete: (
      interactionId: string,
      noteId: string,
      query?: {
        type?: FanguardApiContractsInteractionInteractionTypeEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/interactions/${interactionId}/notes/${noteId}`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interaction
     * @name NotesCreate
     * @summary Add a note to an interaction
     * @request POST:/interactions/{interactionId}/notes
     * @secure
     */
    notesCreate: (
      interactionId: string,
      data: FanguardApiContractsInteractionInteractionNoteRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/interactions/${interactionId}/notes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interaction
     * @name SharedUrlDetail
     * @request GET:/interactions/{interactionId}/shared-url
     * @secure
     */
    sharedUrlDetail: (
      interactionId: string,
      query?: {
        type?: FanguardApiContractsInteractionInteractionTypeEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsInteractionInteractionSharedUrlResponseModel, any>({
        path: `/interactions/${interactionId}/shared-url`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interaction
     * @name ValidationStatusPartialUpdate
     * @summary Set the validation status of an interaction
     * @request PATCH:/interactions/{interactionId}/validation-status
     * @secure
     */
    validationStatusPartialUpdate: (
      interactionId: string,
      data: FanguardApiContractsInteractionInteractionValidationStatusUpdateModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/interactions/${interactionId}/validation-status`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interaction
     * @name WorkflowStagePartialUpdate
     * @summary Set the workflow stage of an interaction alert
     * @request PATCH:/interactions/{interactionId}/workflow-stage
     * @secure
     */
    workflowStagePartialUpdate: (
      interactionId: string,
      data: FanguardApiContractsInteractionInteractionWorkflowStageUpdateModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/interactions/${interactionId}/workflow-stage`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interaction
     * @name InteractionsDetail
     * @summary Retrieve a single interaction
     * @request GET:/interactions/{interactionId}
     * @secure
     */
    interactionsDetail: (
      interactionId: string,
      query: {
        /** 'Alert', 'AccessControl' or 'Ticketing' */
        type: FanguardApiContractsInteractionInteractionTypeEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsInteractionInteractionResponseModel, any>({
        path: `/interactions/${interactionId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interaction
     * @name InteractionsList
     * @summary Retrieve a list of interactions
     * @request GET:/interactions
     * @secure
     */
    interactionsList: (
      query: {
        /** 'Alert', 'AccessControl' or 'Ticketing' */
        type: FanguardApiContractsInteractionInteractionTypeEnum;
        /** Comma separated list of WatchlistIDs */
        watchlistIds?: string;
        /** Comma separated list of SensorIDs */
        sensorIds?: string;
        /** Comma separated list of VenueIDs */
        venueIds?: string;
        /** Comma separated list of EventIDs */
        eventIds?: string;
        /** Comma separated list of watchlist stages */
        workflowStages?: string;
        /** Comma separated list of validation statuses */
        validationStatuses?: string;
        /** Comma separated list of grant statuses */
        grantStatuses?: string;
        /**
         * True if interactions below threshold should be returned.  False, othewise.
         * @default false
         */
        belowThreshold?: boolean;
        /**
         * True if spoof attempts should be returned.  False, otherwise.
         * @default true
         */
        spoofAttempts?: boolean;
        /** @default true */
        conflictingMatches?: boolean;
        /**
         * True if diagnostics should be returned.  False, otherwise.
         * @default false
         */
        diagnostics?: boolean;
        /**
         * True if blurred attempts should be returned.  False, otherwise.
         * @default false
         */
        blur?: boolean;
        /**
         * True if interactions with invalid tickets should be returned.  False, otherwise.
         * @default true
         */
        invalidTickets?: boolean;
        /**
         * Page number to return.
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * Page size to return.
         * @format int32
         * @default 10
         */
        pageSize?: number;
        /** Free-form search term that attempt to match against: Id, PersonId, PersonPhotoId, FaceTrackId, PersonFirstName, PersonLastName */
        searchTerm?: string;
        /**
         * Lower bound of similarity to return
         * @format double
         * @default 0
         */
        similarityMin?: number;
        /**
         * Upper bound of similarity to return
         * @format double
         * @default 1
         */
        similarityMax?: number;
        /**
         * @format double
         * @default 0
         */
        confidenceMin?: number;
        /**
         * @format double
         * @default 1
         */
        confidenceMax?: number;
        /**
         * @format double
         * @default 0
         */
        spoofMin?: number;
        /**
         * @format double
         * @default 1
         */
        spoofMax?: number;
        /**
         * Interactions after this datetime will be returned.
         * @format date-time
         */
        createdStart?: string;
        /**
         * Interactions before this datetime will be returned.
         * @format date-time
         */
        createdEnd?: string;
        /**
         * Include total count in response
         * @default false
         */
        includeCount?: boolean;
        sinceInteractionId?: string;
        affirmed?: boolean;
        /** @default false */
        fromSupportView?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsInteractionInteractionListModel, any>({
        path: `/interactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  jobs = {
    /**
     * No description
     *
     * @tags Job
     * @name JobsDetail
     * @request GET:/jobs/{id}
     * @secure
     */
    jobsDetail: (id: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsJobJobDetailModel, any>({
        path: `/jobs/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  orders = {
    /**
     * No description
     *
     * @tags Order
     * @name CheckoutAifiCreate
     * @request POST:/orders/checkout/aifi/{clientId}
     * @secure
     */
    checkoutAifiCreate: (
      clientId: string,
      data: FanguardApiContractsPersonAiFiCheckoutRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/orders/checkout/aifi/${clientId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name CompleteCreate
     * @request POST:/orders/complete
     * @secure
     */
    completeCreate: (data: FanguardApiContractsOrderOrderCompleteRequestModel, params: RequestParams = {}) =>
      this.request<FanguardApiContractsDetectionRemoteDetectionResponse, any>({
        path: `/orders/complete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name UsersPinDetail
     * @request GET:/orders/users/pin/{pin}
     * @secure
     */
    usersPinDetail: (pin: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsOrderOperatorValidationResultModel, any>({
        path: `/orders/users/pin/${pin}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name CompleteCreate2
     * @request POST:/orders/{interactionId}/complete
     * @originalName completeCreate
     * @duplicate
     * @secure
     */
    completeCreate2: (interactionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/orders/${interactionId}/complete`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name ClaimCreate
     * @request POST:/orders/{orderNumber}/claim
     * @secure
     */
    claimCreate: (
      orderNumber: string,
      data: FanguardApiContractsOrderClaimOrderRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/orders/${orderNumber}/claim`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  persons = {
    /**
     * No description
     *
     * @tags Person
     * @name GetPersonClassifierModifications
     * @summary Get modifications to embeddings for a given watchlist since a given date
     * @request GET:/persons/classifier/{watchlistId}
     * @secure
     */
    getPersonClassifierModifications: (
      watchlistId: string,
      query?: {
        /** @format int32 */
        page?: number;
        /** @format date-time */
        sinceDateTime?: string;
        /** @format int32 */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        FanguardApiContractsPersonPersonClassifierSetModel1FanguardApiContractsPersonPersonClassifierModificationModel,
        any
      >({
        path: `/persons/classifier/${watchlistId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name GetPersonClassifierRemovals
     * @summary Get embeddings removals from a given watchlist since a given date
     * @request GET:/persons/classifierRemovals/{watchlistId}
     * @secure
     */
    getPersonClassifierRemovals: (
      watchlistId: string,
      query?: {
        /** @format int32 */
        page?: number;
        /** @format date-time */
        sinceDateTime?: string;
        /** @format int32 */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        FanguardApiContractsPersonPersonClassifierSetModel1FanguardApiContractsPersonPersonClassifierRemovalModel,
        any
      >({
        path: `/persons/classifierRemovals/${watchlistId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name EmbeddingsCreate
     * @request POST:/persons/embeddings
     * @secure
     */
    embeddingsCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/persons/embeddings`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name CreateGuest
     * @summary Create guest
     * @request POST:/persons/guest
     * @secure
     */
    createGuest: (data: FanguardApiContractsPersonCreateGuestRequest, params: RequestParams = {}) =>
      this.request<void, FanguardApiContractsErrorErrorModel>({
        path: `/persons/guest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name ImportCreate
     * @request POST:/persons/import
     * @secure
     */
    importCreate: (
      query?: {
        json?: FanguardApiControllersPersonImportRequestModel;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/persons/import`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name LinkAccountList
     * @request GET:/persons/link-account
     * @secure
     */
    linkAccountList: (
      query?: {
        path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/persons/link-account`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name LinkAccountCreate
     * @request POST:/persons/link-account
     * @secure
     */
    linkAccountCreate: (data: FanguardApiControllersLinkAccountRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/persons/link-account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name PhotoRequestCreate
     * @request POST:/persons/photoRequest
     * @secure
     */
    photoRequestCreate: (data: FanguardApiContractsPersonPersonBulkPhotoRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/persons/photoRequest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name SearchCreate
     * @summary Search for person by photo
     * @request POST:/persons/search/{context}
     * @secure
     */
    searchCreate: (context: string, data: FanguardApiContractsPersonPersonSearchRequest, params: RequestParams = {}) =>
      this.request<FanguardApiContractsPersonPersonSearchResponse, void>({
        path: `/persons/search/${context}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name ShareDetail
     * @summary Construct shareable URL for a POI
     * @request GET:/persons/share/{personId}
     * @secure
     */
    shareDetail: (personId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/persons/share/${personId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name WatchlistAddCreate
     * @request POST:/persons/watchlistAdd
     * @secure
     */
    watchlistAddCreate: (
      data: FanguardApiContractsPersonPersonBulkAddToWatchlistRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/persons/watchlistAdd`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name WatchlistRemoveCreate
     * @request POST:/persons/watchlistRemove
     * @secure
     */
    watchlistRemoveCreate: (
      data: FanguardApiContractsPersonPersonBulkRemoveFromWatchlistRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/persons/watchlistRemove`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name EmailVerificationCreate
     * @summary Send verification email
     * @request POST:/persons/{email}/emailVerification
     * @secure
     */
    emailVerificationCreate: (
      email: string,
      data: FanguardApiContractsPersonEmailVerificationRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/persons/${email}/emailVerification`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name CreatePersonDayPass
     * @summary Create person daypass
     * @request PATCH:/persons/{personId}/daypass
     * @secure
     */
    createPersonDayPass: (personId: string, params: RequestParams = {}) =>
      this.request<void, FanguardApiContractsErrorErrorModel>({
        path: `/persons/${personId}/daypass`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name ImpersonateDetail
     * @request GET:/persons/{personId}/impersonate
     * @secure
     */
    impersonateDetail: (personId: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsPersonImpersonateResponseModel, any>({
        path: `/persons/${personId}/impersonate`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name LavaEmbedDetail
     * @request GET:/persons/{personId}/lava-embed
     * @secure
     */
    lavaEmbedDetail: (personId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/persons/${personId}/lava-embed`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name LinkedAccountDelete
     * @request DELETE:/persons/{personId}/linked-account/{accountId}
     * @secure
     */
    linkedAccountDelete: (personId: string, accountId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/persons/${personId}/linked-account/${accountId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name NotesCreate
     * @summary Add note to a person
     * @request POST:/persons/{personId}/notes
     * @secure
     */
    notesCreate: (
      personId: string,
      data: FanguardApiContractsPersonAddPersonNoteRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/persons/${personId}/notes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name PhotoRequestCreate2
     * @request POST:/persons/{personId}/photoRequest
     * @originalName photoRequestCreate
     * @duplicate
     * @secure
     */
    photoRequestCreate2: (
      personId: string,
      data: FanguardApiContractsPersonPersonPhotoRequestRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/persons/${personId}/photoRequest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name PhotosDelete
     * @request DELETE:/persons/{personId}/photos/{personPhotoId}
     * @secure
     */
    photosDelete: (personId: string, personPhotoId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/persons/${personId}/photos/${personPhotoId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name PhotosCreate
     * @request POST:/persons/{personId}/photos
     * @secure
     */
    photosCreate: (
      personId: string,
      data: FanguardApiContractsPersonPersonPhotoRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/persons/${personId}/photos`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name PersonsDelete
     * @request DELETE:/persons/{personId}
     * @secure
     */
    personsDelete: (personId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/persons/${personId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name GetPerson
     * @summary Get a specific persons
     * @request GET:/persons/{personId}
     * @secure
     */
    getPerson: (
      personId: string,
      query?: {
        /** @default false */
        includeAlerts?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        FanguardApiContractsPagedItemsModel1FanguardApiContractsPersonPersonDetailModel,
        FanguardApiContractsErrorErrorModel
      >({
        path: `/persons/${personId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name UpdatePerson
     * @summary Update person
     * @request PATCH:/persons/{personId}
     * @secure
     */
    updatePerson: (
      personId: string,
      data: FanguardApiContractsPersonPersonUpdateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, FanguardApiContractsErrorErrorModel>({
        path: `/persons/${personId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name RetrievePersons
     * @summary Retrieve a list of persons
     * @request GET:/persons
     * @secure
     */
    retrievePersons: (
      query?: {
        searchTerm?: string;
        /** Watchlist ID */
        watchlistId?: string;
        /** @default false */
        onlyOrphans?: boolean;
        /** @default "firstName" */
        sortOrder?: string;
        sortDirection?: FanguardCoreInfrastructureDataSortDirection;
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 1000
         */
        pageSize?: number;
        excludeWatchlistId?: string;
        /**
         * @format int32
         * @default 0
         */
        photosCountFrom?: number;
        /**
         * @format int32
         * @default 100
         */
        photosCountTo?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsPagedItemsModel1FanguardApiContractsPersonPersonSummaryModel, void>({
        path: `/persons`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name CreatePerson
     * @summary Create person
     * @request POST:/persons
     * @secure
     */
    createPerson: (data: FanguardApiContractsPersonPersonCreateRequestModel, params: RequestParams = {}) =>
      this.request<void, FanguardApiContractsErrorErrorModel>({
        path: `/persons`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  reports = {
    /**
     * No description
     *
     * @tags Report
     * @name AlertsummaryList
     * @request GET:/reports/alertsummary
     * @secure
     */
    alertsummaryList: (
      query?: {
        eventId?: string;
        /** @default "pdf" */
        output?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/reports/alertsummary`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name DecisionsList
     * @request GET:/reports/decisions
     * @secure
     */
    decisionsList: (
      query?: {
        sensorIds?: string;
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @format int32 */
        timezoneOffset?: number;
        /** @default "pdf" */
        output?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsDecisionDecisionSummaryModel[], any>({
        path: `/reports/decisions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name EnrollmentStatusList
     * @request GET:/reports/enrollmentStatus
     * @secure
     */
    enrollmentStatusList: (
      query?: {
        campaignId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/reports/enrollmentStatus`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name TicketRecapList
     * @request GET:/reports/ticketRecap
     * @secure
     */
    ticketRecapList: (
      query?: {
        listId?: string;
        eventIds?: string;
        /** @format date-time */
        registeredSince?: string;
        /** @default "html" */
        output?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/reports/ticketRecap`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name WatchlistSummaryList
     * @request GET:/reports/watchlistSummary
     * @secure
     */
    watchlistSummaryList: (
      query?: {
        watchlistId?: string;
        /** @format date-time */
        registeredSince?: string;
        /** @default "html" */
        output?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/reports/watchlistSummary`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name WhitelistedPersonsList
     * @request GET:/reports/whitelisted-persons
     * @secure
     */
    whitelistedPersonsList: (
      query?: {
        /** @format date-time */
        registeredSince?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/reports/whitelisted-persons`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  sensors = {
    /**
     * No description
     *
     * @tags Sensor
     * @name AnalyticsUploadCredentialList
     * @request GET:/sensors/analytics-upload-credential
     * @secure
     */
    analyticsUploadCredentialList: (params: RequestParams = {}) =>
      this.request<FanguardCoreServicesTemporaryToken, any>({
        path: `/sensors/analytics-upload-credential`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name BeatCreate
     * @request POST:/sensors/beat
     * @secure
     */
    beatCreate: (data: FanguardApiContractsBoxSensorBeatRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/beat`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name DiagnosticUploadCredentialList
     * @request GET:/sensors/diagnostic-upload-credential
     * @secure
     */
    diagnosticUploadCredentialList: (params: RequestParams = {}) =>
      this.request<FanguardCoreServicesTemporaryToken, any>({
        path: `/sensors/diagnostic-upload-credential`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name GroupsDelete
     * @summary Delete sensor group
     * @request DELETE:/sensors/groups/{groupId}
     * @secure
     */
    groupsDelete: (groupId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/groups/${groupId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name GroupsDetail
     * @summary Get sensor group
     * @request GET:/sensors/groups/{groupId}
     * @secure
     */
    groupsDetail: (groupId: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsSensorSensorGroupDetailModel, any>({
        path: `/sensors/groups/${groupId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name GroupsPartialUpdate
     * @summary Edit existing sensor group
     * @request PATCH:/sensors/groups/{groupId}
     * @secure
     */
    groupsPartialUpdate: (
      groupId: string,
      data: FanguardApiContractsSensorSensorGroupRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsSensorSensorGroupModel, any>({
        path: `/sensors/groups/${groupId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name GroupsList
     * @summary List sensor groups
     * @request GET:/sensors/groups
     * @secure
     */
    groupsList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsSensorSensorGroupModel[], any>({
        path: `/sensors/groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name GroupsCreate
     * @summary Create new sensor group
     * @request POST:/sensors/groups
     * @secure
     */
    groupsCreate: (data: FanguardApiContractsSensorSensorGroupRequestModel, params: RequestParams = {}) =>
      this.request<FanguardApiContractsSensorSensorGroupModel, any>({
        path: `/sensors/groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name HealthCreate
     * @request POST:/sensors/health
     * @secure
     */
    healthCreate: (data: FanguardApiContractsBoxSensorBeatRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/health`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name SelfDestructDelete
     * @request DELETE:/sensors/self-destruct
     * @secure
     */
    selfDestructDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/self-destruct`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name CalibrationCreate
     * @request POST:/sensors/{sensorId}/calibration
     * @secure
     */
    calibrationCreate: (sensorId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/${sensorId}/calibration`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name ControlDetail
     * @request GET:/sensors/{sensorId}/control/{control}
     * @secure
     */
    controlDetail: (sensorId: string, control: FanguardCoreManagersSensorControlEnum, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/${sensorId}/control/${control}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name CrowdDetail
     * @request GET:/sensors/{sensorId}/crowd
     * @secure
     */
    crowdDetail: (sensorId: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsSensorSensorCrowdModel, any>({
        path: `/sensors/${sensorId}/crowd`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name DiagnoseDetail
     * @request GET:/sensors/{sensorId}/diagnose
     * @secure
     */
    diagnoseDetail: (sensorId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/${sensorId}/diagnose`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name DiagnosticUploadCreate
     * @request POST:/sensors/{sensorId}/diagnostic-upload
     * @secure
     */
    diagnosticUploadCreate: (
      sensorId: string,
      data: FanguardApiContractsSensorSensorRequestDiagnosticUploadModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/sensors/${sensorId}/diagnostic-upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name GraphDetail
     * @request GET:/sensors/{sensorId}/graph
     * @secure
     */
    graphDetail: (
      sensorId: string,
      query?: {
        metric?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsSensorSensorGraphModel, any>({
        path: `/sensors/${sensorId}/graph`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name GetSensors
     * @request GET:/sensors/{sensorId}/log
     * @secure
     */
    getSensors: (
      sensorId: string,
      query?: {
        /**
         * @format int32
         * @default 100
         */
        lineCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/sensors/${sensorId}/log`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name ReconfigureDetail
     * @request GET:/sensors/{sensorId}/reconfigure
     * @secure
     */
    reconfigureDetail: (sensorId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/${sensorId}/reconfigure`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name SnapshotRefreshCreate
     * @request POST:/sensors/{sensorId}/snapshot-refresh
     * @secure
     */
    snapshotRefreshCreate: (sensorId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/${sensorId}/snapshot-refresh`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name SnapshotDetail
     * @summary Returns the current snapshot from the sensor.
     * @request GET:/sensors/{sensorId}/snapshot
     * @secure
     */
    snapshotDetail: (sensorId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/${sensorId}/snapshot`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name ZoneUpdate
     * @request PUT:/sensors/{sensorId}/zone
     * @secure
     */
    zoneUpdate: (sensorId: string, data: number[][], params: RequestParams = {}) =>
      this.request<FanguardApiContractsSensorSensorModel, any>({
        path: `/sensors/${sensorId}/zone`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name SensorsDelete
     * @request DELETE:/sensors/{sensorId}
     * @secure
     */
    sensorsDelete: (sensorId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors/${sensorId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name SensorsDetail
     * @summary Retrieves a specific sensor
     * @request GET:/sensors/{sensorId}
     * @secure
     */
    sensorsDetail: (sensorId: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsSensorSensorModel, any>({
        path: `/sensors/${sensorId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name SensorsPartialUpdate
     * @summary Update sensor name and watchlists
     * @request PATCH:/sensors/{sensorId}
     * @secure
     */
    sensorsPartialUpdate: (
      sensorId: string,
      data: FanguardApiContractsSensorSensorUpdateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsSensorSensorModel, any>({
        path: `/sensors/${sensorId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name SensorsList
     * @summary List sensors
     * @request GET:/sensors
     * @secure
     */
    sensorsList: (
      query?: {
        venueId?: string;
        statuses?: string;
        types?: string;
        includeCrowdCountData?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsSensorSensorModel[], any>({
        path: `/sensors`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensor
     * @name SensorsCreate
     * @summary Create new sensor
     * @request POST:/sensors
     * @secure
     */
    sensorsCreate: (data: FanguardApiContractsSensorSensorCreateRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sensors`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  services = {
    /**
     * No description
     *
     * @tags Service
     * @name ServicesCreate
     * @request POST:/services
     * @secure
     */
    servicesCreate: (client: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsBoxServiceKeyModel, any>({
        path: `/services`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  site = {
    /**
     * No description
     *
     * @tags Site
     * @name DetailsList
     * @request GET:/site/details
     * @secure
     */
    detailsList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientSiteDetailModel[], any>({
        path: `/site/details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name SiteDetail
     * @request GET:/site/{idOrPathSegment}
     * @secure
     */
    siteDetail: (idOrPathSegment: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientSiteDetailModel, any>({
        path: `/site/${idOrPathSegment}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name SiteDelete
     * @request DELETE:/site/{id}
     * @secure
     */
    siteDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/site/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name SitePartialUpdate
     * @request PATCH:/site/{id}
     * @secure
     */
    sitePartialUpdate: (
      id: string,
      data: FanguardApiContractsClientSiteUpdateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/site/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name CampaignDelete
     * @request DELETE:/site/{siteId}/campaign/{id}
     * @secure
     */
    campaignDelete: (siteId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/site/${siteId}/campaign/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name CampaignDetail
     * @request GET:/site/{siteId}/campaign/{id}
     * @secure
     */
    campaignDetail: (siteId: string, id: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientCampaignDetailModel, any>({
        path: `/site/${siteId}/campaign/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name CampaignPartialUpdate
     * @request PATCH:/site/{siteId}/campaign/{id}
     * @secure
     */
    campaignPartialUpdate: (
      siteId: string,
      id: string,
      data: FanguardApiContractsClientCampaignUpdateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/site/${siteId}/campaign/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name CampaignCreate
     * @request POST:/site/{siteId}/campaign
     * @secure
     */
    campaignCreate: (
      siteId: string,
      data: FanguardApiContractsClientCampaignCreateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsClientCampaignDetailModel, any>({
        path: `/site/${siteId}/campaign`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name SiteList
     * @request GET:/site
     * @secure
     */
    siteList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientSiteSummaryModel[], any>({
        path: `/site`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Site
     * @name SiteCreate
     * @request POST:/site
     * @secure
     */
    siteCreate: (data: FanguardApiContractsClientSiteCreateRequestModel, params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientSiteDetailModel, any>({
        path: `/site`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  ticketGroup = {
    /**
     * No description
     *
     * @tags TicketGroup
     * @name TicketGroupDelete
     * @request DELETE:/ticket-group/{id}
     * @secure
     */
    ticketGroupDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/ticket-group/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketGroup
     * @name TicketGroupDetail
     * @request GET:/ticket-group/{id}
     * @secure
     */
    ticketGroupDetail: (id: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsTicketTicketGroupDetailModel, any>({
        path: `/ticket-group/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketGroup
     * @name TicketGroupPartialUpdate
     * @request PATCH:/ticket-group/{id}
     * @secure
     */
    ticketGroupPartialUpdate: (
      id: string,
      data: FanguardApiContractsTicketTicketGroupUpdateRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/ticket-group/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketGroup
     * @name TicketGroupList
     * @request GET:/ticket-group
     * @secure
     */
    ticketGroupList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsTicketTicketGroupSummaryModel[], any>({
        path: `/ticket-group`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TicketGroup
     * @name TicketGroupCreate
     * @request POST:/ticket-group
     * @secure
     */
    ticketGroupCreate: (data: FanguardApiContractsTicketTicketGroupCreateRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/ticket-group`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  tickets = {
    /**
     * No description
     *
     * @tags Ticket
     * @name PatronList
     * @request GET:/tickets/patron
     * @secure
     */
    patronList: (
      query?: {
        emailAddress?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/tickets/patron`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name RedemptionsCreate
     * @request POST:/tickets/redemptions
     * @secure
     */
    redemptionsCreate: (data: FanguardApiContractsTicketTicketScanRequest, params: RequestParams = {}) =>
      this.request<FanguardApiContractsTicketTicketScanResult[], any>({
        path: `/tickets/redemptions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name RemoteRedemptionsCreate
     * @request POST:/tickets/remote-redemptions
     * @secure
     */
    remoteRedemptionsCreate: (
      data: FanguardApiContractsTicketRemoteTicketingRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsTicketTicketScanResult[], any>({
        path: `/tickets/remote-redemptions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name ScansCreate
     * @request POST:/tickets/scans
     * @secure
     */
    scansCreate: (data: FanguardApiContractsTicketTicketScanRequest, params: RequestParams = {}) =>
      this.request<FanguardApiContractsTicketTicketScanResult[], any>({
        path: `/tickets/scans`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name PersonDetail
     * @request GET:/tickets/{eventId}/person/{personId}
     * @secure
     */
    personDetail: (eventId: string, personId: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsTicketTicketModel, any>({
        path: `/tickets/${eventId}/person/${personId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name TicketsDetail
     * @request GET:/tickets/{id}
     * @secure
     */
    ticketsDetail: (id: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsDecisionTicketDecisionSummaryModel, any>({
        path: `/tickets/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name TicketsDelete
     * @request DELETE:/tickets/{ticketId}
     * @secure
     */
    ticketsDelete: (ticketId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tickets/${ticketId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name TicketsList
     * @request GET:/tickets
     * @secure
     */
    ticketsList: (
      query?: {
        systemFilterIds?: string;
        watchlistIds?: string;
        eventIds?: string;
        venueIds?: string;
        sensorIds?: string;
        validationStatuses?: string;
        grantStatuses?: string;
        /** @format int32 */
        page?: number;
        /** @format int32 */
        pageSize?: number;
        searchTerm?: string;
        /**
         * @format double
         * @default 0
         */
        similarityMin?: number;
        /**
         * @format double
         * @default 1
         */
        similarityMax?: number;
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsDecisionTicketDecisionSummaryModel[], any>({
        path: `/tickets`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name TicketsCreate
     * @request POST:/tickets
     * @secure
     */
    ticketsCreate: (data: FanguardApiContractsTicketCreateTicketRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tickets`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  token = {
    /**
     * No description
     *
     * @tags Token
     * @name TokenDelete
     * @request DELETE:/token
     * @secure
     */
    tokenDelete: (data: FanguardApiContractsUserLogoutRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/token`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Token
     * @name TokenList
     * @request GET:/token
     * @secure
     */
    tokenList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/token`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Token
     * @name TokenCreate
     * @summary Authenticate user and create authorization token
     * @request POST:/token
     * @secure
     */
    tokenCreate: (data: FanguardApiContractsUserAuthenticationRequest, params: RequestParams = {}) =>
      this.request<FanguardCoreServicesAuthenticationResult, void>({
        path: `/token`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags User
     * @name PreferencesUpdate
     * @request PUT:/users/preferences
     * @secure
     */
    preferencesUpdate: (data: FanguardApiContractsUserUserPreferencesRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/preferences`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name DevicesDelete
     * @summary Wipes all devices for a user from Fanguard
     * @request DELETE:/users/{userId}/devices
     * @secure
     */
    devicesDelete: (userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${userId}/devices`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name DevicesCreate
     * @summary Register a new device for user to receive push notifications
     * @request POST:/users/{userId}/devices
     * @secure
     */
    devicesCreate: (userId: string, data: FanguardApiContractsUserDeviceModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${userId}/devices`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name InvitationsCreate
     * @summary Send (or resend) an invitation to setup an account or reset a password
     * @request POST:/users/{userId}/invitations/{type}
     * @secure
     */
    invitationsCreate: (userId: string, type: FanguardApiContractsUserInvitationTypeEnum, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${userId}/invitations/${type}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name PasswordUpdate
     * @request PUT:/users/{userId}/password
     * @secure
     */
    passwordUpdate: (
      userId: string,
      data: FanguardApiContractsUserUserSetPasswordRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/${userId}/password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SignedAgreementsCreate
     * @summary Sign agreement
     * @request POST:/users/{userId}/signedAgreements
     * @secure
     */
    signedAgreementsCreate: (
      userId: string,
      data: FanguardApiContractsUserSignAgreementRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/${userId}/signedAgreements`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UnlockCreate
     * @summary Unlock user account
     * @request POST:/users/{userId}/unlock
     * @secure
     */
    unlockCreate: (userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${userId}/unlock`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersDelete
     * @request DELETE:/users/{userId}
     * @secure
     */
    usersDelete: (userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${userId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersDetail
     * @summary Retrieve user
     * @request GET:/users/{userId}
     * @secure
     */
    usersDetail: (userId: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsUserUserDetailModel, any>({
        path: `/users/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersPartialUpdate
     * @summary Update a user.  Only provided fields will be modified.
     * @request PATCH:/users/{userId}
     * @secure
     */
    usersPartialUpdate: (
      userId: string,
      data: FanguardApiContractsUserUserPatchRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<FanguardApiContractsUserUserDetailModel, any>({
        path: `/users/${userId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersList
     * @summary Retrieves a list if users within a clien
     * @request GET:/users
     * @secure
     */
    usersList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersCreate
     * @summary Register new user for authentication with API endpoints
     * @request POST:/users
     * @secure
     */
    usersCreate: (data: FanguardApiContractsUserUserRequestModel, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  venues = {
    /**
     * No description
     *
     * @tags Venue
     * @name VenuesDelete
     * @request DELETE:/venues/{id}
     * @secure
     */
    venuesDelete: (id: string, params: RequestParams = {}) =>
      this.request<any, MicrosoftAspNetCoreMvcProblemDetails>({
        path: `/venues/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name SensorsCreate
     * @request POST:/venues/{venueId}/sensors
     * @secure
     */
    sensorsCreate: (
      venueId: string,
      data: FanguardApiContractsClientSensorPlacementRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/venues/${venueId}/sensors`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name VenuesDetail
     * @request GET:/venues/{venueId}
     * @secure
     */
    venuesDetail: (venueId: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientVenueDetailModel, any>({
        path: `/venues/${venueId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name VenuesPartialUpdate
     * @request PATCH:/venues/{venueId}
     * @secure
     */
    venuesPartialUpdate: (
      venueId: string,
      data: FanguardApiContractsClientVenueRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/venues/${venueId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name VenuesList
     * @summary List venues
     * @request GET:/venues
     * @secure
     */
    venuesList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientVenueSummaryModel[], any>({
        path: `/venues`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name VenuesCreate
     * @request POST:/venues
     * @secure
     */
    venuesCreate: (data: FanguardApiContractsClientVenueRequestModel, params: RequestParams = {}) =>
      this.request<FanguardApiContractsClientVenueSummaryModel, any>({
        path: `/venues`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  wallet = {
    /**
     * No description
     *
     * @tags Wallet
     * @name PaymentCardsDelete
     * @summary Delete Payment Card
     * @request DELETE:/wallet/payment-cards/{id}
     * @secure
     */
    paymentCardsDelete: (
      id: string,
      data: FanguardApiContractsPersonPaymentCardRemovalRequestModel,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/wallet/payment-cards/${id}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name PaymentCardsList
     * @summary List of PaymentCards
     * @request GET:/wallet/payment-cards
     * @secure
     */
    paymentCardsList: (
      query?: {
        personId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/wallet/payment-cards`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name PaymentCardsCreate
     * @summary Adding PaymentCard
     * @request POST:/wallet/payment-cards
     * @secure
     */
    paymentCardsCreate: (data: FanguardApiContractsPersonPaymentCardRequestModel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/wallet/payment-cards`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name TestAifiEnsureCustomerExistsCreate
     * @request POST:/wallet/test/aifi/ensure-customer-exists/{email}
     * @secure
     */
    testAifiEnsureCustomerExistsCreate: (email: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/wallet/test/aifi/ensure-customer-exists/${email}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name TestPaymentCardsCaptureCreate
     * @request POST:/wallet/test/payment-cards/capture/{personId}
     * @secure
     */
    testPaymentCardsCaptureCreate: (
      personId: string,
      query?: {
        preAuthToken?: string;
        /** @format double */
        amount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/wallet/test/payment-cards/capture/${personId}`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name TestPaymentCardsPreAuthCreate
     * @request POST:/wallet/test/payment-cards/pre-auth/{personId}
     * @secure
     */
    testPaymentCardsPreAuthCreate: (personId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/wallet/test/payment-cards/pre-auth/${personId}`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  };
  watchlists = {
    /**
     * No description
     *
     * @tags Watchlist
     * @name WatchlistsDelete
     * @summary Edit watchlist
     * @request DELETE:/watchlists/{id}
     * @secure
     */
    watchlistsDelete: (id: string, params: RequestParams = {}) =>
      this.request<any, MicrosoftAspNetCoreMvcProblemDetails>({
        path: `/watchlists/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Watchlist
     * @name WatchlistsDetail
     * @request GET:/watchlists/{id}
     * @secure
     */
    watchlistsDetail: (id: string, params: RequestParams = {}) =>
      this.request<FanguardApiContractsWatchlistWatchlistDetailModel, any>({
        path: `/watchlists/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Watchlist
     * @name WatchlistsUpdate
     * @summary Edit watchlist
     * @request PUT:/watchlists/{id}
     * @secure
     */
    watchlistsUpdate: (
      id: string,
      data: FanguardApiContractsWatchlistWatchlistDetailModel,
      params: RequestParams = {},
    ) =>
      this.request<any, MicrosoftAspNetCoreMvcProblemDetails>({
        path: `/watchlists/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Watchlist
     * @name WatchlistsList
     * @summary List watchlists
     * @request GET:/watchlists
     * @secure
     */
    watchlistsList: (params: RequestParams = {}) =>
      this.request<FanguardApiContractsWatchlistWatchlistSummaryModel[], any>({
        path: `/watchlists`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Watchlist
     * @name WatchlistsCreate
     * @summary Create watchlist
     * @request POST:/watchlists
     * @secure
     */
    watchlistsCreate: (data: FanguardApiContractsWatchlistWatchlistDetailModel, params: RequestParams = {}) =>
      this.request<FanguardApiContractsWatchlistWatchlistDetailModel, any>({
        path: `/watchlists`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
